// Core
import { useContext, useEffect, useRef } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

// Context
import { UIContext } from 'contexts/UIContext';

// Utils
import { showError } from 'validators/showError';

// UI
import { FormInput } from 'components/Form/FormInput';
import { InfoButton } from 'components/Buttons';

// Styles
import s from '../Release.module.css';

const MainTable = ({
	changeField,
	errorsFromBack,
	errors,
	personal,
	handleOnBlurDateInput,
	handleOnBlurPlineDateInput,
	handleOnBlurClineDateInput,
	handleFocus,
	handleBlur,
	countries,
	getClassnameForInfoLineBtn,
	releaseTypes,
	isH11Edit,
}) => {
	const { showModal } = useContext(UIContext);
	const errorsRef = useRef(null);

	const fieldRefs = [
		'title',
		'subtitle',
		'release_type_id',
		'genres',
		'label',
		'created_date',
		'created_country_id',
		'p_line',
		'c_line',
	];

	useEffect(() => {
		const errorFields = Object.keys(errorsFromBack);
		if (
			errorFields.length > 0 &&
			errorFields.some((key) => fieldRefs.includes(key))
		) {
			if (errorsRef?.current) {
				errorsRef.current.scrollIntoView({ behavior: 'smooth' });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errorsFromBack]);

	const renderErrorMessage = (errors, errorType) => {
		return errors[errorType][0].rule !== 'max_size' ? (
			<span className={s.Helper}>
				{showError(errors[errorType][0].rule)(errors[errorType][0])}
			</span>
		) : (
			<span className={s.Helper}>
				{errors[errorType][0] && (
					<FormattedMessage
						id={`rod.error.max_size.${errorType}`}
						values={{ value: errors[errorType][0]?.value }}
					/>
				)}
			</span>
		);
	};

	return (
		<div className={s.table} ref={errorsRef}>
			<div className={s.input}>
				<FormInput
					type="muiInput"
					name={'title'}
					onChange={changeField}
					errors={errors}
					data={personal}
					required
					label={
						<FormattedMessage
							id={'rod.release.create.step.release.label.name'}
						/>
					}
				/>
				{Object.keys(errors).includes('title') && (
					<span className={s.Helper}>
						{showError(errors.title[0].rule)(errors.title[0])}
					</span>
				)}
				{Object.keys(errorsFromBack).includes('title') && (
					<span className={s.Helper}>
						{showError(errorsFromBack.title[0].rule)(errorsFromBack.title[0])}
					</span>
				)}
			</div>
			<div className={s.input}>
				<div className={s.gridForLines}>
					<div className={s.helpContainer}>
						<FormInput
							type={'muiInputDate_year'}
							readOnly={false}
							name={'date_p_line'}
							onChange={changeField}
							handleOnBlurDateInput={handleOnBlurPlineDateInput}
							errors={Object.keys(errors).includes('date_p_line') ? errors : {}}
							data={personal}
							required
							label={
								<FormattedMessage
									id={
										'rod.release.create.step.recording_info.label.p-line.year'
									}
								/>
							}
						/>
						{Object.keys(errors).includes('date_p_line') && (
							<span className={s.plineDateHelper}>
								{showError(errors.date_p_line[0].rule)(errors.date_p_line[0])}
							</span>
						)}
						{Object.keys(errorsFromBack).includes('date_p_line') && (
							<span className={s.plineDateHelper}>
								{showError('date_p_line')(
									errorsFromBack.date_p_line[0]
								)}
							</span>
						)}
					</div>
					<div className={s.helpContainer}>
						<FormInput
							type="muiInput"
							name={'p_line'}
							onChange={changeField}
							onFocus={handleFocus}
							onBlur={handleBlur}
							errors={errors}
							data={personal}
							required
							label={
								<FormattedMessage
									id={'rod.release.create.step.recording_info.label.p-line'}
								/>
							}
						/>
						<InfoButton
							className={getClassnameForInfoLineBtn('p_line')}
							onClick={showModal({
								text: (
									<FormattedHTMLMessage
										id={'rod.release.create.help.c-line/p-line'}
									/>
								),
							})}
						/>
						{Object.keys(errors).includes('p_line') && (
							<span className={s.plineHelper}>
								{showError(errors.p_line[0].rule)(errors.p_line[0])}
							</span>
						)}
						{Object.keys(errorsFromBack).includes('p_line') && (
							<span className={s.plineHelper}>
								{showError(errorsFromBack.p_line[0].rule)(
									errorsFromBack.p_line[0]
								)}
							</span>
						)}
					</div>
				</div>
			</div>
			<div className={s.input}>
				<FormInput
					type="muiInput"
					name={'subtitle'}
					onChange={changeField}
					errors={errors}
					data={personal}
					label={
						<FormattedMessage
							id={'rod.release.create.step.release.label.subtitle'}
						/>
					}
				/>
				{Object.keys(errors).includes('subtitle') && (
					<span className={s.Helper}>
						{showError(errors.subtitle[0].rule)(errors.subtitle[0])}
					</span>
				)}
			</div>
			<div className={s.input}>
				<div className={s.gridForLines}>
					<div className={s.helpContainer}>
						<FormInput
							type={'muiInputDate_year'}
							readOnly={false}
							name={'date_c_line'}
							onChange={changeField}
							handleOnBlurDateInput={handleOnBlurClineDateInput}
							errors={Object.keys(errors).includes('date_c_line') ? errors : {}}
							data={personal}
							required
							label={
								<FormattedMessage
									id={
										'rod.release.create.step.recording_info.label.c-line.year'
									}
								/>
							}
						/>
						{Object.keys(errors).includes('date_c_line') && (
							<span className={s.plineDateHelper}>
								{showError(errors.date_c_line[0].rule)(errors.date_c_line[0])}
							</span>
						)}
						{Object.keys(errorsFromBack).includes('date_c_line') && (
							<span className={s.plineDateHelper}>
								{showError('date_c_line')(
									errorsFromBack.date_c_line[0]
								)}
							</span>
						)}
					</div>
					<div className={s.helpContainer}>
						<FormInput
							type="muiInput"
							name={'c_line'}
							onChange={changeField}
							onFocus={handleFocus}
							onBlur={handleBlur}
							errors={errors}
							data={personal}
							required
							label={
								<FormattedMessage
									id={'rod.release.create.step.recording_info.label.c-line'}
								/>
							}
						/>
						<InfoButton
							className={getClassnameForInfoLineBtn('c_line')}
							onClick={showModal({
								text: (
									<FormattedHTMLMessage
										id={'rod.release.create.help.c-line/p-line'}
									/>
								),
							})}
						/>
						{Object.keys(errors).includes('c_line') && (
							<span className={s.plineHelper}>
								{showError(errors.c_line[0].rule)(errors.c_line[0])}
							</span>
						)}
						{Object.keys(errorsFromBack).includes('c_line') && (
							<span className={s.plineHelper}>
								{showError(errorsFromBack.c_line[0].rule)(
									errorsFromBack.c_line[0]
								)}
							</span>
						)}
					</div>
				</div>
			</div>

			<div className={s.input}>
				<FormInput
					type={'MuiSelectReleaseType'}
					name={'release_type_id'}
					onChange={changeField}
					errors={errors}
					data={personal}
					className={s.select}
					required
					label={
						<FormattedMessage
							id={'rod.release.create.step.release.label.release_type'}
						/>
					}
					items={releaseTypes}
					disabled={
						isH11Edit &&
						(personal.release_type_id === 42 ||
							personal.release_type_id === 43 ||
							personal.release_type_id === 70)
					}
				/>
				{Object.keys(errors).includes('release_type_id') && (
					<span className={s.Helper}>
						{showError(errors.release_type_id[0].rule)(
							errors.release_type_id[0]
						)}
					</span>
				)}
				{Object.keys(errorsFromBack).includes('release_type_id') && (
					<span className={s.Helper}>
						{showError(errorsFromBack.release_type_id[0].rule)(
							errorsFromBack.release_type_id[0]
						)}
					</span>
				)}
			</div>
			<div className={s.input}>
				<FormInput
					type={'muiInputDate_v2'}
					readOnly={false}
					name={'created_date'}
					onChange={changeField}
					handleOnBlurDateInput={handleOnBlurDateInput}
					maxDate={true}
					errors={
						Object.keys(errors).includes('created_date')
							? errors
							: Object.keys(errorsFromBack).includes('created_date')
							? errorsFromBack
							: {}
					}
					data={personal}
					required
					label={
						<FormattedMessage
							id={'rod.release.create.step.release.label.create_date'}
						/>
					}
				/>
				{Object.keys(errors).includes('created_date') && (
					<span className={s.Helper}>
						{showError('created_date')(errors.created_date[0])}
					</span>
				)}
				{Object.keys(errorsFromBack).includes('created_date') && (
					<span className={s.Helper} style={Object.keys(errors).includes('created_date') ? {bottom: '-28px'} : {}}>
						{showError('created_date')(
							errorsFromBack.created_date[0]
						)}
					</span>
				)}
			</div>
			<div className={s.inputGenres}>
				<FormInput
					type={'muiAutocompleteMultipleGenres'}
					name={'genres'}
					onChange={changeField}
					errors={errors}
					className={s.select}
					data={personal}
					required
					label={
						<FormattedMessage
							id={'rod.release.create.step.release.label.genre'}
						/>
					}
				/>
				{Object.keys(errors).includes('genres') &&
					renderErrorMessage(errors, 'genres')}
				{Object.keys(errorsFromBack).includes('genres') &&
					renderErrorMessage(errorsFromBack, 'genres')}
			</div>
			<div className={s.input}>
				<FormattedMessage
					id={'rod.release.create.step.recording_info.label.create_country'}
				>
					{(placeholder) => (
						<FormInput
							type="country"
							name="created_country_id"
							onChange={changeField}
							errors={errors}
							data={personal}
							className={s.select}
							placeholder={placeholder}
							items={countries}
							countries={countries}
							showFloatingLabel
						/>
					)}
				</FormattedMessage>
			</div>
		</div>
	);
};

export default MainTable;
