// Core
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

// Context
import { LangContext } from 'contexts/LangContext';

// UI
import CloseIcon from '@mui/icons-material/Close';
import { Check, NoCheck } from 'material-design/svg';
import MuiSelect from 'material-design/components/MuiSelect/MuiSelect';

// Styles
import s from './ReportsAdminModal.module.css';

const ReportsAdminModal = ({
	active,
	setActive,
	filterData,
	handleCheckedFilter,
	submitFilter,
	isFilterEnable,
	handleClearFilter,
	moderationRequests,
	selectedStatus,
	handleSelectStatus,
}) => {
	const { lang } = useContext(LangContext);

	const settings = filterData || [];
	const settingsWithoutStatus = settings.filter((_, index) => index !== 0);

	return (
		<>
			<div className={active ? `${s.Overlay} ${s.OverlayActive}` : s.Overlay} />
			<div
				className={active ? `${s.modal} ${s.active}` : s.modal}
				onClick={() => setActive(false)}
			>
				<div className={s.container} onClick={(e) => e.stopPropagation()}>
					<div className={s.modalHeader}>
						<div className={s.modalHeader__titleWrapper}>
							<span className={s.modalHeader__title}>
								<FormattedMessage id={'rod.admin.reports.filters'} />
							</span>
							<div className={s.modalHeader__icon}>
								<CloseIcon
									onClick={() => setActive(false)}
									style={{ cursor: 'pointer' }}
								/>
							</div>
						</div>
						<div
							className={s.modalHeader__clearAll}
							onClick={handleClearFilter}
						>
							<span>
								<FormattedMessage id={'rod.repertoire.clear_all'} />
							</span>
						</div>
					</div>
					<div className={s.modalBody}>
						{filterData && (
							<div className={s.setting}>
								<div className={s.setting__title}>
									{lang === 'en'
										? filterData[0]?.title_en
										: filterData[0]?.title_ru}
								</div>
								<MuiSelect
									label={
										lang === 'en'
											? 'select onboarding status'
											: 'выберите статус онбординга'
									}
									items={filterData[0]?.list || []}
									value={selectedStatus}
									isNotSelectedRowExists
									sort={false}
									lowercase
									onChange={(value) => {
										handleSelectStatus(value);
									}}
								/>
							</div>
						)}

						{settingsWithoutStatus.map((item, index) => (
							<div key={item.id} className={s.setting}>
								<div className={s.setting__title}>
									{lang === 'en' ? item.title_en : item.title_ru}
								</div>
								<ul>
									{item.list.map((setting) => (
										<li
											className={s.setting__item}
											key={setting.id}
											onClick={
												moderationRequests
													? () => handleCheckedFilter(setting.id)
													: () => handleCheckedFilter(index, setting.code)
											}
										>
											<div className={s.setting__checkbox}>
												{setting.checked ? (
													<Check className={s.auto__check} />
												) : (
													<NoCheck className={s.auto__noCheck} />
												)}
											</div>

											<span className={s.setting__label}>
												{lang === 'en' ? setting.name_en : setting.name_ru}
											</span>
										</li>
									))}
								</ul>
							</div>
						))}
						<button
							className={s.button__select}
							onClick={() => submitFilter(isFilterEnable)}
						>
							<FormattedMessage id={'rod.release.info.apply'} />
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default ReportsAdminModal;
