import React, { useState, useEffect, useContext } from 'react';

import { DropZone } from 'material-design/components/DropZone';
import { withRoot, withAuth, withUI } from 'hocs';
import { useRouteMatch } from 'react-router-dom';
import { compose } from 'recompose';
import stop_button from 'images/stop_button.svg';
import play_button from 'images/play_button.svg';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { AudioPlayer } from 'material-design/components/audioPlayer';
import { UIContext } from 'contexts/UIContext';
import { Loading } from 'components';
import s from './RepertoireRecordingAudio.module.css';

function RepertoireRecordingAudio(props) {
	const match = useRouteMatch();
	const recordingId = match.params.id;
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(true);
	const [repertoireFile, setRepertoireFile] = useState(null);
	const {
		rootContext: { getRecordingAudio },
		authContext: { accountId },
		UIContext: { isPlayerShow },
	} = props;

	const uiContext = useContext(UIContext);
	const { play, handlePlay, audioId, handlePlayStop } = uiContext;

	useEffect(() => {
		getRecordingAudio(accountId, recordingId).then((res) => {
			if (Array.isArray(res)) {
				setItems(res);
			} else if (res.status === 'none') {
				setItems([]);
			} else {
				setItems([res]);
			}
			setLoading(false);
		});
	}, []);

	function handleDelete(id) {
		const newItems = items.filter((i) => i.id !== id);
		setItems(newItems);
	}

	return loading ? (
		<Loading />
	) : (
		<>
			{items.length > 0 ? (
				<div className={s.body}>
					<div className={`${s.table} ${s.table__header}`}>
						<FormattedHTMLMessage id={'rod.for_all.title'} />
						<span></span>
						<FormattedHTMLMessage id={'rod.recordery.info.length'} />
						<span></span>
					</div>
					<ul className={s.main}>
						{items.map((i, index) => (
							<li key={index} className={`${s.table} ${s.table__item}`}>
								<span>{i.title}</span>
								<span></span>
								<span>{i.length}</span>
								<span></span>
								{i.preview &&
									(audioId === i.id && play ? (
										<img
											src={stop_button}
											className={s.play}
											onClick={handlePlayStop}
											alt=""
										/>
									) : (
										<img
											src={play_button}
											className={s.play}
											onClick={() =>
												handlePlay(index, i, i.title, i.performers)
											}
											alt=""
										/>
									))}

								<button onClick={() => handleDelete(i.id)} className={s.delete}>
									<FormattedMessage id={'rod.recordery.info.delete'} />
								</button>
							</li>
						))}
					</ul>
				</div>
			) : (
				<div className={s.noData}>
					<FormattedMessage id={'rod.admin.no-data'} />
				</div>
			)}
		</>
	);
}

export default compose(withAuth, withRoot, withUI)(RepertoireRecordingAudio);
