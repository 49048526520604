// Core
import React, { useState, useEffect, useContext } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import { partner } from 'messages/EN/pages_branding';
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';
import { accounts } from 'services';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { RootContext } from 'contexts/RootContext';
import { UIContext } from 'contexts/UIContext';

// Utils
import { getBannerData } from './utils/getBannerData';
import { reachDataLayer, reachGoal } from 'utils';

// UI
import Greetings from './Greetings/Greetings';
import Statistics from './Statistics/Statistics';
import AddReleaseModal from 'containers/Modal/AddReleaseModal/AddReleaseModal';
import ButtonsGroup from './ButtonsGroup/ButtonsGroup';
import TopReleases from './TopReleases/TopReleases';
import TopTracksMain from './TopTracksMain/TopTracksMain';

// Styles
import s from './MainPage.module.css';
import {
	accId,
	EVENTS,
	paramName,
	paramSuccess,
	reachParams,
	releaseType,
	releaseTypeNew,
	releaseTypeTransfer,
	userId,
} from 'utils/reachParams';

const MainPage = (props) => {
	const data = getBannerData();
	const isHideGreetingsBanner = localStorage.getItem('isHideGreetingsBanner');

	const [modal, setModal] = useState(false);
	const [show, setShow] = useState(!isHideGreetingsBanner);
	const [assetStatistics, setAssetStatistics] = useState({});
	const [downloadProgressPercents] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [hasStat, setHasStat] = useState(false);

	const {
		user,
		accountId,
		user_completion_step,
		getAccountCompletionStep,
		contractsExpiredRedirect,
		setIsNoContract,
		setIsAccountLoading,
		isAddReleaseEnableForAccount,
		isAddCompositionEnableForAccount,
	} = useContext(AuthContext);
	const {
		getAccountPersonalData,
		getAccountContactsData,
		getAccountStatisticCheck,
	} = useContext(RootContext);
	const {
		setIsTransferRelease,
		showTitle,
		hideTitle,
		changeBackTargetUrl,
		hideBackNavi,
		isCompositionCreateDisabled,
		isReleaseCreateDisabled,
		setSelectedRepertoire,
		isDisplayingStatistics,
	} = useContext(UIContext);

	let { params } = useRouteMatch();
	let { push } = useHistory();

	const onBtnClick = () => {
		setShow(false);
		localStorage.setItem('isHideGreetingsBanner', true);
	};

	const sendGoalAndParams = (type) => {
		reachGoal(EVENTS.chooseReleaseType);
		const eventName = EVENTS.chooseReleaseType;
		const formName = 'Выбор типа релиза';
		const pageUrl = window.location.href;

		const yaParams = {
			[pageUrl]: {
				[eventName]: {
					[formName]: {
						[paramName]: paramSuccess,
						[userId]: user.id,
						[accId]: accountId,
						[releaseType]:
							type === 'new' ? releaseTypeNew : releaseTypeTransfer,
					},
				},
			},
		};
		reachParams(yaParams);
		reachDataLayer(eventName, formName, paramSuccess, user.id, accountId);
	};

	const createNewReleaseHandler = () => {
		sendGoalAndParams('new');
		setModal(false);
		localStorage.removeItem('releaseId');
		localStorage.removeItem('isTransferRelease');
		setIsTransferRelease(false);
		push('/release/create');
	};

	const transferReleaseHandler = () => {
		sendGoalAndParams('transfer');
		setModal(false);
		localStorage.removeItem('releaseId');
		localStorage.setItem('isTransferRelease', true);
		setIsTransferRelease(true);
		push('/release/create');
	};

	const clickToCreateComposition = () => {
		reachGoal('rod_click_add_repertory');
		const eventName = EVENTS.addComposition;
		const formName = 'Кнопка "Добавить произведение"';
		const pageUrl = window.location.href;
		
		const yaParams = {
			[pageUrl]: {
				[eventName]: {
					[formName]: {
						[paramName]: paramSuccess,
						[userId]: user.id,
						[accId]: accountId,
					},
				},
			},
		};
		reachParams(yaParams);
		reachDataLayer(eventName, formName, paramSuccess, user.id, accountId);
		localStorage.removeItem('compositionId');
	};

	const isNoContract = localStorage.getItem('no_contract');

	useEffect(() => {
		changeBackTargetUrl('/');
		hideBackNavi();
		setIsAccountLoading(true);

		if (user_completion_step !== 'account_legacy') {
			setIsLoading(true);

			getAccountStatisticCheck(accountId)
				.then((res) => {
					setHasStat(res.data?.has_stat);
				})
				.catch((err) => {
					console.error(
						'MainPage - getAccountStatisticCheck - api- error: ',
						err.response
					);
				});

			accounts
				.getAssetStatistics(accountId)
				.then((res) => {
					const result = res.data.data;
					setAssetStatistics(result);
					if (
						(!result['compositions'].total &&
							!result['recordings'].total &&
							result['releases'].total) ||
						(result['releases'].total &&
							result['compositions'].total &&
							result['recordings'].total) ||
						(result['releases'].total &&
							result['compositions'].total &&
							!result['recordings'].total) ||
						(result['releases'].total &&
							!result['compositions'].total &&
							result['recordings'].total)
					) {
						setSelectedRepertoire('releases');
					} else if (
						result['compositions'].total &&
						!result['recordings'].total &&
						!result['releases'].total
					) {
						setSelectedRepertoire('compositions');
					} else if (
						(!result['compositions'].total &&
							result['recordings'].total &&
							!result['releases'].total) ||
						(!result['releases'].total &&
							result['recordings'].total &&
							result['compositions'].total)
					) {
						setSelectedRepertoire('recordings');
					} else {
						setSelectedRepertoire('drafts');
					}
				})
				.catch((err) => {
					console.error(
						'MainPage - getAssetStatistics - api- error: ',
						err.response
					);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}

		showTitle('rod.navbar.main');

		getAccountPersonalData(params.id)
			.then((account) => {
				if (account.status === 207 || account.status === 205) {
					return contractsExpiredRedirect(account.status);
				} else if (isNoContract) {
					setIsNoContract();
				}
				account = account.data.data;
				if (!props.authContext.isJoin) {
					getAccountCompletionStep(account.completion_step);
				}
			})
			.catch((err) => {
				console.error(
					'mainPage - getAccountPersonalData  api- error.response',
					err.response
				);
				console.error('mainPage - getAccountPersonalData  api- error', err);
				push('/accounts');
			})
			.finally(() => {
				setIsAccountLoading(false);
			});

		getAccountContactsData(params.id)
			.then((res) => {
				res = res.data.data;
			})
			.catch((err) => push('/accounts'));

		return function cleanup() {
			hideTitle();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		localStorage.removeItem('playlistsPagination');
	}, [accountId]);

	return (
		<>
			{modal && (
				<AddReleaseModal
					title={<FormattedMessage id={'rod.btn.add_release'} />}
					subtitle={
						<FormattedMessage
							id={'rod.release.create.step.distributions.description.transfer'}
						/>
					}
					newReleaseButtonLabel={<FormattedMessage id={'rod.new_release'} />}
					transferReleaseButtonLabel={
						<FormattedMessage id={'rod.transfer_release'} />
					}
					newReleaseButtonHandler={createNewReleaseHandler}
					transferReleaseButtonHandler={transferReleaseHandler}
					onClose={() => setModal(false)}
				/>
			)}
			<Helmet>
				<title>{partner.title_rod_company}</title>
			</Helmet>
			<div className={s.container}>
				{show && process.env.REACT_APP_CLIENT !== 'dgtal' && (
					<Greetings data={data} onClickBtn={onBtnClick} />
				)}
				<div className={!show ? s.Statistics : ''}>
					<Statistics
						statistics={assetStatistics}
						isLoading={isLoading}
						downloadProgressPercents={downloadProgressPercents}
					/>
				</div>
				{(!isReleaseCreateDisabled ||
					isAddReleaseEnableForAccount ||
					!isCompositionCreateDisabled ||
					isAddCompositionEnableForAccount) && (
					<ButtonsGroup
						setModal={setModal}
						createNewReleaseHandler={createNewReleaseHandler}
						clickToCreateComposition={clickToCreateComposition}
					/>
				)}
				{isDisplayingStatistics && hasStat && (
					<>
						<TopReleases />
						<TopTracksMain />
					</>
				)}
			</div>
		</>
	);
};

export default compose(withUI, withAuth, withRoot)(MainPage);
