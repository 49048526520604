// Core
import React, { useContext } from 'react';

// Context
import { LangContext } from 'contexts/LangContext';
import { UIContext } from 'contexts/UIContext';

// UI
import LoaderWhithoutProgress from 'components/LoaderWhithoutProgress';
import RecordingLoader from './RecordingLoader/RecordingLoader';

// Icons
import stop_button from 'images/stop_button.svg';
import play_button from 'images/play_button.svg';
import remove_cart from 'images/remove_cart.svg';
import sort_dnd from 'images/sort_dnd.svg';
import update_file_icon from 'images/update_file_icon.svg';

// Styles
import s from '../File.module.css';

const RecordingsBlock = ({
	recordings,
	isDraggable,
	dragStartHandler,
	dragEndHandler,
	dragOverHandler,
	dropHandler,
	deleteBtnDisabled,
	h11Moderation,
	audioLoading,
	uploadProgressPercents,
	handlePreparedAudio,
	handleFileUpload,
	handleDelete,
}) => {
	const isH11Edit = window.location.href.includes('/edit/');

	const { lang } = useContext(LangContext);
	const { play, audioId, handlePlayStop } = useContext(UIContext);

	return (
		<ul className={s.table}>
			{recordings.map((item, index) => (
				<li
					key={index}
					onDragStart={
						isDraggable && !deleteBtnDisabled
							? (e) => dragStartHandler(e, item, index)
							: null
					}
					onDragLeave={
						isDraggable && !deleteBtnDisabled ? (e) => dragEndHandler(e) : null
					}
					onDragEnd={
						isDraggable && !deleteBtnDisabled ? (e) => dragEndHandler(e) : null
					}
					onDragOver={
						isDraggable && !deleteBtnDisabled ? (e) => dragOverHandler(e) : null
					}
					onDrop={
						isDraggable && !deleteBtnDisabled
							? (e) => dropHandler(e, item, index)
							: null
					}
					draggable={isDraggable && !deleteBtnDisabled}
					className={s.table__item}
					style={
						isH11Edit && h11Moderation
							? { paddingRight: '48px' }
							: (isH11Edit && !h11Moderation) || deleteBtnDisabled
							? { cursor: 'default' }
							: {}
					}
				>
					{audioLoading ? (
						<span className={s.spinner}>
							<LoaderWhithoutProgress />
						</span>
					) : (
						<span>{index + 1}</span>
					)}

					<span>{item?.file?.client_name || item.name}</span>
					<span>3:00</span>

					{!item.done ? (
						<RecordingLoader
							item={item}
							index={index}
							uploadProgressPercents={uploadProgressPercents}
							deleteBtnDisabled={deleteBtnDisabled}
							handleDelete={handleDelete}
						/>
					) : (
						<div
							key={index}
							className={
								isH11Edit && !h11Moderation ? s.menuWrapperEdit : s.menuWrapper
							}
						>
							{audioId === (item.file?.id || item.file_id) && play ? (
								<button className={s.play} onClick={handlePlayStop}>
									<img src={stop_button} alt="pause" />
								</button>
							) : (
								<button
									className={`${s.play} ${deleteBtnDisabled ? s.disabled : ''}`}
									disabled={deleteBtnDisabled}
									onClick={() => handlePreparedAudio(index, item)}
								>
									<img src={play_button} alt="play" />
								</button>
							)}

							{isH11Edit && h11Moderation && (
								<div>
									<label htmlFor={`upload-button-${item.id}`}>
										<img
											src={update_file_icon}
											className={s.replace}
											alt="file"
											title={
												lang === 'ru'
													? 'заменить фонограмму'
													: 'replace the phonogram file'
											}
										/>
									</label>
									<input
										type="file"
										id={`upload-button-${item.id}`}
										style={{ display: 'none' }}
										onChange={(e) =>
											handleFileUpload([e.target.files[0]], item.id)
										}
										accept=".wav, .flac"
									/>
								</div>
							)}

							{(!isH11Edit || (isH11Edit && h11Moderation)) && (
								<button
									className={
										deleteBtnDisabled
											? `${s.deleteButton} ${s.disabled}`
											: s.deleteButton
									}
									disabled={deleteBtnDisabled}
									onClick={() => handleDelete(index, item)}
								>
									<img src={remove_cart} alt="" />
								</button>
							)}

							{(!isH11Edit || (isH11Edit && h11Moderation)) && (
								<img
									src={sort_dnd}
									className={s.sort}
									style={deleteBtnDisabled ? { cursor: 'not-allowed' } : {}}
									alt=""
								/>
							)}
						</div>
					)}
				</li>
			))}
		</ul>
	);
};

export default RecordingsBlock;
