// Core
import { useContext } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { Col, Row } from 'react-grid-system';

// Context
import { LangContext } from 'contexts/LangContext';

// UI
import Loader from 'components/Loader';

// Icons
import pendingIcon from 'images/pending-icon.svg';

// Styles
import styles from '../DistributionPage.module.css';

const UploadArchiveData = ({
	uploadArchiveData,
	isParsingStart,
	uploadProgressPercents,
}) => {
	const { lang } = useContext(LangContext);

	return (
		<>
			{uploadArchiveData.length
				? uploadArchiveData.map((item, index) => (
						<li className={styles.articlesListItem}>
							<Row nowrap className={styles.row_data}>
								<Row nowrap className={styles.row_title}>
									<Col width={'12%'} className={styles.shell}>
										{item.date_publication}
									</Col>
									<Col width={'12%'} className={styles.shell}>
										{item.created_at}
									</Col>
									<Col width={'11%'} className={styles.shell}></Col>
									<Col width={'30%'} className={styles.shell}>
										{lang === 'ru' ? item.heading : item.heading_en}
									</Col>
									<Col width={'15%'} className={styles.shell}></Col>

									<Col width={'170px'} className={styles.shell}>
										{!isParsingStart ? (
											<div className={styles.loaderWrapper}>
												<div className={styles.loader}>
													<Loader
														uploadProgressPercents={uploadProgressPercents}
														width={'64px'}
														height={'64px'}
														padding={'0px'}
													/>
												</div>
												{uploadProgressPercents.value && (
													<span className={styles.percentCount}>
														{uploadProgressPercents.value + '%'}
													</span>
												)}
											</div>
										) : (
											<div>
												<div className={styles.action}>
													<img src={pendingIcon} alt="" width="25" />
													<div className={styles.actionTitlePending}>
														<FormattedHTMLMessage
															id={'rod.admin.reports.pending_review'}
														/>
													</div>
												</div>
											</div>
										)}
									</Col>
									<Col width={'42px'}></Col>
								</Row>
							</Row>
						</li>
				  ))
				: ''}
		</>
	);
};

export default UploadArchiveData;
