// Core
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

// Utils
import { getDateData } from 'material-design/ReleaseCreate/steps/Distribution/utils/getDateData.js';
import { getArrayOfOutletsTitles } from 'material-design/ReleaseCreate/steps/Distribution/utils/getArrayOfOutletsTitles';
import { formatDateToDdMmYy } from 'material-design/ReleaseCreate/steps/Distribution/utils/formatDateToDdMmYy';
import { getOutletTitleById } from 'material-design/ReleaseCreate/steps/Distribution/utils/getOutletTitleById';

// Context
import { LangContext } from 'contexts/LangContext';
import { UIContext } from 'contexts/UIContext';

// Icons
import pencil from 'images/pencil.svg';
import remove_cart from 'images/remove_cart.svg';

// Styles
import styles from './DataBar.module.css';

const DataBar = ({
	item,
	allOutlets,
	outlets,
	modalType,
	personal,
	setPersonal,
	containerStyles,
	resetErrors,
	resetBackErrors,
}) => {
	const { lang } = useContext(LangContext);
	const { showModal } = useContext(UIContext);

	const [personalOutlets, setPersonalOutlets] = useState([]);
	const [distributionOutlets, setDistributionOutlets] = useState([]);

	const deleteHandler = () => {
		if (resetBackErrors) {
			resetBackErrors();
		}

		setPersonal({ ...personal, distribution_outlets: null });
	};

	useEffect(() => {
		const outlets =
			personal?.outlets && typeof personal.outlets === 'string'
				? personal.outlets.split(',')
				: personal.outlets;
		setPersonalOutlets(outlets);

		if (personal?.distribution_outlets?.length) {
			if (typeof personal.distribution_outlets[0].outlets === 'string') {
				const outlets = personal.distribution_outlets[0].outlets.split(',');
				setDistributionOutlets(outlets);
			} else {
				setDistributionOutlets(personal.distribution_outlets[0].outlets);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [personal]);

	return (
		<div
			className={styles.container}
			style={containerStyles ? containerStyles : {}}
		>
			<div
				className={styles.dataBlock}
				style={!modalType ? { gridTemplateColumns: '1fr 6fr' } : {}}
			>
				{personal.delivery_start_time && (
					<div className={styles.dataTitle}>
						<FormattedMessage id="rod.release.create.step.distribution_date.data_title.shipment" />
					</div>
				)}
				{!outlets ? (
					<>
						{personal.delivery_start_time && (
							<div className={styles.dataInfo}>
								{personal.delivery_start_time.includes('T')
									? getDateData(personal.delivery_start_time).dateRow
									: formatDateToDdMmYy(personal.delivery_start_time)}
							</div>
						)}
					</>
				) : (
					<>
						{personal.distribution_outlets[0].delivery_start_time && (
							<div className={styles.dataInfo}>
								{personal.distribution_outlets[0].delivery_start_time.includes(
									'T'
								)
									? getDateData(
											personal.distribution_outlets[0].delivery_start_time
									  ).dateRow
									: formatDateToDdMmYy(
											personal.distribution_outlets[0].delivery_start_time
									  )}
							</div>
						)}
					</>
				)}

				{personal.sale_start_date && !outlets && (
					<div className={styles.dataTitle}>
						<FormattedMessage id="rod.release.create.step.distribution_date.data_title.start_sale" />
					</div>
				)}
				{personal.sale_start_date && !outlets && (
					<div className={styles.dataInfo}>
						{personal.type === 'asap' ? (
							<FormattedMessage id="rod.release.create.step.distribution.asap-date" />
						) : (
							<>
								{personal.sale_start_date.includes('T')
									? getDateData(personal.sale_start_date).dateRow
									: formatDateToDdMmYy(personal.sale_start_date)}
							</>
						)}
					</div>
				)}

				{(modalType === 'distribution_outlets_edit' || !modalType) && (
					<div className={styles.dataTitle}>
						<FormattedMessage id="rod.release.create.step.distribution_date.data_title.outlets" />
					</div>
				)}
				{modalType === 'distribution_outlets_edit' && (
					<div className={styles.dataInfoOutlets}>
						{distributionOutlets.map((item, index) => (
							<span key={index} className={styles.outletSpan}>
								{getOutletTitleById(item, outlets)}
							</span>
						))}
					</div>
				)}

				{/* Confirm step */}
				{!modalType && (
					<div className={styles.dataInfoOutlets}>
						{allOutlets.length === personalOutlets.length && (
							<span>
								<FormattedMessage id="rod.release.create.step.distribution.input_radio3" />
							</span>
						)}
						{allOutlets.length !== personalOutlets.length &&
							getArrayOfOutletsTitles(allOutlets, personal.outlets).map(
								(item, index) => (
									<>
										{item && (
											<span key={index} className={styles.outletSpan}>
												{item}
											</span>
										)}
									</>
								)
							)}
					</div>
				)}
			</div>
			{item && (
				<div className={styles.controls}>
					<button
						onClick={showModal(
							{
								item,
								outlets,
								lang,
								personal,
								setPersonal,
								resetErrors,
								resetBackErrors,
							},
							modalType
						)}
					>
						<img src={pencil} alt="pencil" />
					</button>
					{modalType === 'distribution_outlets_edit' && (
						<button onClick={deleteHandler}>
							<img src={remove_cart} alt="remove_cart" />
						</button>
					)}
				</div>
			)}
		</div>
	);
};

export default DataBar;
