export const getArrayOfOutletsTitles = (allOutlets, personalOutlets) => {
	if (typeof personalOutlets === 'string') {
		const outlets = personalOutlets.split(',');
		const outletsTitles = outlets.map((id) => {
			const outlet = allOutlets
				.filter((item) => !item.is_recommended)
				.find((item) => +item.id === +id);
			if (outlet) {
				return outlet.title;
			} else return null;
		});

		const sortedArr = outletsTitles
			.filter((item) => item !== null)
			.sort((a, b) => a.localeCompare(b));

		return sortedArr;
	}
};
