// Core
import React, { useEffect, useState, useRef, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import union from 'lodash/union';
import moment from 'moment';

// Constants

import { isForCom } from 'constants/urlConstants';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { LangContext } from 'contexts/LangContext';
import { RootContext } from 'contexts/RootContext';

// Utils
import { formatPublishDate } from 'utils';
import { compose } from 'recompose';
import { withRoot, withAuth, withUI } from 'hocs';

// UI
import { Loading } from 'components';
import RepertoireInfoFilter from 'material-design/components/RepertoireInfoFilter';
import MainInfoTable from './MainInfoTable/MainInfoTable';
import { NoData } from 'components';
import Dispute from './MainInfoTable/Dispute/Dispute';

// Styles
import styles from './RepertoireCompositionInfo.module.css';

const RepertoireCompositionInfo = (props) => {
	const { accountId, countryId } = useContext(AuthContext);
	const { lang } = useContext(LangContext);
	const {
		getCompositionMetadata,
		getCompositionOutlets,
		getCompositionOwnership,
		getCompositionTypes,
		getExploitationTypes,
		getCommercialModels,
		getGenres,
		getCountries,
		getLanguages,
		getCompositionsOriginRightholders,
		getContributorRoles,
		getAccountPersonalData,
	} = useContext(RootContext);

	const [loading, setLoading] = useState(true);
	const [unknownError, setUnknownError] = useState('');
	const [isChevronOpen, setIsChevronOpen] = useState(false);
	const [repertoireData, setRepertoireData] = useState({
		repertoireMetadata: {},
		exploitationTypes: [],
		repertoireOutlets: [],
		repertoireProducts: [],
		repertoireOwnership: {},
		repertoireTypes: [],
		rightTypes: [],
		commercialModels: [],
	});
	const [ownershipTableData, setOwnershipTableData] = useState([]);
	const [langs, setLangs] = useState([]);
	const [filtersData, setFiltersData] = useState({});
	const [errors] = useState({});
	const [countries, setCountries] = useState([]);
	const [genres, setGenres] = useState([]);
	const [isFilterExists, setIsFilterExists] = useState(false);
	const [originRightholders, setOriginRightholders] = useState([]);
	const [contributorRoles, setContributorRoles] = useState([]);
	const [requestParameters, setRequestParameters] = useState({});
	const [incompatibilityError, setIncompatibilityError] = useState('');

	const prevCountRef = useRef();
	const match = useRouteMatch();
	const compositionId = match.params.id;

	const notSelected = {
		id: -1,
		code: 'EMPTY',
		title_ru: 'Не выбрано',
		title_en: 'Not selected',
	};

	useEffect(() => {
		if (Object.keys(filtersData).length)
			localStorage.setItem('compositionFilters', JSON.stringify(filtersData));
	}, [filtersData]);

	useEffect(() => {
		const p0 = getCountries();
		const p1 = getGenres();
		const p2 = getCommercialModels();
		const p3 = getCompositionTypes();
		const p4 = getExploitationTypes();
		const p5 = getCompositionMetadata(accountId, compositionId);
		const p6 = getAccountPersonalData(accountId);

		Promise.all([p0, p1, p2, p3, p4, p5, p6])
			.then((values) => {
				setCountries([...correctCountries(values[0])]);
				setGenres(values[1]);

				const rightTypesData =
					values[4].data.length && !isFilterExists
						? values[4].data[0].right_types
						: [];

				setRepertoireData((prev) => ({
					...prev,
					commercialModels: values[2].data,
					repertoireTypes: values[3].data,
					exploitationTypes: values[4].data,
					rightTypes: rightTypesData,
					repertoireMetadata: values[5].data,
				}));

				if (
					localStorage.getItem('compositionFilters') &&
					localStorage.getItem('compositionFilters') !== '{}'
				) {
					setIsFilterExists(true);
					setFiltersData({
						...JSON.parse(localStorage.getItem('compositionFilters')),
						country_id: values[5]?.data?.created_country_id
							? values[5]?.data?.created_country_id
							: countryId
							? countryId
							: isForCom
							? 18
							: 1,
					});
				} else {
					setFiltersData((prev) => ({
						...prev,
						country_id: values[5]?.data?.created_country_id
							? values[5]?.data?.created_country_id
							: countryId
							? countryId
							: isForCom
							? 18
							: 1,
						exploitation_type: values[4].data[0],
						right_type: values[4].data[0].right_types[0],
					}));
				}
			})
			.then(() => {
				const p0 = getLanguages();
				const p1 = getCompositionsOriginRightholders(accountId, compositionId);
				const p2 = getContributorRoles();
				Promise.all([p0, p1, p2]).then((values) => {
					setLangs(values[0]);
					setOriginRightholders(values[1].data);
					setContributorRoles(values[2].data);
				});
			})
			.catch((error) => console.error('promiseAll error', error));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (contributorRoles.length) {
			const exploitationTypeID =
				filtersData.exploitation_type &&
				Object.keys(filtersData.exploitation_type).length > 0
					? filtersData.exploitation_type.id
					: '';
			const rightTypeID =
				filtersData.right_type && Object.keys(filtersData.right_type).length > 0
					? filtersData.right_type.id
					: '';
			const outletID =
				filtersData.outlet && Object.keys(filtersData.outlet).length > 0
					? filtersData.outlet.id
					: '';
			const productID =
				filtersData.product && Object.keys(filtersData.product).length > 0
					? filtersData.product.id
					: '';

			const currDate = new Date();
			const currMonth = currDate.getMonth() + 1;
			const currYear = currDate.getFullYear();
			let currDay = moment().format('MMM Do YY').split(' ')[1];
			currDay = isNaN(currDay.slice(0, 2))
				? currDay.slice(0, 1)
				: currDay.slice(0, 2);
			let dateFrom =
				currDay.padStart(2, '0') +
				'.' +
				currMonth.toString().padStart(2, '0') +
				'.' +
				currYear;

			setRequestParameters({
				country_id: filtersData.country_id,
				exploitationTypeID: exploitationTypeID,
				rightTypeID: rightTypeID,
				outletID: outletID,
				productID: productID,
				dateFrom: dateFrom,
			});

			if (
				prevCountRef.current &&
				(filtersData.country_id !== prevCountRef.current?.country_id ||
					filtersData.exploitation_type.id !==
						prevCountRef.current?.exploitation_type.id ||
					filtersData.right_type.id !== prevCountRef.current?.right_type.id)
			) {
				const p0 = getCompositionOutlets(
					accountId,
					compositionId,
					'en',
					filtersData.country_id,
					exploitationTypeID,
					rightTypeID
				);
				const p1 = getCompositionOutlets(
					accountId,
					compositionId,
					'ru',
					filtersData.country_id,
					exploitationTypeID,
					rightTypeID
				);

				Promise.all([p0, p1])
					.then((values) => {
						setRepertoireData((prev) => ({
							...prev,
							repertoireOutlets: values[0].data.map((outlet) => {
								return {
									...outlet,
									title_en: outlet.title,
									title_ru: values[1].data.find(
										(outlet_ru) => outlet_ru.id === outlet.id
									).title,
								};
							}),
						}));
					})
					.then(() => {
						if (filtersData.outlet && Object.keys(filtersData.outlet).length) {
							getProductsByOutletID(filtersData.outlet);
						} else {
							setRepertoireData((prev) => ({
								...prev,
								repertoireProducts: [],
							}));
						}
					})
					.then(() => {
						getCompositionOwnership(
							accountId,
							compositionId,
							filtersData.country_id,
							exploitationTypeID,
							rightTypeID,
							outletID,
							productID,
							dateFrom,
							lang
						).then((ownershipData) => {
							setRepertoireData((prev) => ({
								...prev,
								repertoireOwnership:
									ownershipData.status === 'ok'
										? ownershipData.data.splitsheet
										: { status: 'Not Ok' },
							}));

							if (ownershipData.status === 'ok') {
								setIncompatibilityError('');
								combineOwnershipData(ownershipData.data.splitsheet);
							} else if (ownershipData.status === 0 && ownershipData.error) {
								setOwnershipTableData([]);
								setIncompatibilityError(ownershipData.error);
							} else if (ownershipData.status !== 0 && ownershipData.error) {
								setUnknownError(ownershipData.error);
							}
							setLoading(false);
						});
					});
			} else if (Object.keys(filtersData).length) {
				getCompositionOwnership(
					accountId,
					compositionId,
					filtersData.country_id,
					exploitationTypeID,
					rightTypeID,
					outletID,
					productID,
					dateFrom,
					lang
				).then((ownershipData) => {
					setRepertoireData((prev) => ({
						...prev,
						repertoireOwnership:
							ownershipData.status === 'ok'
								? ownershipData.data.splitsheet
								: { status: 'Not Ok' },
					}));

					if (ownershipData.status === 'ok') {
						setIncompatibilityError('');
						combineOwnershipData(ownershipData.data.splitsheet);
					} else if (ownershipData.status === 0 && ownershipData.error) {
						setOwnershipTableData([]);
						setIncompatibilityError(ownershipData.error);
					} else if (ownershipData.status !== 0 && ownershipData.error) {
						setUnknownError(ownershipData.error);
					}
					setLoading(false);
				});
			}
			prevCountRef.current = filtersData;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersData, contributorRoles, lang]);

	const addContributors = (data) => {
		const titleBlockHolders = originRightholders.filter(
			(item) => item.titles?.length
		);
		if (titleBlockHolders.length) {
			titleBlockHolders.forEach((item) => {
				const recordWithItemsBlock = data.find(
					(lic) => lic.account_id === item.account_id
				);
				if (recordWithItemsBlock) {
					recordWithItemsBlock['titles'] = item.titles;
				}
			});
			return data;
		} else {
			return data;
		}
	};

	const combineOwnershipData = (ownershipData) => {
		ownershipData = originRightholders.length
			? addContributors(Object.values(ownershipData))
			: Object.values(ownershipData);
		let ownershipJustRecords = [];
		let commonRecords = [];
		let restOriginRecords = [];
		ownershipData.forEach((item) => {
			const commonAccount = originRightholders.find(
				(origin) => origin.account_id === item.account_id
			);
			const ownershipRoles = Array.isArray(item.roles)
				? []
				: Object.keys(item.roles).map((str) => +str);
			if (commonAccount) {
				const rightholdersRoles = commonAccount.contributor_roles;
				item.roles = union(ownershipRoles, rightholdersRoles);
				commonRecords.push(item);
			} else {
				item.roles = ownershipRoles;
				ownershipJustRecords.push(item);
			}
		});

		originRightholders.forEach((item) => {
			if (
				!commonRecords.find((common) => common.account_id === item.account_id)
			) {
				if (item.titles && item.titles.length === 0) {
					delete item.titles;
				}

				restOriginRecords.push({
					...item,
					title: item.account_title,
					licensors: [],
					roles: item.contributor_roles,
				});
			}
		});

		const combinedOwnershipData = [
			...ownershipJustRecords,
			...commonRecords,
			...restOriginRecords,
		];
		setOwnershipTableData(combinedOwnershipData);
	};

	function correctCountries(data) {
		const modifyTitle = (title) => {
			const exeptions = ['the', 'of', 'and', 'и'];
			const lowerTitle = title.toLowerCase();
			if (!lowerTitle.includes(' '))
				return lowerTitle.charAt(0).toUpperCase() + lowerTitle.slice(1);

			const titleAsArray = lowerTitle.split(' ');
			const modifiedTitle = titleAsArray
				.map((word) => {
					return exeptions.includes(word)
						? word
						: word.charAt(0).toUpperCase() + word.slice(1);
				})
				.join(' ');
			return modifiedTitle;
		};

		const modifiedCountries = data.map((obj) => {
			let result = obj;
			result.title_en = modifyTitle(obj.title_en);
			result.title_ru = modifyTitle(obj.title_ru);
			return result;
		});
		return modifiedCountries;
	}

	const getGenresTitlesByTypes = (genresIDsArr) => {
		const result = genresIDsArr.map(
			(id) =>
				genres.find((genre) => genre.id === id || id === genre.code)[
					`title_${lang}`
				]
		);
		return result.join(', ');
	};

	const getTypeTitleById = (id) => {
		return repertoireData.repertoireTypes.find((type) => type.id === id)[
			`title_${lang}`
		];
	};

	const getProductsByOutletID = (e) => {
		const {
			rootContext: { getCompositionProducts },
			authContext: { accountId },
		} = props;
		const p0 = getCompositionProducts(accountId, compositionId, e.id);
		const p1 = getCompositionProducts(accountId, compositionId, e.id, 'ru');

		Promise.all([p0, p1])
			.then((values) => {
				setRepertoireData((prev) => ({
					...prev,
					repertoireProducts: values[0].data.map((product) => {
						return {
							...product,
							title_en: product.title,
							title_ru: values[1].data.find(
								(product_ru) => product_ru.id === product.id
							).title,
						};
					}),
				}));
				return values[0];
			})
			.then((products) => {
				if (
					!filtersData.product ||
					!products.data.find((prod) => prod.id === filtersData.product.id)
				) {
					setFiltersData((prev) => ({
						...prev,
						outlet: e,
						product: {},
					}));
				} else
					setFiltersData((prev) => ({
						...prev,
						outlet: e,
					}));
			});
	};

	const changeField = (field) => (e) => {
		let newFilter = {};

		if (e.code === 'EMPTY' && field === 'right_type') {
			setFiltersData((prev) => ({
				...prev,
				[field]: {},
				outlet: {},
				product: {},
			}));
			setRepertoireData((prev) => ({
				...prev,
				repertoireOutlets: [],
				repertoireProducts: [],
			}));

			return;
		}
		if (e.code === 'EMPTY') {
			setFiltersData((prev) => {
				return field === 'outlet'
					? {
							...prev,
							[field]: {},
							product: {},
					  }
					: {
							...prev,
							[field]: {},
					  };
			});
			setRepertoireData((prev) => {
				return field === 'outlet'
					? {
							...prev,
							repertoireProducts: [],
					  }
					: {
							...prev,
					  };
			});
			return;
		}

		switch (field) {
			case 'exploitation_type':
				setRepertoireData((prev) => ({
					...prev,
					rightTypes: e.right_types,
				}));
				setFiltersData((prev) => ({
					...prev,
					exploitation_type: e,
					right_type:
						e.right_types && e.right_types.length
							? e.right_types[0]
							: notSelected,
					outlet: {},
					product: {},
				}));
				break;

			case 'outlet':
				getProductsByOutletID(e);
				break;

			case 'country_id':
				newFilter[field] = e.id;
				setFiltersData((prev) => ({
					...prev,
					outlet: {},
					product: {},
				}));
				break;

			case 'right_type':
				newFilter[field] = e;
				setFiltersData((prev) => ({
					...prev,
					outlet: {},
					product: {},
				}));
				break;
			default:
				newFilter[field] = e;
				break;
		}
		if (field !== 'exploitation_type' && field !== 'outlet')
			setFiltersData((prev) => ({
				...prev,
				...newFilter,
			}));
	};

	const getCountryTitle = () => {
		const country = countries.find(
			(country) =>
				country.id === repertoireData.repertoireMetadata.created_country_id
		);
		return country ? country[`title_${lang}`] : '';
	};

	const getLangsString = (languagesIds) => {
		if (langs.length) {
			const resultedList = languagesIds.map(
				(languageId) =>
					langs.find((item) => item.id === languageId)[`title_${lang}`]
			);
			return resultedList.join(',');
		}
	};
	const handleOnClick = () => {
		setIsChevronOpen((prev) => !prev);
	};
	const mainRender = () => {
		return (
			<>
				<div className={styles.infoBlock}>
					<div>
						<span className={styles.title}>
							<FormattedMessage id={'rod.composition.info.title'} />
						</span>
						<div className={styles.titleInfo}>
							<div className={styles.titleInfoItem}>
								<span className={styles.RepertoireInfoItem__title}>
									<FormattedMessage id={'rod.composition.info.creation_date'} />
								</span>
								<span>
									{repertoireData &&
									repertoireData.repertoireMetadata &&
									repertoireData.repertoireMetadata.published_date
										? formatPublishDate(
												repertoireData.repertoireMetadata.published_date
										  )
										: '-'}
								</span>
							</div>
							{repertoireData &&
								repertoireData.repertoireMetadata &&
								repertoireData.repertoireMetadata.catalogue_number && (
									<div className={styles.titleInfoItem}>
										<span className={styles.RepertoireInfoItem__title}>
											<FormattedMessage
												id={'rod.composition.info.catalog_number'}
											/>
										</span>
										<span>
											{repertoireData &&
											repertoireData.repertoireMetadata &&
											repertoireData.repertoireMetadata.catalogue_number
												? repertoireData.repertoireMetadata.catalogue_number
												: '-'}
										</span>
									</div>
								)}

							<div className={styles.titleInfoItem}>
								<span className={styles.RepertoireInfoItem__title}>
									<FormattedMessage
										id={'rod.composition.info.lyrics_language'}
									/>
								</span>
								<span>
									{(repertoireData &&
										repertoireData.repertoireMetadata &&
										repertoireData.repertoireMetadata.languages &&
										getLangsString(
											repertoireData.repertoireMetadata.languages
										)) ||
										'-'}
								</span>
							</div>
							<div className={styles.titleInfoItem}>
								<span className={styles.RepertoireInfoItem__title}>
									<FormattedMessage
										id={'rod.composition.info.creation_territory'}
									/>
								</span>

								<span className={styles.RepertoireInfoItem__flagContainer}>
									<span>
										{(countries &&
											repertoireData.repertoireMetadata &&
											getCountryTitle()) ||
											'-'}
									</span>
								</span>
							</div>
							<div className={styles.titleInfoItem}>
								<span className={styles.RepertoireInfoItem__title}>
									<FormattedMessage
										id={'rod.composition.info.composition_type'}
									/>
								</span>
								<span>
									{(repertoireData &&
										repertoireData.repertoireMetadata &&
										repertoireData.repertoireMetadata.composition_type_id &&
										getTypeTitleById(
											repertoireData.repertoireMetadata.composition_type_id
										)) ||
										'-'}
								</span>
							</div>
							<div className={styles.titleInfoItem}>
								<span className={styles.RepertoireInfoItem__title}>
									<FormattedMessage id={'rod.composition.info.genres'} />
								</span>
								<span>
									{(repertoireData &&
										repertoireData.repertoireMetadata &&
										repertoireData.repertoireMetadata.genres &&
										getGenresTitlesByTypes(
											repertoireData.repertoireMetadata.genres
										)) ||
										'-'}
								</span>
							</div>
						</div>
						<div className={styles.RepertoireSongInfo__main}>
							<MainInfoTable
								repertoireData={repertoireData}
								ownershipTableData={ownershipTableData}
								requestParameters={requestParameters}
								contributorRoles={contributorRoles}
								incompatibilityError={incompatibilityError}
								unknownError={unknownError}
								isChevronOpen={isChevronOpen}
								handleOnClick={handleOnClick}
							/>
						</div>
						{unknownError &&
							typeof unknownError === 'object' &&
							unknownError.conditions &&
							unknownError.message &&
							!incompatibilityError && <Dispute unknownError={unknownError} />}
						{!ownershipTableData.length &&
							!unknownError &&
							!unknownError.conditions &&
							!unknownError.message &&
							!incompatibilityError && <NoData non_margin={true} />}
						{incompatibilityError && (
							<div className={styles.incompatibilityError}>
								{incompatibilityError}
							</div>
						)}
					</div>
					<div>
						<span className={styles.title}>
							<FormattedMessage id={'rod.composition.info.filters.title'} />
						</span>
						<RepertoireInfoFilter
							repertoireData={repertoireData}
							filtersData={filtersData}
							changeField={changeField}
							errors={errors}
							countries={countries}
						/>
					</div>
				</div>
			</>
		);
	};
	return loading ? <Loading /> : <>{mainRender()}</>;
};
export default compose(withAuth, withRoot, withUI)(RepertoireCompositionInfo);
