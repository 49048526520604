/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

import { Button } from 'components/Buttons/';
import { Row } from 'components/Grid';
import { Label } from 'components/Labels/Label';
import Progress from 'components/Progress/Progress';
import { Form } from 'containers/Layout';
import { withAuth, withRoot, withUI } from 'hocs';
import attachment from 'images/attachment.svg';
import { ReactComponent as Bullet } from 'images/bullet_green.svg';
import close from 'images/close_black.svg';
import DNDUpload from 'pages/user/repertoire/Add/components/DNDUpload/DNDUpload';
import React, { PureComponent } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import styles from './Add.module.css';
import { reachGoal } from 'utils';

class Add extends PureComponent {
	static propTypes = {};
	state = {
		isFileSelected: false,
		isFileLoading: false,
		isFileLoaded: false,
		isFileError: false,
		progress: 0,
	};

	submitHandler = ({ username, password }) => {
		const {
			authContext: { login },
		} = this.props;

		login({
			username,
			password,
		});
	};

	nextHandler = () => {
		this.setState({ step: this.state.step + 1 });
	};

	submitHandler = () => {
		this.setState({ step: this.state.step + 1 });
	};

	selectFileHandler = (files) => {
		this.setState({
			isFileSelected: true,
			file: files[0],
		});
	};

	uploadFileHandler = () => {
		this.setState({
			isFileLoading: true,
		});

		const id = setInterval(() => {
			const progress = this.state.progress;
			if (progress >= 100) {
				clearInterval(id);
				this.setState({ isFileLoaded: true });
			} else {
				this.setState({ progress: progress + 10 });
			}
		}, 200);
	};

	render() {
		return (
			<React.Fragment>
				<div className={styles.Page}>
					<Form>
						{this.state.isFileLoaded
							? this.renderFileLoaded()
							: this.state.isFileLoading
							? this.renderFileLoading()
							: this.renderFileSelect()}
						{}
					</Form>
				</div>
			</React.Fragment>
		);
	}

	renderStep() {
		switch (this.state.step) {
			case 1:
			case 2:
			case 3:
			case 4:
			default:
				return;
		}
	}

	renderFileLoaded() {
		return (
			<div>
				<Row>
					<Label
						text={'загрузка произведений, фонограм и релизов'}
						variant={'text-30-140'}
					/>
				</Row>
				<Row>
					<ul>
						<li className={styles.Step}>
							<div>
								{/* <img src={bullet} alt="" /> */}
								<Bullet className={styles.svg_branding} />
							</div>
							<div>
								<Label
									text={'успешно загружено: 5 позиций'}
									variant={'text-24-140'}
								/>
							</div>
						</li>
					</ul>
				</Row>
				<Row>
					<Label
						text={
							'присоедините ваш репертуар к услуге для начала лицензирования и сбора роялти'
						}
						variant={'text-30-140'}
					/>
				</Row>
				<Row>
					<Button
						onClick={this.uploadFileHandler}
						text={<FormattedMessage id={'rod.action.select_conditions'} />}
						variant={'primary'}
					/>
				</Row>
			</div>
		);
	}

	renderFileLoading() {
		return (
			<div>
				<Row>
					<Label
						text={'загрузка произведений, фонограм и релизов'}
						variant={'text-30-140'}
					/>
					<Label
						text={`спасибо! Файлы ${this.state.file.name} приняты в обработку`}
						variant={'text-16-140'}
					/>
				</Row>
				<Row>
					<Progress value={this.state.progress} />
				</Row>
				<Row>
					<ul>
						<li className={styles.Step}>
							<div>
								{/* <img src={bullet} alt="" /> */}
								<Bullet className={styles.svg_branding} />
							</div>
							<div>
								<Label
									text={'файл успешно загружен в систему'}
									variant={'text-24-140'}
								/>
							</div>
						</li>
						<li className={styles.Step}>
							<div>
								<Bullet className={styles.svg_branding} />
							</div>
							<div>
								<Label
									text={'успешная конвертация параметров'}
									variant={'text-24-140'}
								/>
							</div>
						</li>
					</ul>
				</Row>
			</div>
		);
	}

	renderFileSelect() {
		const {
			UIContext: { showModal },
		} = this.props;
		return (
			<div>
				<Row>
					<Label
						text={'пожалуйста, загрузите репертуар'}
						variant={'text-30-140'}
					/>
				</Row>
				<Row>
					<ul>
						<li className={styles.Step}>
							<div>
								{/* <img src={bullet} alt="" /> */}
								<Bullet className={styles.svg_branding} />
							</div>
							<div>
								<Label
									text={'скачайте образец репертуарного файла'}
									variant={'text-24-140'}
								/>
								<a href="#" target="_blank" rel="noreferrer">
									скачать образец файла
								</a>
							</div>
						</li>
						<li className={styles.Step}>
							<div>
								<Bullet className={styles.svg_branding} />
							</div>
							<div>
								<Label
									text={'заполните файл согласно инструкции'}
									variant={'text-24-140'}
								/>
								<Button
									variant={'a'}
									onClick={showModal({
										title: (
											<FormattedMessage
												id={'rod.help.identifiers.modal.title'}
											/>
										),
										text: (
											<FormattedHTMLMessage
												id={'rod.help.identifiers.modal.text'}
											/>
										),
									})}
								>
									инструкция по заполнению
								</Button>
							</div>
						</li>
						<li className={styles.Step}>
							<div>
								{/* <img src={bullet} alt="" /> */}
								<Bullet className={styles.svg_branding} />
							</div>
							<div>
								<Label
									text={'загрузите заполненный вами репертуарный файл'}
									variant={'text-24-140'}
								/>
								{this.state.isFileSelected ? (
									<div className={styles.Attachment}>
										<img src={attachment} alt="" />
										{this.state.file.name}
										<img src={close} className={styles.DeleteFile} alt="" />
									</div>
								) : (
									<DNDUpload onFileSelect={this.selectFileHandler} />
								)}
							</div>
						</li>
						{this.state.isFileSelected && (
							<Row>
								<Button
									onClick={this.uploadFileHandler}
									text={<FormattedMessage id={'rod.action.send'} />}
									variant={'primary'}
								/>
							</Row>
						)}
					</ul>
				</Row>
			</div>
		);
	}
}

export default compose(withRoot, withAuth, withUI)(Add);
