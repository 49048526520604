// Core
import { CloseButton } from 'components/Buttons';
import { Button } from 'components/Buttons';
import { Col, Container, Row } from 'components/Grid';
import { FormInput } from 'components/Form/FormInput';
import { compose } from 'recompose';
import { withLang, withRoot, withUI } from 'hocs';
import { DropZone } from 'components/Upload/DropZone';
import React from 'react';
import posed, { PoseGroup } from 'react-pose';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import { FaqAdminLang } from 'material-design/components/FaqAdminLang';
import BaseModal from './BaseModal';
import styles from './Modal.module.css';
import debounce from 'lodash.debounce';
import { showError } from 'validators/showError';

const Modal = posed.div({
	enter: {
		x: 0,
		opacity: 1,
		transition: {
			x: {
				ease: 'easeIn',
			},
			default: { duration: 300 },
		},
	},
	exit: {
		x: '100%',
		opacity: 0,
		transition: { duration: 250 },
	},
});

const Shade = posed.div({
	enter: { opacity: 0.5 },
	exit: { opacity: 0 },
});

const ReportsManualModal = (props) => {
	const [isVisible, setIsVisible] = useState(true);
	const [errors, setErrors] = useState({});
	const [backErrors, setBackErrors] = useState({});
	const [reportLang, setReportLang] = useState('en');

	const [archiveAttachment, setArchiveAttachment] = useState({
		file: null,
		fileData: null,
	});
	const [xlsAttachment, setXlsAttachment] = useState({
		file: null,
		fileData: null,
	});
	const [enConfirmed, setEnConfirmed] = useState(false);
	const [ruConfirmed, setRuConfirmed] = useState(false);
	const [isShowNotice, setIsShowNotice] = useState(false);
	const [isConfirmedBtnActive, setIsConfirmedBtnActive] = useState(true);
	const [isShowConfirmQuestion, setIsShowConfirmQuestion] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [reportPrepared, setReportPrepared] = useState('');
	const [reportsModalRadio, setReportsModalRadio] = useState('archive');
	const [fileLoadingData, setFileLoadingData] = useState({});
	const [fileTabReady, setFileTabReady] = useState(false);

	const {
		onClose,
		prepareFileToUpload,
		refreshList,
		distributionId,
		updateAdminReportsDistributions,
		distributionNames,
		postAdminDistributionReportFile,
		editManualReportFile,
	} = props;

	const currencies = [
		{ id: 1, title: 'EUR', code: '€' },
		{ id: 2, title: 'USD', code: '$' },
		{ id: 3, title: 'GBP', code: '£' },
		{ id: 4, title: 'RUB', code: '₽' },
	];

	const [distributionData, setDistributionData] = useState({
		heading: Object.keys(distributionNames).length
			? distributionNames.heading
			: '',
		heading_en: Object.keys(distributionNames).length
			? distributionNames.heading_en
			: '',
	});

	useEffect(() => {
		if (
			distributionData.heading_en &&
			archiveAttachment.file &&
			xlsAttachment.file
		) {
			setEnConfirmed(true);
		} else setEnConfirmed(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [distributionData.heading_en, archiveAttachment, xlsAttachment]);

	useEffect(() => {
		if (
			distributionData.heading &&
			archiveAttachment.file &&
			xlsAttachment.file
		) {
			setRuConfirmed(true);
		} else setRuConfirmed(false);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [distributionData.heading, archiveAttachment, xlsAttachment]);

	useEffect(() => {
		if (
			fileLoadingData &&
			Object.keys(fileLoadingData).length === 3 &&
			xlsAttachment.file
		) {
			setFileTabReady(
				!Object.values(fileLoadingData).some((item) => {
					return (
						item === '' ||
						(item && typeof item === 'object' && Object.keys(item).length === 0)
					);
				})
			);
		} else setFileTabReady(false);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fileLoadingData, xlsAttachment]);

	const willUnmount = (withData = true) => {
		setIsVisible(false);
		setTimeout(() => {
			if (withData && reportsModalRadio === 'file') refreshList(true);
			onClose();
		}, 300);
	};

	const titleChange = (e) => {
		setErrors({});
		if (e.target.value.length > 85) return;
		if (reportLang === 'en') {
			setDistributionData((prev) => ({ ...prev, heading_en: e.target.value }));
		} else {
			setDistributionData((prev) => ({ ...prev, heading: e.target.value }));
		}
	};

	const handleFileRemove = (s) => {
		setBackErrors({});
		if (s === 'archiveAttachment') {
			setArchiveAttachment({
				file: null,
				fileData: null,
			});
		} else {
			setXlsAttachment({
				file: null,
				fileData: null,
			});
		}
	};
	const handleArchiveUpload = (files) => {
		const fileTypes = ['application/zip', 'application/x-zip-compressed'];
		setErrors({});
		if (!fileTypes.includes(files[0].type)) {
			setErrors({ file: [{ rule: 'archive_wrong_format' }] });
			return;
		}

		const correctedFile = Object.assign(files[0], {
			preview: URL.createObjectURL(files[0]),
		});
		const fd = new FormData();
		fd.append('file', correctedFile);

		setArchiveAttachment({
			file: correctedFile,
			fileData: files[0],
		});

		return Promise.resolve();
	};

	const handleXlsUpload = (files) => {
		const fileTypes = [
			'application/xls',
			'application/x-xls',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		];
		setErrors({});
		if (!fileTypes.includes(files[0].type)) {
			setErrors({ file: [{ rule: 'archive_wrong_format' }] });
			return;
		}

		const fd = new FormData();
		fd.append('file', files[0]);

		setXlsAttachment({
			file: fd,
			fileData: files[0],
		});

		return Promise.resolve();
	};

	const preConfirmData = () => {
		setBackErrors({});
		switch (reportsModalRadio) {
			case 'archive':
				if (!enConfirmed || !ruConfirmed) {
					setReportLang(enConfirmed ? 'ru' : 'en');
					setIsShowNotice(true);
				} else if (archiveAttachment.file && xlsAttachment.file) {
					setIsShowNotice(false);
					setIsShowConfirmQuestion(true);
				}
				break;
			case 'file':
				if (xlsAttachment.file && Object.keys(fileLoadingData).length === 3) {
					setIsShowNotice(false);
					setIsShowConfirmQuestion(true);
				}
				break;
			default:
				break;
		}
	};
	const debouncedSendData = debounce(() => prepareToSendData(), 500);

	const prepareToSendData = () => {
		sendArchive();
	};

	const sendArchive = () => {
		if (reportsModalRadio === 'file') {
			const { account_id, royalties, currency } = fileLoadingData;
			const fileData = {
				account_id,
				royalties,
				currency: currency.code,
			};

			postAdminDistributionReportFile(distributionId, fileData).then((res) => {
				editManualReportFile(res.data.id, xlsAttachment.file)
					.then((res) => {
						willUnmount();
					})
					.catch((err) => console.error('Error:', err));
			});
		} else {
			updateAdminReportsDistributions(distributionId, distributionData)
				.then((res) => {
					prepareFileToUpload(
						archiveAttachment,
						distributionId,
						reportPrepared,
						xlsAttachment
					)
						.then(() => willUnmount())
						.catch((error) => {
							setBackErrors(error.response?.data.errors);
							setIsShowConfirmQuestion(false);
							setIsConfirmedBtnActive(true);
						});
				})
				.catch((err) => console.error('Error:', err));
		}
	};

	const langChange = () => {
		setReportLang((prev) => (prev === 'en' ? 'ru' : 'en'));
	};

	const onReportsModalRadio = (event) => {
		setBackErrors({});
		setReportsModalRadio(event.target.value);
		setFileLoadingData({});
		setArchiveAttachment({});
		setXlsAttachment({});
	};

	const changeField = (field) => (e) => {
		switch (field) {
			case 'royalties':
				if (!e.target.value.includes(' ') && !isNaN(+e.target.value))
					setFileLoadingData((prev) => ({
						...prev,
						[field]: e.target.value,
					}));
				break;
			case 'currency':
				setFileLoadingData((prev) => ({ ...prev, [field]: e }));
				break;
			case 'account_id':
				setFileLoadingData((prev) => ({ ...prev, [field]: e.target.value }));
				break;
			default:
				setFileLoadingData((prev) => ({ ...prev, [field]: e }));
				break;
		}
	};

	const getErrorText = (errorsData) => {
		if (Object.keys(errorsData).includes('client_name')) {
			if (errorsData.client_name.length === 1) {
				if (
					errorsData.client_name[0]?.message === 'incorrect identifier format'
				) {
					return (
						<FormattedMessage id="rod.admin.distribution.reports.error.id" />
					);
				}

				if (
					errorsData.client_name[0]?.rule === 'invalid' &&
					!errorsData.client_name[0]?.message
				) {
					return (
						<FormattedMessage id="rod.admin.distribution.reports.error.manual.distribution.title" />
					);
				}
			} else {
				if (
					errorsData.client_name.length === 2 &&
					errorsData.client_name.some(
						(item) => item.message === 'incorrect identifier format'
					) &&
					errorsData.client_name.some(
						(item) => !item.message && item.rule === 'invalid'
					)
				) {
					return (
						<>
							<div style={{marginBottom: '8px'}}>
								<FormattedMessage id="rod.admin.distribution.reports.error.id" />
							</div>
							<div>
								<FormattedMessage id="rod.admin.distribution.reports.error.manual.distribution.title" />
							</div>
						</>
					);
				}
			}
		}
		return <FormattedMessage id="rod.modal.admin_reports_parsing.title" />;
	};

	return (
		<BaseModal className={styles.Modal}>
			<PoseGroup>
				{isVisible && [
					<Shade key="shade" className={styles.Overlay} />,

					<Modal
						key="modal"
						style={{
							marginLeft: 'auto',
						}}
					>
						<Container fluid>
							<Row>
								<Col onClick={() => willUnmount(false)} />
								<Col width={'1'} className={styles.notify}>
									<div
										className={styles.notify__wrapper}
										style={{ width: '400px' }}
									>
										<div className={styles.ReportsModalTitle}>
											<div className={styles.notify__title}>
												<FormattedHTMLMessage
													id={'rod.admin.reports.loading_report'}
												/>
											</div>
											<CloseButton
												black
												small
												className={styles.CloseButtonNotify}
												onClick={() => willUnmount(false)}
											/>
										</div>
										<div className={styles.ReportsModalRadioBtn}>
											<div>
												<input
													type="radio"
													id="reportsModalArchiveLoad"
													name="reportsModalRadio"
													value="archive"
													checked={reportsModalRadio === 'archive'}
													onChange={onReportsModalRadio}
												/>
												<label for="reportsModalArchiveLoad">
													<FormattedMessage
														id={'rod.admin.reports.load_archive'}
													/>
												</label>
											</div>
											<div>
												<input
													type="radio"
													id="reportsModalFileLoad"
													name="reportsModalRadio"
													value="file"
													checked={reportsModalRadio === 'file'}
													onChange={onReportsModalRadio}
												/>
												<label for="reportsModalFileLoad">
													<FormattedMessage
														id={'rod.admin.reports.load_file'}
													/>
												</label>
											</div>
										</div>
										{reportsModalRadio === 'archive' && (
											<>
												<div className={styles.notify__lang}>
													<ul className={styles.Tray}>
														<li>
															<div className={styles.createArticle__lang}>
																<ul className={styles.Tray}>
																	<li
																		onClick={langChange}
																		style={{ maxWidth: '50px' }}
																	>
																		<FaqAdminLang
																			lang={reportLang}
																			className={styles.Lang}
																		/>
																	</li>
																</ul>
															</div>
														</li>
													</ul>
												</div>

												<div
													className={`${styles.reports__title} ${styles.InactiveBlock}`}
												>
													<FormattedMessage
														id={'rod.admin.reports.report_name'}
													>
														{(placeholder) => (
															<input
																type="muiInput"
																name="reportTitle"
																value={
																	reportLang === 'en'
																		? distributionData.heading_en
																		: distributionData.heading
																}
																placeholder={placeholder}
																disabled={true}
																onChange={titleChange}
															/>
														)}
													</FormattedMessage>
													{Object.keys(errors).includes('heading') && (
														<span className={styles.Helper}>
															{showError('reportTitle')(errors.heading[0])}
														</span>
													)}
												</div>

												<div className={styles.DropZone__wrapper}>
													<DropZone
														multiple={false}
														accept={'.zip, .7z'}
														imgType={1}
														classStyles={styles.DropZone}
														dropzoneId={'rod.admin.reports.report_dropbox'}
														onUpload={handleArchiveUpload}
														onRemove={() =>
															handleFileRemove('archiveAttachment')
														}
														kb
														value={
															archiveAttachment.file
																? {
																		client_name:
																			archiveAttachment.fileData.name,

																		size: archiveAttachment.fileData.size,
																		url: archiveAttachment.fileData.path,
																  }
																: null
														}
														maxSize={99991024 * 99991024 * 99100}
													/>
												</div>
												<div className={styles.DropZone__wrapper}>
													<DropZone
														multiple={false}
														accept={'.xls, .xlsx'}
														imgType={1}
														classStyles={styles.DropZone}
														dropzoneId={'rod.admin.reports.report_dropbox_xls'}
														onUpload={handleXlsUpload}
														onRemove={() => handleFileRemove('xlsAttachment')}
														kb
														value={
															xlsAttachment.file
																? {
																		client_name: xlsAttachment.fileData.name,
																		size: xlsAttachment.fileData.size,
																		url: xlsAttachment.fileData.path,
																  }
																: null
														}
														maxSize={99991024 * 99991024 * 99100}
													/>
												</div>
											</>
										)}
										{reportsModalRadio === 'file' && (
											<div className={styles.dataTable}>
												<div className={styles.RoyaltyWrapper}>
													<div className={styles.Royalty}>
														<FormInput
															type={'muiInputNumber'}
															name={'royalties'}
															onChange={changeField}
															errors={errors}
															data={fileLoadingData}
															label={
																<FormattedMessage
																	id={'rod.admin.reports.reports.summ'}
																/>
															}
														/>
														{Object.keys(errors).includes('royalties') && (
															<span className={styles.Helper}>
																{
																	<FormattedMessage
																		id={`rod.error.${errors.royalties[0]?.rule}`}
																	/>
																}
															</span>
														)}
													</div>
													<div className={styles.RoyaltyCurrencies}>
														<FormInput
															type={'MuiSelectCurrencies'}
															name={'currency'}
															onChange={changeField}
															errors={errors}
															data={fileLoadingData}
															className={styles.select}
															label={
																<FormattedMessage
																	id={'rod.admin.reports.reports.currency'}
																/>
															}
															items={currencies}
														/>
														{Object.keys(errors).includes('currency') && (
															<span className={styles.Helper}>
																{showError(errors.currency[0]?.rule)(
																	errors.currency[0]
																)}
															</span>
														)}
													</div>
												</div>

												<div className={styles.ReportsModalAccId}>
													<FormInput
														type="muiInput"
														name={'account_id'}
														onChange={changeField}
														errors={errors}
														data={fileLoadingData}
														label={
															<FormattedHTMLMessage
																id={'rod.field.admin.reports.acc_id'}
															/>
														}
													/>
													{Object.keys(errors).includes('account_id') && (
														<span className={styles.Helper}>
															{
																<FormattedMessage
																	id={`rod.error.${errors.account_id[0].rule}`}
																/>
															}
														</span>
													)}
												</div>

												<div className={styles.DropZone__wrapper}>
													<DropZone
														multiple={false}
														accept={'.xls, .xlsx'}
														imgType={1}
														classStyles={styles.DropZone}
														dropzoneId={'rod.admin.reports.edit_report_dropbox'}
														onUpload={handleXlsUpload}
														onRemove={handleFileRemove}
														kb
														value={
															xlsAttachment.file
																? {
																		client_name: xlsAttachment.fileData.name,

																		size: xlsAttachment.fileData.size,
																		url: xlsAttachment.fileData.path,
																  }
																: null
														}
														maxSize={99991024 * 99991024 * 99100}
													/>
												</div>
											</div>
										)}
										{Object.keys(backErrors).length > 0 && (
											<span className={styles.errorHelper}>
												{getErrorText(backErrors)}
											</span>
										)}
										{!isShowConfirmQuestion ? (
											<>
												<div className={styles.reports__confirm}>
													{isShowNotice && (
														<div className={styles.notify__confirm_question}>
															<FormattedHTMLMessage
																id={'rod.admin.reports.confirm_one_article'}
															/>
														</div>
													)}
													{reportsModalRadio === 'archive' && (
														<Button
															text={
																<FormattedHTMLMessage
																	id={'rod.action.upload'}
																/>
															}
															className={
																((reportLang === 'en' && enConfirmed) ||
																	(reportLang === 'ru' && ruConfirmed)) &&
																!Object.keys(errors).length
																	? styles.activeReportsBtn
																	: styles.inactiveReportsBtn
															}
															variant={'primary'}
															disabled={
																!(
																	(reportLang === 'en' && enConfirmed) ||
																	(reportLang === 'ru' && ruConfirmed)
																) || Object.keys(errors).length
															}
															onClick={preConfirmData}
														/>
													)}
													{reportsModalRadio === 'file' && (
														<Button
															text={
																<FormattedHTMLMessage
																	id={'rod.action.upload'}
																/>
															}
															className={
																fileTabReady && !Object.keys(errors).length
																	? styles.activeReportsBtn
																	: styles.inactiveReportsBtn
															}
															variant={'primary'}
															disabled={
																!fileTabReady || Object.keys(errors).length
															}
															onClick={preConfirmData}
														/>
													)}
												</div>
											</>
										) : (
											<div className={styles.notify__confirm_question_wrapper}>
												<div className={styles.notify__confirm_question}>
													<FormattedHTMLMessage
														id={
															(enConfirmed && ruConfirmed) ||
															reportsModalRadio === 'file'
																? 'rod.modal.notify.admin.confirm-question'
																: 'rod.faq.confirm_one_article'
														}
													/>
												</div>
												<div className={styles.notify__confirm}>
													<Button
														text={
															<FormattedHTMLMessage
																id={'rod.modal.notify.admin.confirm-yes'}
															/>
														}
														className={styles.activeBtnConfirmQuestion}
														variant={
															isConfirmedBtnActive ? 'primary' : 'disabled'
														}
														onClick={() => {
															if (isConfirmedBtnActive) {
																setIsConfirmedBtnActive(false);
																debouncedSendData();
															}
														}}
													/>
													<Button
														text={
															<FormattedHTMLMessage
																id={'rod.modal.notify.admin.confirm-no'}
															/>
														}
														className={styles.activeBtnConfirmQuestion}
														variant={
															isConfirmedBtnActive ? 'primary' : 'disabled'
														}
														onClick={() => {
															if (isConfirmedBtnActive) {
																setIsShowConfirmQuestion(false);
															}
														}}
													/>
												</div>
											</div>
										)}
									</div>
								</Col>
							</Row>
						</Container>
					</Modal>,
				]}
			</PoseGroup>
		</BaseModal>
	);
};

export default compose(withLang, withUI, withRoot)(ReportsManualModal);
