// Core
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

// Utils
import { formatTime } from '../utils/formatTime';
import { reachGoal } from 'utils';

// Context
import { AuthContext } from 'contexts/AuthContext';

// UI
import { Button } from 'components/Buttons';
import { Input } from 'components/Inputs';
import { Label } from 'components/Labels';
import { Form } from 'components/Form';

// Styles
import styles from './EmailCheckForm.module.css';
import { EVENTS } from 'utils/reachParams';

const EmailCheckForm = ({ onSubmit }) => {
	const {
		user,
		resendEmail,
		registrationUpdate,
		countEqualLimit,
		errors,
		regUpdateErrors,
		clearAllErrors,
		clearRegUpdateErrors,
	} = useContext(AuthContext);
	const emailRef = useRef(null);
	const codeRef = useRef(null);
	const MINUTE = 60;

	const [email, setEmail] = useState(user.login ?? '');
	const [emailDisabled, setEmailDisabled] = useState(true);
	const [emailSecondsLeft, setEmailSecondsLeft] = useState(MINUTE);
	const [codeSecondsLeft, setCodeSecondsLeft] = useState(MINUTE);
	const [showChangeEmailButton, setShowChangeEmailButton] = useState(false);
	const [showResendButton, setShowResendButton] = useState(false);

	useEffect(() => {
		clearRegUpdateErrors();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (emailSecondsLeft > 0) {
			const timer = setInterval(() => {
				setEmailSecondsLeft((prevSecondsLeft) => prevSecondsLeft - 1);
			}, 1000);
			return () => clearInterval(timer);
		} else {
			if (
				Object.keys(regUpdateErrors) &&
				regUpdateErrors?.email &&
				regUpdateErrors?.email[0]?.rule === 'forbidden'
			) {
				setShowChangeEmailButton(false);
			} else {
				setShowChangeEmailButton(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [emailSecondsLeft, regUpdateErrors]);

	useEffect(() => {
		if (codeSecondsLeft > 0) {
			const timer = setInterval(() => {
				setCodeSecondsLeft((prevSecondsLeft) => prevSecondsLeft - 1);
			}, 1000);

			return () => clearInterval(timer);
		} else {
			if (
				Object.keys(errors) &&
				errors?.code &&
				errors?.code[0]?.rule === 'forbidden'
			) {
				setShowResendButton(false);
			} else {
				setShowResendButton(true);
			}
		}
	}, [codeSecondsLeft, errors]);

	useEffect(() => {
		if (
			Object.keys(regUpdateErrors) &&
			regUpdateErrors?.email &&
			(regUpdateErrors?.email[0]?.rule === 'email_format' ||
				regUpdateErrors?.email[0]?.rule === 'invalid' ||
				regUpdateErrors?.email === 'exists_in_signup')
		) {
			setEmailSecondsLeft(0);
			setShowChangeEmailButton(false);
			setEmailDisabled(false);
		}
	}, [regUpdateErrors]);

	useEffect(() => {
		if (
			Object.keys(errors) &&
			errors?.code &&
			errors?.code[0]?.rule === 'forbidden'
		) {
			setCodeSecondsLeft(0);
			setShowResendButton(false);
		}
	}, [errors]);

	const showError = (error) => {
		if (error.rule) {
			switch (error.rule) {
				case 'invalid':
					if (error.hasOwnProperty('remaining')) {
						if (error.remaining === 0) {
							return <FormattedHTMLMessage id={'rod.error.code_exhausted'} />;
						} else {
							return (
								<FormattedHTMLMessage
									id={'rod.error.code.invalid'}
									values={{ count: error.remaining }}
								/>
							);
						}
					} else {
						return <FormattedMessage id={'rod.error.email_format'} />;
					}

				case 'forbidden':
					return <FormattedHTMLMessage id={'rod.error.code_exhausted'} />;

				default:
					return <FormattedHTMLMessage id={`rod.error.${error.rule}`} />;
			}
		} else if (error.email) {
			return <FormattedHTMLMessage id={`rod.error.${error.email}`} />;
		} else {
			return <FormattedHTMLMessage id={`rod.error.${error}`} />;
		}
	};

	const handleChangeEmail = (event) => {
		event.preventDefault();
		setEmailDisabled(false);
		clearAllErrors();
	};

	const onChangeEmail = () => {
		setEmail(emailRef.current.value);
		clearRegUpdateErrors();
	};

	const onChange = () => {
		clearAllErrors();
	};

	const handleSendCode = () => {
		setEmailDisabled(true);
		setEmailSecondsLeft(MINUTE);
		setCodeSecondsLeft(MINUTE);
		setShowChangeEmailButton(false);
		setShowResendButton(false);
		try {
			registrationUpdate(email);
		} catch (error) {
			console.error(error);
		}
	};

	const handleResend = () => {
		clearAllErrors();
		setCodeSecondsLeft(MINUTE);
		setShowResendButton(false);
		try {
			if (email) {
				resendEmail(email);
			}
		} catch (error) {
			console.error('Error resend code', error);
		}
	};

	const handleConfirm = () => {
		reachGoal(EVENTS.submitRequestEmail);
		if (onSubmit) {
			onSubmit({
				code: codeRef.current.value,
			});
		}
	};

	const handleBlur = () => {
		if (user.login === email) {
			setEmailDisabled(true);
			setShowChangeEmailButton(true);
		}
	};

	return (
		<Form className={styles.Form}>
			<div className={styles.Title__wrapper}>
				<Label className={styles.Title__header}>
					<FormattedMessage id={'rod.verify_email.header'} />
				</Label>
				<Label className={styles.Title__subheader}>
					<FormattedHTMLMessage id={'rod.verify_email.subheader'} />
				</Label>
			</div>

			<div className={styles.Fields}>
				<FormattedHTMLMessage id={'rod.field.email.helper'}>
					{(helper) => {
						return (
							<div className={styles.codeInput}>
								<Input
									forwardRef={emailRef}
									className={
										emailDisabled || !showChangeEmailButton
											? `${styles.Input} ${styles.disabled}`
											: `${styles.Input}`
									}
									placeholder={user.login}
									clearInputIfHasError={true}
									errors={regUpdateErrors.email}
									value={email}
									onChange={(s) => {
										onChangeEmail(s);
									}}
									onBlur={handleBlur}
									name={'email'}
									showError={showError}
									helper={
										!showChangeEmailButton && !countEqualLimit
											? `${helper} ${
													emailSecondsLeft > 0
														? formatTime(emailSecondsLeft)
														: '0:00'
											  }`
											: undefined
									}
									disabled={emailDisabled || !showChangeEmailButton}
								/>
								{showChangeEmailButton && emailDisabled && !countEqualLimit && (
									<button
										className={styles.resendBtn}
										style={
											Object.keys(regUpdateErrors).length > 0 &&
											regUpdateErrors?.email &&
											showResendButton
												? { bottom: '5px' }
												: {}
										}
										onClick={(e) => handleChangeEmail(e)}
									>
										<FormattedHTMLMessage id={'rod.field.email.change'} />
									</button>
								)}
							</div>
						);
					}}
				</FormattedHTMLMessage>
				<FormattedHTMLMessage id={'rod.field.sms.helper'}>
					{(helper) => {
						return (
							<div className={styles.codeInput}>
								<FormattedMessage id={'rod.field.verify_email_code'}>
									{(placeholder) => (
										<Input
											forwardRef={codeRef}
											className={
												!emailDisabled
													? `${styles.Input} ${styles.disabled}`
													: `${styles.Input}`
											}
											placeholder={placeholder}
											clearInputIfHasError={true}
											errors={errors.code}
											onChange={(s) => {
												onChange(s);
											}}
											name={'code'}
											showError={showError}
											helper={
												!showResendButton
													? `${helper} ${
															codeSecondsLeft > 0
																? formatTime(codeSecondsLeft)
																: '0:00'
													  }`
													: undefined
											}
											disabled={
												!emailDisabled ||
												(Object.keys(errors) &&
													errors?.code &&
													(errors.code[0]?.rule === 'code_exhausted' ||
														errors.code[0]?.rule === 'forbidden' ||
														(errors.code[0]?.rule === 'invalid' &&
															errors.code[0]?.remaining === 0)))
											}
											forbidEnter
										/>
									)}
								</FormattedMessage>
								{showResendButton && emailDisabled && (
									<button
										className={styles.resendBtn}
										style={
											Object.keys(errors).length > 0 &&
											errors.code &&
											showResendButton
												? { bottom: '5px' }
												: {}
										}
										onClick={handleResend}
									>
										<FormattedHTMLMessage id={'rod.verify_email.send_again'} />
									</button>
								)}
							</div>
						);
					}}
				</FormattedHTMLMessage>
			</div>
			<div className={styles.Controls}>
				<Button
					className={styles.Button_send}
					text={
						<FormattedMessage
							id={
								!emailDisabled ? 'rod.verify.send_code' : 'rod.action.confirm'
							}
						/>
					}
					onClick={!emailDisabled ? handleSendCode : handleConfirm}
					disabled={
						(emailDisabled && !codeRef?.current?.value) ||
						(!emailDisabled && !emailRef?.current?.value) ||
						(emailDisabled &&
							Object.keys(errors) &&
							errors?.code &&
							errors?.code[0]?.rule === 'code_exhausted')
					}
					primary
				/>
				{Object.keys(errors) &&
					errors?.code &&
					(errors?.code[0]?.rule === 'code_exhausted' ||
						errors?.code[0]?.remaining === 0 ||
						(errors?.code[0]?.rule === 'forbidden' &&
							errors?.code[0]?.count === 3)) && (
						<Button
							className={styles.noCode}
							to={'/registration/email/nocode'}
							text={<FormattedMessage id={'rod.verify_sms.no_code'} />}
							link
						/>
					)}
			</div>
		</Form>
	);
};

EmailCheckForm.propTypes = {
	onSubmit: PropTypes.func,
	clearAllErrors: PropTypes.func,
	errors: PropTypes.object,
};

export default EmailCheckForm;
