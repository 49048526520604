// Core
import { Button } from 'components/Buttons/Button';
import { Label } from 'components/Labels';
import { Success as SuccessForm } from 'forms/common';
import { withUI } from 'hocs';
import React, { Component } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

// Styles
import styles from './ForgotPasswordSend.module.css';

class ForgotPasswordSend extends Component {
	handleClick = () => {
		this.props.history.push('/');
	};

	render() {
		return (
			<SuccessForm>
				<div className={styles.Header} >
					<FormattedHTMLMessage id={'rod.restore_password.email.header'} />
				</div>
				<Label black className={styles.Label} font="--gilroy-Medium-24">
					<FormattedHTMLMessage id={'rod.restore_password.email.subheader'} />
				</Label>
				<Button
					className={styles.Button}
					onClick={this.handleClick}
					text={<FormattedMessage id={'rod.action.to_index_page'} />}
					variant={'primary'}
				/>
			</SuccessForm>
		);
	}
}

export default compose(withUI, withRouter)(ForgotPasswordSend);
