// Core
import React, { PureComponent } from 'react';
import { Container } from 'react-grid-system';
import DOMPurify from 'dompurify';
import update from 'immutability-helper';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';

// Utils
import { withAuth, withLang, withRoot } from 'hocs';
import { getHelpMessage } from '../../../../../messages/helper';
import { generateDocumentsSteps } from '../utils/generateDocumentsSteps';
import { reachGoal } from 'utils';

// UI
import { Loading } from 'components';
import { Button } from 'components/Buttons/Button';
import { Wizard } from 'components/Wizard';
import Personal from './Personal/Personal';
import Sole from './Sole/Sole';
import Company from './Company/Company';

// Types
import accountTypes from 'constants/accountTypes';
import documentTypes from 'constants/documentTypes';
import { EVENTS } from 'utils/reachParams';

// Styles
import styles from './Documents.module.css';


const personalDocumentTypes = {
	PASSPORT: 1,
	DRIVER_LICENSE: 2,
};

class Documents extends PureComponent {
	static propTypes = {};

	constructor(props) {
		super(props);

		this.state = {
			personalDocumentSelected: personalDocumentTypes.PASSPORT,
			loading: true,
			files: [],
		};
		this.steps = generateDocumentsSteps(props.personalData.business_type);
	}

	componentDidMount() {
		this.update();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.id !== this.props.id) {
			this.update();
		}

		if (
			prevProps.personalData.business_type !==
			this.props.personalData.business_type
		) {
			this.steps = generateDocumentsSteps(
				this.props.personalData.business_type
			);
		}
	}

	update() {
		const {
			authContext: { changeWidget, setIsAccountLoading },
			rootContext: { getAccountDocuments },
		} = this.props;

		setIsAccountLoading(true);
		changeWidget();
		getAccountDocuments(this.props.id)
			.then((res) => {
				res = res.data.data;
				const { files } = res;
				const driver =
					files.hasOwnProperty(documentTypes.DRIVER_LICENSE_FRONT_PAGE) ||
					files.hasOwnProperty(documentTypes.DRIVER_LICENSE_BACK_PAGE);
				this.setState(
					update(this.state, {
						files: { $set: files },
						loading: { $set: false },
						personalDocumentSelected: {
							$set: driver
								? personalDocumentTypes.DRIVER_LICENSE
								: personalDocumentTypes.PASSPORT,
						},
					})
				);
				setIsAccountLoading(false);
			})
			.catch((error) => {
				console.error('Documents - getAccountDocuments error: ', error);
				console.error(
					'Documents - getAccountDocuments error.response: ',
					error.response
				);
				setIsAccountLoading(false);
			});
	}

	nextHandler = () => {
		const {
			rootContext: { saveDocsFiles },
		} = this.props;

		reachGoal(EVENTS.downloadDocuments);
		if (typeof this.props.onChange === 'function') {
			saveDocsFiles(this.state.files);
			this.props.onChange({ ...this.state.files });
		}
	};

	handleFileUpload = (type) => (files) => {
		return new Promise((resolve, reject) => {
			const fd = new FormData();
			fd.append('type', type);
			fd.append('file', files[0]);
			const {
				rootContext: { uploadAccountDocument },
			} = this.props;

			uploadAccountDocument(this.props.id, fd)
				.then((res) => {
					res = res.data.data;
					this.setState(
						update(this.state, {
							files: { $auto: { [res.type]: { $set: res } } },
						}),
						() => {
							resolve(res);
						}
					);
				})
				.catch((error) => {
					console.error('Documents - uploadAccountDocument error: ', error);
					console.error(
						'Documents - uploadAccountDocument error.response: ',
						error.response
					);
					reject(error.response);
				});
		});
	};

	handleFileRemove = (file) => () => {
		return new Promise((resolve, reject) => {
			const {
				rootContext: { deleteAccountDocument },
			} = this.props;
			deleteAccountDocument(this.props.id, file.id)
				.then((res) => {
					res = res.data.data;
					this.setState(
						update(this.state, {
							files: {
								$unset: [file.type],
							},
						}),
						() => {
							resolve(res);
						}
					);
				})
				.catch((error) => {
					console.error('Documents - deleteAccountDocument error: ', error);

					console.error(
						'Documents - deleteAccountDocument error.response: ',
						error.response
					);
					reject(error.response);
				});
		});
	};

	handleDocumentTypeChange = (e) => {
		this.setState({ personalDocumentSelected: e.currentTarget.value });
	};

	render() {
		const {
			personalData: { business_type },
			handleOpenPrevTabs,
		} = this.props;

		if (this.state.loading) {
			return <Loading non_margin={true} />;
		}

		return (
			<div className={styles.Page}>
				<Wizard
					steps={this.steps}
					className={styles.Wizard}
					handleOpenPrevTabs={handleOpenPrevTabs}
				/>
				<div className={styles.Container}>
					<Container
						fluid
						style={{
							height: '100%',
							padding: 0,
						}}
					>
						{business_type === accountTypes.PERSONAL &&
							this.renderPersonalFields()}
						{business_type === accountTypes.PERSONAL_ENTREPRENEUR &&
							this.renderPEFields()}
						{business_type === accountTypes.COMPANY &&
							this.renderCompanyFields()}
					</Container>
				</div>
			</div>
		);
	}

	renderPersonalFields() {
		const { personalDocumentSelected, files } = this.state;
		const {
			personalData: { moderation_status, completion_step },
			UIContext: { showModal },
		} = this.props;

		let moderationStatus = {};
		if (completion_step === 'declined_docs') {
			moderationStatus = moderation_status;
		} else {
			moderationStatus = {
				passport_registration_page: { quality: true, valid: true },
				passport_photo_page: { quality: true, valid: true },
			};
		}

		const lang = localStorage.getItem('lang');
		const message = DOMPurify.sanitize(
			...getHelpMessage(lang, 'documents_passport')
		);

		return (
			<Personal
				personalDocumentSelected={personalDocumentSelected}
				personalDocumentTypes={personalDocumentTypes}
				showModal={showModal}
				message={message}
				files={files}
				moderationStatus={moderationStatus}
				handleFileUpload={this.handleFileUpload}
				handleFileRemove={this.handleFileRemove}
			>
				{this.renderControls()}
			</Personal>
		);
	}

	renderPEFields() {
		const { files } = this.state;
		const {
			personalData: { moderation_status, completion_step },
			UIContext: { showModal },
		} = this.props;

		let moderationStatus = {};
		if (completion_step === 'declined_docs') {
			moderationStatus = moderation_status;
		} else {
			moderationStatus = {
				passport_registration_page: { quality: true, valid: true },
				passport_photo_page: { quality: true, valid: true },
				sole_entrepreneur_registration_certificate: {
					quality: true,
					valid: true,
				},
			};
		}

		const lang = localStorage.getItem('lang');
		const documentsPassportMessage = DOMPurify.sanitize(
			...getHelpMessage(lang, 'documents_passport')
		);
		const documentsPeMessage = DOMPurify.sanitize(
			...getHelpMessage(lang, 'documents_PE')
		);

		return (
			<Sole
				documentsPassportMessage={documentsPassportMessage}
				documentsPeMessage={documentsPeMessage}
				showModal={showModal}
				files={files}
				moderationStatus={moderationStatus}
				handleFileUpload={this.handleFileUpload}
				handleFileRemove={this.handleFileRemove}
			>
				{this.renderControls()}
			</Sole>
		);
	}

	renderCompanyFields() {
		const { files } = this.state;
		const {
			personalData: { moderation_status, completion_step },
			UIContext: { showModal },
		} = this.props;

		let moderationStatus = {};
		if (completion_step === 'declined_docs') {
			moderationStatus = moderation_status;
		} else {
			moderationStatus = {
				company_registration_certificate: { quality: true, valid: true },
				company_tax_residency_certificate: { quality: true, valid: true },
			};
		}

		const lang = localStorage.getItem('lang');
		const message = DOMPurify.sanitize(
			...getHelpMessage(lang, 'documents_company')
		);

		return (
			<Company
				showModal={showModal}
				files={files}
				message={message}
				moderationStatus={moderationStatus}
				handleFileUpload={this.handleFileUpload}
				handleFileRemove={this.handleFileRemove}
			>
				{this.renderControls()}
			</Company>
		);
	}

	isValid() {
		const { files, personalDocumentSelected } = this.state;

		const {
			personalData: { business_type },
		} = this.props;
		if (Object.keys(files).length === 0) {
			return false;
		} else if (business_type === accountTypes.PERSONAL) {
			if (personalDocumentSelected === personalDocumentTypes.PASSPORT) {
				return (
					files.hasOwnProperty(documentTypes.PASSPORT_REGISTRATION_PAGE) &&
					files.hasOwnProperty(documentTypes.PASSPORT_PHOTO_PAGE)
				);
			} else {
				return (
					files.hasOwnProperty(documentTypes.DRIVER_LICENSE_FRONT_PAGE) &&
					files.hasOwnProperty(documentTypes.DRIVER_LICENSE_BACK_PAGE)
				);
			}
		} else if (business_type === accountTypes.PERSONAL_ENTREPRENEUR) {
			return (
				files.hasOwnProperty(documentTypes.PASSPORT_PHOTO_PAGE) &&
				files.hasOwnProperty(documentTypes.PASSPORT_REGISTRATION_PAGE) &&
				files.hasOwnProperty(
					documentTypes.SOLE_ENTREPRENEUR_REGISTRATION_CERTIFICATE
				)
			);
		} else if (business_type === accountTypes.COMPANY) {
			return (
				files.hasOwnProperty(documentTypes.COMPANY_REGISTRATION_CERTIFICATE) &&
				files.hasOwnProperty(documentTypes.COMPANY_TAX_RESIDENCY_CERTIFICATE)
			);
		}
		return false;
	}

	renderControls() {
		return (
			<div className={styles.Controls}>
				<Button
					disabled={!this.isValid()}
					text={<FormattedMessage id={'rod.action.next'} />}
					variant={'primary'}
					onClick={this.nextHandler}
				/>
			</div>
		);
	}
}

export default compose(withRoot, withAuth, withLang)(Documents);
