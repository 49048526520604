// Core
import { FormattedMessage } from 'react-intl';

export const stepsArray = [
	'release',
	'file',
	'tracks',
	'authors',
	'lyrics',
	'cover',
	'distribution',
	'confirm',
	'moderation',
];

export const initialSteps = [
	{
		id: 0,
		status: 'active',
		title: <FormattedMessage id={'rod.release.create.step.release'} />,
	},
	{
		id: 1,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.files'} />,
	},
	{
		id: 2,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.tracks'} />,
	},
	{
		id: 3,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.authors'} />,
	},
	{
		id: 4,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.lyrics'} />,
	},
	{
		id: 5,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.cover'} />,
	},
	{
		id: 6,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.distribution'} />,
	},
	{
		id: 7,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.confirm'} />,
	},
];

export const releaseStepComplete = [
	{
		id: 0,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.release'} />,
	},
	{
		id: 1,
		status: 'active',
		title: <FormattedMessage id={'rod.release.create.step.files'} />,
	},
	{
		id: 2,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.tracks'} />,
	},
	{
		id: 3,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.authors'} />,
	},
	{
		id: 4,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.lyrics'} />,
	},
	{
		id: 5,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.cover'} />,
	},
	{
		id: 6,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.distribution'} />,
	},
	{
		id: 7,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.confirm'} />,
	},
];

export const filesStepComplete = [
	{
		id: 0,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.release'} />,
	},
	{
		id: 1,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.files'} />,
	},
	{
		id: 2,
		status: 'active',
		title: <FormattedMessage id={'rod.release.create.step.tracks'} />,
	},
	{
		id: 3,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.authors'} />,
	},
	{
		id: 4,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.lyrics'} />,
	},
	{
		id: 5,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.cover'} />,
	},
	{
		id: 6,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.distribution'} />,
	},
	{
		id: 7,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.confirm'} />,
	},
];

export const tracksStepComplete = [
	{
		id: 0,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.release'} />,
	},
	{
		id: 1,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.files'} />,
	},
	{
		id: 2,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.tracks'} />,
	},
	{
		id: 3,
		status: 'active',
		title: <FormattedMessage id={'rod.release.create.step.authors'} />,
	},
	{
		id: 4,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.lyrics'} />,
	},
	{
		id: 5,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.cover'} />,
	},
	{
		id: 6,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.distribution'} />,
	},
	{
		id: 7,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.confirm'} />,
	},
];

export const authorsStepComplete = [
	{
		id: 0,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.release'} />,
	},
	{
		id: 1,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.files'} />,
	},
	{
		id: 2,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.tracks'} />,
	},
	{
		id: 3,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.authors'} />,
	},
	{
		id: 4,
		status: 'active',
		title: <FormattedMessage id={'rod.release.create.step.lyrics'} />,
	},
	{
		id: 5,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.cover'} />,
	},
	{
		id: 6,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.distribution'} />,
	},
	{
		id: 7,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.confirm'} />,
	},
];

export const lyricsStepComplete = [
	{
		id: 0,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.release'} />,
	},
	{
		id: 1,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.files'} />,
	},
	{
		id: 2,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.tracks'} />,
	},
	{
		id: 3,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.authors'} />,
	},
	{
		id: 4,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.lyrics'} />,
	},
	{
		id: 5,
		status: 'active',
		title: <FormattedMessage id={'rod.release.create.step.cover'} />,
	},
	{
		id: 6,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.distribution'} />,
	},
	{
		id: 7,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.confirm'} />,
	},
];

export const coverStepComplete = [
	{
		id: 0,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.release'} />,
	},
	{
		id: 1,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.files'} />,
	},
	{
		id: 2,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.tracks'} />,
	},
	{
		id: 3,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.authors'} />,
	},
	{
		id: 4,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.lyrics'} />,
	},
	{
		id: 5,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.cover'} />,
	},
	{
		id: 6,
		status: 'active',
		title: <FormattedMessage id={'rod.release.create.step.distribution'} />,
	},
	{
		id: 7,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.confirm'} />,
	},
];

export const distributionStepComplete = [
	{
		id: 0,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.release'} />,
	},
	{
		id: 1,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.files'} />,
	},
	{
		id: 2,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.tracks'} />,
	},
	{
		id: 3,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.authors'} />,
	},
	{
		id: 4,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.lyrics'} />,
	},
	{
		id: 5,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.cover'} />,
	},
	{
		id: 6,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.distribution'} />,
	},
	{
		id: 7,
		status: 'active',
		title: <FormattedMessage id={'rod.release.create.step.confirm'} />,
	},
];

export const emptyTrackListSteps = [
	{
		id: 0,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.release'} />,
	},
	{
		id: 1,
		status: 'active',
		title: <FormattedMessage id={'rod.release.create.step.files'} />,
	},
	{
		id: 2,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.tracks'} />,
	},
	{
		id: 3,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.authors'} />,
	},
	{
		id: 4,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.lyrics'} />,
	},
	{
		id: 5,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.cover'} />,
	},
	{
		id: 6,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.distribution'} />,
	},
	{
		id: 7,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.confirm'} />,
	},
];
