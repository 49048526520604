// Core
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import lscache from 'lscache';

// Services
import { accounts } from 'services';
import { setCache } from 'services/cacheHelper/setCache';

// Context
import { LangContext } from 'contexts/LangContext';

// Utils
import { getGenres } from 'utils';

// UI
import ConfirmDistributions from '../ConfirmDistributions/ConfirmDistributions';
import { DropZone } from 'components/Upload/DropZone';
import RecordingsWrapper from './RecordingsWrapper/RecordingsWrapper';

// Styles
import s from '../Confirm.module.css';

const Body = ({
	personal,
	outlets,
	country,
	cover,
	loading,
	handleOnLoad,
	handleFileUpload,
	emptyTrackList,
	recommendedOutlets,
}) => {
	const { lang } = useContext(LangContext);
	const [genresArr, setGenresArr] = useState([]);

	const getPerformers = () => {
		if (Array.isArray(personal.performers)) {
			const formatArray = personal.performers.map((item) =>
				typeof item === 'string' ? item : item.name
			);
			return formatArray.join(', ');
		}
		return personal.performers;
	};

	useEffect(() => {
		let genresCache = lscache.get('genresCache');

		if (genresCache) {
			if (lang === 'ru') {
				genresCache = genresCache.sort((a, b) =>
					a.title_ru > b.title_ru ? 1 : -1
				);
			} else {
				genresCache = genresCache.sort((a, b) =>
					a.title_en > b.title_en ? 1 : -1
				);
			}
			setGenresArr(genresCache);
		} else {
			accounts
				.getGenres()
				.then((res) => {
					res = res.data.data;
					setCache('genres', 'genresCache', res);
					if (lang === 'ru') {
						res = res.sort((a, b) => (a.title_ru > b.title_ru ? 1 : -1));
					} else {
						res = res.sort((a, b) => (a.title_en > b.title_en ? 1 : -1));
					}
					setGenresArr(res);
				})
				.catch((error) => {
					console.error('Error', error);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={s.body}>
			<span className={s.title}>
				<FormattedMessage id={'rod.release.create.step.submit.body_title'} />
			</span>
			<div className={s.section__information}>
				<div className={s.info__table}>
					<div className={s.table}>
						<div className={s.item}>
							<span className={s.item__title}>
								<FormattedMessage
									id={'rod.release.create.step.submit.artist'}
								/>
							</span>
							<span className={s.item__text}>{getPerformers()}</span>
						</div>
						<div className={s.item}>
							<span className={s.item__title}>
								<FormattedMessage
									id={'rod.release.create.step.submit.subtitle'}
								/>
							</span>
							<span className={s.item__text}>{personal?.subtitle || '-'}</span>
						</div>
						<div className={s.item}>
							<span className={s.item__title}>
								<FormattedMessage id={'rod.release.create.step.submit.label'} />
							</span>
							<span className={s.item__text}>
								{personal.p_line ? personal.p_line : '-'}
							</span>
						</div>
						<div className={s.item}>
							<span className={s.item__title}>
								<FormattedMessage
									id={'rod.release.create.step.submit.catalog_number'}
								/>
							</span>
							<span className={s.item__text}>
								{personal.catalog_number ||
									(personal.generate_catalog_number && (
										<FormattedMessage
											id={'rod.release.create.step.submit.assign_automatically'}
										/>
									)) ||
									'-'}
							</span>
						</div>
						<div className={s.item}>
							<span className={s.item__title}>GRID</span>
							<span className={s.item__text}>
								{personal.grid?.replace(
									/(\w{2})(\w{5})(\w{10})(\w{1})/,
									'$1-$2-$3-$4'
								) ||
									(personal.generate_grid && (
										<FormattedMessage
											id={'rod.release.create.step.submit.assign_automatically'}
										/>
									)) ||
									'-'}
							</span>
						</div>
						<div className={s.item}>
							<span className={s.item__title}>EAN/UPC</span>
							<span className={s.item__text}>
								{personal.generate_ean ? (
									<FormattedMessage
										id={'rod.release.create.step.submit.assign_automatically'}
									/>
								) : (
									personal.ean
								)}
							</span>
						</div>
						<div className={s.item}>
							<span className={s.item__title}>
								<FormattedMessage
									id={'rod.release.create.step.submit.genres'}
								/>
							</span>
							<span className={`${s.item__text} ${s.item_genres}`}>
								{personal.genres && getGenres(lang, genresArr, personal.genres)}
							</span>
						</div>
						<div className={s.item}>
							<span className={s.item__title}>
								<FormattedMessage
									id={'rod.release.create.step.submit.create_date'}
								/>
							</span>
							<span className={s.item__text}>{personal.created_date}</span>
						</div>
						<div className={s.item}>
							<span className={s.item__title}>
								<FormattedMessage
									id={'rod.release.create.step.submit.country_name.review'}
								/>
							</span>
							<span className={s.item__text}>
								{lang === 'ru' ? country?.title_ru : country?.title_en}
							</span>
						</div>
					</div>
				</div>
				<div className={s.dropZone__wrapper}>
					{cover || loading ? (
						<div
							className={s.Preview}
							style={loading ? { border: '1px solid var(--color-black)' } : {}}
						>
							<img
								className={loading ? s.loader : null}
								src={personal?.cover?.url || cover}
								onLoad={handleOnLoad}
								alt=""
							/>
							{loading && <div className={s.preLoader} />}
						</div>
					) : (
						<>
							<DropZone
								multiple={false}
								accept=".jpg, .jpeg, .png, .tiff"
								imgType={2}
								classStyles={s.DropZone}
								dropzoneId={'rod.release.create.step.cover.upload'}
								onUpload={handleFileUpload}
								kb
								maxSize={1024 * 1024 * 100}
							/>
							<p className={s.error}>
								<FormattedMessage
									id={'rod.release_create.error.cover_required'}
								/>
							</p>
						</>
					)}
				</div>
			</div>
			{!loading && (
				<div className={s.info}>
					{personal.distributions && (
						<ConfirmDistributions
							personal={personal}
							outlets={outlets}
							recommendedOutlets={recommendedOutlets}
						/>
					)}

					<RecordingsWrapper
						personal={personal}
						emptyTrackList={emptyTrackList}
					/>
				</div>
			)}
		</div>
	);
};

export default Body;
