// Core
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

// UI
import { TopTracksSkeleton } from 'components';
import TopTracksTable from '../TopTracksTable/TopTracksTable';
import { Pagination } from 'material-design/components';

// Styles
import styles from '../StatisticByTerritoriesSectionv2.module.css';

const TopTracksCombined = ({
	pageLoading,
	topTracks,
	handleCheckedTopTrack,
	page,
	setPage,
	setIsPaginationPressed,
	setPageLoading,
	chartMode,
	statisticData,
	isAdmin,
	accountOutlets,
	accountId,
	total,
}) => {
	return (
		<>
			<div className={styles.topTracksTitle}>
				<span>
					<FormattedHTMLMessage
						id={`${
							chartMode === 'phono'
								? 'rod.statistic.top_tracks'
								: 'rod.statistic.top_compositions'
						}`}
					/>
				</span>
			</div>
			{pageLoading ? (
				<TopTracksSkeleton />
			) : (
				<>
					{topTracks && topTracks.length > 0 && (
						<>
							<TopTracksTable
								topTracks={topTracks}
								handleCheckedTopTrack={handleCheckedTopTrack}
								page={page}
								chartMode={chartMode}
								statisticData={statisticData}
								isAdmin={isAdmin}
								outlets={accountOutlets}
								accountId={accountId}
							/>
							{total > 10 && (
								<Pagination
									page={page}
									total={total}
									paginate={(pageNumber) => {
										setPage(pageNumber);
										setIsPaginationPressed(true);
										setPageLoading(true);
									}}
									items_on_page={10}
								/>
							)}
						</>
					)}
				</>
			)}
		</>
	);
};

export default TopTracksCombined;
