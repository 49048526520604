// Core
import React, { Component } from 'react';
import posed, { PoseGroup } from 'react-pose';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import RichTextEditor from 'react-rte';

// Utils
import debounce from 'lodash.debounce';
import { showError } from 'validators/showError';
import union from 'lodash/union';

// UI
import { CloseButton } from 'components/Buttons';
import { Button } from 'components/Buttons';
import { Lang } from 'components';
import { Col, Container, Row } from 'components/Grid';
import { FormInput } from 'components/Form/FormInput';
import { compose } from 'recompose';
import { withLang, withRoot, withUI } from 'hocs';
import Admin from 'services/api/admin';
import BaseModal from './BaseModal';
import { DropZone } from 'components/Upload/DropZone';
import UploadedFile from 'pages/repertoire/Dashboard/UploadedFile/UploadedFile';

// Styles
import styles from './Modal.module.css';

const Modal = posed.div({
	enter: {
		x: 0,
		opacity: 1,
		transition: {
			x: {
				ease: 'easeIn',
			},
			default: { duration: 300 },
		},
	},
	exit: {
		x: '100%',
		opacity: 0,
		transition: { duration: 250 },
	},
});

const Shade = posed.div({
	enter: { opacity: 0.5 },
	exit: { opacity: 0 },
});

class NotifyScreenModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: RichTextEditor.createEmptyValue(),
			isTextAreaReady: false,
			confirmed: false,
			isConfirmedBtnActive: true,
			sendAll: false,
			isVisible: false,
			isShowConfirmQuestion: false,
			errors: { recipients: {}, title: '' },
			fileErrors: {},
			backErrors: {},
			title: '',
			bodyText: '',
			data: {
				selectedData: { recipients: [] },
				recipients: [
					{
						id: 1,
						title_ru: '1',
						title_en: '1',
					},
					{
						id: 2,
						title_ru: '2',
						title_en: '2',
					},
					{ id: 3, title_ru: '3', title_en: '3' },
					{ id: 4, title_ru: '4', title_en: '4' },
				],
				attachment: { file: null, fileData: null },
			},
		};
	}

	componentDidMount() {
		const { users } = this.props;

		this.setState({
			isVisible: true,
			onCloseFn: this.props.onClose,
			data: { ...this.state.data, recipients: users },
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			this.state.title &&
			this.state.value &&
			(this.state.data.selectedData.recipients.length ||
				this.state.sendAll ||
				(this.state.data.attachment && this.state.data.attachment.file)) &&
			!this.state.confirmed
		) {
			this.setState({ confirmed: true });
		} else if (prevState.confirmed) {
			this.setState({ confirmed: false });
		}
	}

	willUnmount(e) {
		this.setState({
			isVisible: false,
		});
		setTimeout(() => {
			this.state.onCloseFn();
		}, 300);
	}

	onChange = (value) => {
		this.setState({ value });
		if (this.props.onChange) {
			this.props.onChange(value.toString('html'));
		}
	};

	changeField = (field) => (e) => {
		this.setState({ errors: {} });
		if (e && e.target) {
			this.setState({ [field]: e.target.value });
		} else {
			this.setState({ [field]: e });
		}
	};

	preConfirmData = () => {
		this.setState({ isShowConfirmQuestion: true });
	};

	handleFileUpload = (files) => {
		this.setState({ fileErrors: {}, backErrors: {} });

		const fileTypes = [
			'application/xls',
			'application/x-xls',
			'application/vnd.ms-excel',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			'application/csv',
			'text/csv',
		];
		this.setState({ fileErrors: {} });

		if (!fileTypes.includes(files[0].type)) {
			this.setState({ fileErrors: { wrong_file_type: true } });
			return;
		}

		const fd = new FormData();
		fd.append(`accounts_file`, files[0]);

		this.setState({
			data: {
				...this.state.data,
				attachment: {
					file: fd,
					fileData: files[0],
				},
			},
		});

		return Promise.resolve();
	};

	handleFileRemove = () => {
		this.setState({ fileErrors: {}, backErrors: {} });
		this.setState({
			data: {
				...this.state.data,
				attachment: {
					file: null,
					fileData: null,
				},
			},
		});
	};

	debouncedSendData = debounce(() => this.sendData(), 500);

	sendData = (e) => {
		const {
			langContext: { lang },
			UIContext: { setNotificationsSent, notificationsSent },
		} = this.props;

		const recipients = this.state.sendAll
			? this.state.data.recipients.map((recepient) => recepient.id)
			: this.state.data.selectedData.recipients.map(
					(recepient) => recepient.id
			  );

		Admin.createAdminNotifications(
			lang,
			this.state.title,
			this.state.value.toString('html'),
			this.state.sendAll ? 1 : 0,
			this.state.sendAll ? '' : recipients,
			this.state.data.attachment.file ? this.state.data.attachment.file : null
		)
			.then((res) => {
				setNotificationsSent(union(notificationsSent, recipients));
				this.willUnmount();
				this.props.isNotificationSend(true);
			})
			.catch((err) => {
				this.setState({
					errors: err.response.data.errors,
					backErrors: err.response.data.errors,
				});
			})
			.finally(() => {
				this.setState({
					isShowConfirmQuestion: false,
					isConfirmedBtnActive: true,
				});
			});
	};

	changeSelectUsersField = (e) => {
		this.setState({
			data: { ...this.state.data, selectedData: { recipients: e } },
		});
	};

	prepareWysiwyg = () => {
		if (this.state.isTextAreaReady) {
		}
	};

	render() {
		if (this.state.isTextAreaReady) {
			this.prepareWysiwyg();
		}

		const toolbarConfig = {
			// Optionally specify the groups to display (displayed in the order listed).
			display: [
				'INLINE_STYLE_BUTTONS',
				'BLOCK_TYPE_BUTTONS',
				'LINK_BUTTONS',
				// 'BLOCK_TYPE_DROPDOWN',
				'HISTORY_BUTTONS',
			],
			INLINE_STYLE_BUTTONS: [
				{ label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
				{ label: 'Italic', style: 'ITALIC' },
				{ label: 'Underline', style: 'UNDERLINE' },
			],
			BLOCK_TYPE_BUTTONS: [
				{ label: 'UL', style: 'unordered-list-item' },
				{ label: 'OL', style: 'ordered-list-item' },
			],
		};

		return (
			<BaseModal className={styles.Modal}>
				<PoseGroup>
					{this.state.isVisible && [
						<Shade key="shade" className={styles.Overlay} />,

						<Modal
							key="modal"
							style={{
								marginLeft: 'auto',
							}}
						>
							<Container fluid>
								<Row>
									<Col
										md={12 - 4}
										onClick={(e) => {
											this.willUnmount(e);
										}}
									/>
									<Col md={4} className={styles.notify}>
										<div
											className={styles.notify__wrapper}
											id={'notificationModalContainer'}
										>
											<div className={styles.notify__top_wrapper}>
												<div className={styles.notify__title}>
													<FormattedHTMLMessage
														id={'rod.modal.notify.admin.create_notification'}
													/>
												</div>
												<CloseButton
													black
													small
													className={styles.CloseButtonNotify}
													onClick={(e) => {
														this.willUnmount(e);
													}}
												/>
											</div>
											<div className={styles.notify__lang}>
												<ul className={styles.Tray}>
													<li>
														{this.props.langContext.isShowLang && (
															<Lang
																className={styles.Lang}
																authContext={this.props.authContext}
															/>
														)}
													</li>
												</ul>
											</div>
											<div className={styles.notify__header}>
												<FormattedMessage
													id={'rod.modal.notify.admin.placeholder-header'}
												>
													{(placeholder) => (
														<FormInput
															placeholder={placeholder}
															name={'title'}
															onChange={this.changeField}
															errors={this.state.errors}
															required
															data={this.state}
															className={styles.articleNameInput}
														/>
													)}
												</FormattedMessage>
												{Object.keys(this.state.errors).includes('heading') && (
													<span className={styles.Helper}>
														{showError('notifyTitle')(
															this.state.errors.heading[0]
														)}
													</span>
												)}
											</div>
											<div className={styles.notify__body}>
												<RichTextEditor
													toolbarClassName={styles.toolbarEditor}
													editorClassName={styles.textEditor}
													placeholder={
														<FormattedMessage
															id={'rod.modal.notify.admin.placeholder-body'}
														/>
													}
													value={this.state.value}
													onChange={this.onChange}
													toolbarConfig={toolbarConfig}
												/>
											</div>

											<div
												className={`styles.notify__recipients ${
													this.state.sendAll || this.state.data.attachment.file ? styles.disable : ''
												}`}
											>
												<FormInput
													type={'muiAutocompleteMultipleUsers'}
													name={'recipients'}
													onChange={(e) => {
														this.changeSelectUsersField(e);
													}}
													errors={this.state.errors}
													data={this.state.data.selectedData}
													items={this.state.data.recipients}
												/>
											</div>
											<div className={styles.hint}>
												<FormattedMessage
													id={'rod.modal.notify.admin.recipients.hint'}
												/>
											</div>

											<div
												className={`${styles.notify__sendAllMark} ${
													this.state.data.attachment.file ? styles.disable : ''
												}`}
											>
												<div
													className={styles.checkbox_wrapper}
													onClick={() => {
														this.setState({
															sendAll: !this.state.sendAll,
															fileErrors: {},
															backErrors: {},
														});
													}}
												>
													<div
														className={
															this.state.sendAll
																? styles.checked
																: styles.notChecked
														}
													/>
													<div className={styles.checkbox_label}>
														<FormattedMessage
															id={'rod.modal.notify.admin.send-all'}
														/>
													</div>
												</div>
											</div>
											<div className={styles.notify__dropZone}>
												<DropZone
													multiple={false}
													accept={'.xls, .xlsx, .csv'}
													imgType={1}
													classStyles={styles.DropZone}
													dropzoneId="rod.admin.notify.upload.file.title"
													onUpload={this.handleFileUpload}
													onRemove={this.handleFileRemove}
													kb
													value={
														this.state.data.attachment.file
															? {
																	client_name: this.state.data.attachment
																		.fileData.name,

																	size: this.state.data.attachment.fileData
																		.size,
																	url: this.state.data.attachment.fileData.path,
															  }
															: null
													}
													maxSize={99991024 * 99991024 * 99100}
													disabled={this.state.sendAll}
												/>
											</div>
											{Object.keys(this.state.fileErrors).length > 0 && (
												<span className={styles.errorNotifyHelper}>
													{showError('')(this.state.fileErrors)}
												</span>
											)}
											{Object.keys(this.state.backErrors).length > 0 && (
												<span className={styles.errorNotifyHelper}>
													{showError('')(this.state.backErrors)}
												</span>
											)}
											{this.state.data.attachment &&
												this.state.data.attachment.length > 0 && (
													<div className={styles.filesBlock}>
														{this.state.data.attachment.map((file) => (
															<UploadedFile
																key={file.id}
																file={file}
																handleFileRemove={() =>
																	this.handleFileRemove(file.id)
																}
															/>
														))}
													</div>
												)}

											{!this.state.isShowConfirmQuestion ? (
												<div className={styles.notify__confirm}>
													<Button
														text={
															<FormattedHTMLMessage id={'rod.action.send'} />
														}
														className={
															!this.state.confirmed
																? styles.inactiveBtn
																: styles.activeBtn
														}
														variant={'primary'}
														disabled={
															!this.state.confirmed ||
															Object.keys(this.state.backErrors).length > 0
														}
														onClick={this.preConfirmData}
													/>
												</div>
											) : (
												<div
													className={styles.notify__confirm_question_wrapper}
												>
													<div className={styles.notify__confirm_question}>
														<FormattedHTMLMessage
															id={'rod.modal.notify.admin.confirm-question'}
														/>
													</div>
													<div className={styles.notify__confirm}>
														<Button
															text={
																<FormattedHTMLMessage
																	id={'rod.modal.notify.admin.confirm-yes'}
																/>
															}
															className={styles.activeBtnConfirmQuestion}
															variant={
																this.state.isConfirmedBtnActive
																	? 'primary'
																	: 'disabled'
															}
															onClick={() => {
																if (this.state.isConfirmedBtnActive) {
																	this.setState({
																		isConfirmedBtnActive: false,
																	});
																	this.debouncedSendData();
																}
															}}
														/>
														<Button
															text={
																<FormattedHTMLMessage
																	id={'rod.modal.notify.admin.confirm-no'}
																/>
															}
															className={styles.activeBtnConfirmQuestion}
															variant={
																this.state.isConfirmedBtnActive
																	? 'primary'
																	: 'disabled'
															}
															onClick={() => {
																this.setState({
																	isShowConfirmQuestion: false,
																});
															}}
														/>
													</div>
												</div>
											)}
										</div>
									</Col>
								</Row>
							</Container>
						</Modal>,
					]}
				</PoseGroup>
			</BaseModal>
		);
	}
}

export default compose(withLang, withUI, withRoot)(NotifyScreenModal);
