/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 25/05/2020, 20:02
 */

import { CloseButton } from 'components/Buttons';
import { Button } from 'components/Buttons';
import { Col, Container, Row } from 'components/Grid';
import { FormInput } from 'components/Form/FormInput';
import { compose } from 'recompose';
import { withLang, withRoot, withUI } from 'hocs';
// import { Lang } from 'components';
// import Admin from 'services/api/admin';
// import union from 'lodash/union';
// import { dateFormat } from 'logic';
import { DropZone } from 'components/Upload/DropZone';

import React, { useContext } from 'react';
import posed, { PoseGroup } from 'react-pose';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { useEffect, useState } from 'react';

import BaseModal from './BaseModal';
import styles from './Modal.module.css';
import debounce from 'lodash.debounce';
import { showError } from 'validators/showError';

const Modal = posed.div({
	enter: {
		x: 0,
		opacity: 1,
		// delay: 200,
		transition: {
			x: {
				ease: 'easeIn',
			},
			default: { duration: 300 },
		},
	},
	exit: {
		x: '100%',
		opacity: 0,
		transition: { duration: 250 },
	},
});

const Shade = posed.div({
	enter: { opacity: 0.5 },
	exit: { opacity: 0 },
});

const ReportsModal = (props) => {
	// const [pageLoading, setPageLoading] = useState(true);
	const [isVisible, setIsVisible] = useState(true);
	const [errors, setErrors] = useState({});
	const [reportLang, setReportLang] = useState('en');

	const [publishingDate, setPublishingDate] = useState({
		publishing_date: '',
	});
	const [attachment, setAttachment] = useState({ file: null, fileData: null });
	const [enConfirmed, setEnConfirmed] = useState(false);
	const [ruConfirmed, setRuConfirmed] = useState(false);
	const [isShowNotice, setIsShowNotice] = useState(false);
	const [isConfirmedBtnActive, setIsConfirmedBtnActive] = useState(true);
	const [isSaveBtnActive, setIsSaveBtnActive] = useState(false);

	const [isShowConfirmQuestion, setIsShowConfirmQuestion] = useState(false);

	const {
		reportId,
		onClose,

		refreshList,
		accountId,
		handleManualEdit,
	} = props;

	const currencies = [
		{ id: 1, title: 'EUR' },
		{ id: 2, title: 'USD' },
		{ id: 3, title: 'GBP' },
		{ id: 4, title: 'RUB' },
	];

	const [reportData, setReportData] = useState({
		account_id: accountId || '',
	});

	const willUnmount = (withData = true) => {
		setIsVisible(false);
		setTimeout(() => {
			if (withData) refreshList();
			onClose();
		}, 300);
	};

	useEffect(() => {
		if (attachment.file || (reportData.royalties && reportData.currency)) {
			setIsSaveBtnActive(true);
		} else {
			setIsSaveBtnActive(false);
		}
	}, [reportData, attachment]);

	const handleFileRemove = () => {
		setAttachment({
			file: null,
			fileData: null,
		});
	};

	const handleFileUpload = (files) => {
		const fileTypes = [
			'application/xls',
			'application/x-xls',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		];
		setErrors({});
		if (!fileTypes.includes(files[0].type)) {
			setErrors({ file: [{ rule: 'archive_wrong_format' }] });
			return;
		}

		const fd = new FormData();
		fd.append('file', files[0]);

		setAttachment({
			file: fd,
			fileData: files[0],
		});

		return Promise.resolve();
	};

	const debouncedSendData = debounce(() => sendData(), 500);

	const sendData = () => {
		setIsShowConfirmQuestion(false);
		const currency =
			reportData?.currency?.title === 'EUR'
				? '€'
				: reportData?.currency?.title === 'USD'
				? '$'
				: reportData?.currency?.title === 'GBP'
				? '£'
				: reportData?.currency?.title === 'RUB'
				? '₽'
				: '';
		handleManualEdit({
			reportId,
			reportData: { ...reportData, currency: currency },
			attachment,
		});
		willUnmount(false);
	};

	const changeField = (field) => (e) => {
		switch (field) {
			case 'royalties':
				if (!e.target.value.includes(' ') && !isNaN(+e.target.value))
					reportData[field] = e.target.value;

				break;
			case 'currency':
				reportData[field] = e;

				break;
			default:
				break;
		}
		setReportData({ ...reportData });
	};

	const preConfirmData = () => {
		if (reportData.sum && reportData.currencies) {
			setIsShowNotice(true);
		} else {
			setIsShowNotice(false);
			setIsShowConfirmQuestion(true);
		}
	};

	// eslint-disable-next-line no-unused-vars
	const handleOnBlurDateInput = (err, value) => {};

	return (
		<BaseModal className={styles.Modal}>
			<PoseGroup>
				{isVisible && [
					<Shade key="shade" className={styles.Overlay} />,

					<Modal
						key="modal"
						style={{
							marginLeft: 'auto',
						}}
					>
						<Container fluid>
							<Row>
								<Col
									/* width={'416'} */ /* md={12 - 4} */ onClick={() =>
										willUnmount(false)
									}
								/>
								<Col width={'1'} /* md={4} */ className={styles.notify}>
									<div
										className={styles.notify__wrapper}
										style={{ width: '400px' }}
									>
										<div className={styles.notify__top_wrapper}>
											<div className={styles.notify__title}>
												<FormattedHTMLMessage
													id={
														'rod.admin.reports.reports_with_errors_modal_title'
													}
												/>
											</div>
											<CloseButton
												black
												small
												className={styles.CloseButtonNotify}
												onClick={() => willUnmount(false)}
											/>
										</div>

										<div className={styles.dataTable}>
											<div className={styles.sumWrapper}>
												<div className={styles.sum}>
													<FormInput
														type={'muiInputNumber'}
														name={'royalties'}
														onChange={changeField}
														errors={errors}
														data={reportData}
														label={
															<FormattedMessage
																id={'rod.admin.reports.reports.summ'}
															/>
														}
													/>
													{Object.keys(errors).includes('royalties') && (
														<span className={styles.Helper}>
															{
																<FormattedMessage
																	id={`rod.error.${errors.royalties[0].rule}`}
																/>
															}
														</span>
													)}
												</div>
												<div className={styles.currencies}>
													<FormInput
														type={'MuiSelectCurrencies'}
														name={'currency'}
														onChange={changeField}
														errors={errors}
														data={reportData}
														className={styles.select}
														label={
															<FormattedMessage
																id={'rod.admin.reports.reports.currency'}
															/>
														}
														items={currencies}
													/>
													{Object.keys(errors).includes('currency') && (
														<span className={styles.Helper}>
															{
																// <FormattedMessage
																// 	id={`rod.error.${props.errors.title[0].rule}`}
																// />
																showError(errors.currency[0].rule)(
																	errors.currency[0]
																)
															}
														</span>
													)}
												</div>
											</div>

											<div className={styles.accId}>
												<FormInput
													type="muiInput"
													name={'account_id'}
													onChange={changeField}
													errors={errors}
													data={reportData}
													disabled={true}
													label={
														<FormattedHTMLMessage
															id={'rod.field.admin.reports.acc_id'}
														/>
													}
												/>
												{Object.keys(errors).includes('account_id') && (
													<span className={styles.Helper}>
														{
															<FormattedMessage
																id={`rod.error.${errors.account_id[0].rule}`}
															/>
														}
													</span>
												)}
											</div>
										</div>

										<div className={styles.DropZone__wrapper}>
											<DropZone
												multiple={false}
												accept={'.xls, .xlsx'}
												imgType={1}
												classStyles={styles.DropZone}
												dropzoneId={'rod.admin.reports.report_dropbox'}
												onUpload={handleFileUpload}
												onRemove={handleFileRemove}
												kb
												value={
													attachment.file
														? {
																client_name: attachment.fileData.name,

																size: attachment.fileData.size,
																url: attachment.fileData.path,
														  }
														: null
												}
												maxSize={99991024 * 99991024 * 99100}
											/>
										</div>

										{!isShowConfirmQuestion ? (
											<>
												<div className={styles.reports__confirm}>
													{isShowNotice && (
														<div className={styles.notify__confirm_question}>
															<FormattedHTMLMessage
																id={'rod.admin.reports.confirm_one_article'}
															/>
														</div>
													)}
													<Button
														text={
															<FormattedHTMLMessage
																id={'rod.performance_work.save'}
															/>
														}
														className={
															((reportLang === 'en' && enConfirmed) ||
																(reportLang === 'ru' && ruConfirmed)) &&
															!Object.keys(errors).length &&
															publishingDate.publishing_date.length === 10
																? styles.activeReportsBtn
																: styles.inactiveReportsBtn
														}
														variant={isSaveBtnActive ? 'primary' : 'disabled'}
														onClick={() => {
															isSaveBtnActive && preConfirmData();
														}}
													/>
												</div>
											</>
										) : (
											<div className={styles.notify__confirm_question_wrapper}>
												<div className={styles.notify__confirm_question}>
													<FormattedHTMLMessage
														id={'rod.modal.notify.admin.confirm-question'}
													/>
												</div>
												{/* {enConfirmed && ruConfirmed && ( */}
												<div className={styles.notify__confirm}>
													<Button
														text={
															<FormattedHTMLMessage
																id={'rod.modal.notify.admin.confirm-yes'}
															/>
														}
														className={styles.activeBtnConfirmQuestion}
														variant={
															isConfirmedBtnActive ? 'primary' : 'disabled'
														}
														onClick={() => {
															if (isConfirmedBtnActive) {
																setIsConfirmedBtnActive(false);
																debouncedSendData();
															}
														}}
													/>
													<Button
														text={
															<FormattedHTMLMessage
																id={'rod.modal.notify.admin.confirm-no'}
															/>
														}
														className={styles.activeBtnConfirmQuestion}
														variant={
															isConfirmedBtnActive ? 'primary' : 'disabled'
														}
														onClick={() => {
															if (isConfirmedBtnActive) {
																sendData();
															}
														}}
													/>
												</div>
												{/* 	)} */}
											</div>
										)}
									</div>
								</Col>
							</Row>
						</Container>
					</Modal>,
				]}
			</PoseGroup>
		</BaseModal>
	);
};

export default compose(withLang, withUI, withRoot)(ReportsModal);
