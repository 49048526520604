// Core
import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRoot, withAuth } from 'hocs';
import { compose } from 'recompose';
import debounce from 'lodash.debounce';

// UI
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Input, SelectCustom } from 'material-design/MuiStyled/MuiStyled';
import { CheckboxBlack } from 'material-design/MuiStyled/MuiStyled';
import { TextFieldInput } from 'material-design/MuiStyled/MuiStyled';
import MuiMultipleCheckedSkeleton from '../MuiMultipleCheckedSkeleton/MuiMultipleCheckedSkeleton';
import { Label } from 'components/Labels';

// Styles
import s from './RecordingsFilter.module.css';

const MultipleSelectCheckmarksPlaylist = ({
	rootContext: { getAccountRecordingsFilterPlaylist },
	accountId,
	lang,
	recordingsState,
	dateFrom,
	dateTo,
	setPage,
}) => {
	const [recordings, setRecordings] = useState([]);
	const [checkedRecordingIds, setCheckedRecordingIds] = useState([]);
	const [cachedIds, setCachedIds] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [isShowSkeleton, setIsShowSkeleton] = useState(false);
	const [isEmptyData, setIsEmptyData] = useState(false);
	const [nameRecording, setNameRecording] = useState('');
	const [isFirstRender, setIsFirstRender] = useState(true);

	const playlistsPagination = JSON.parse(
		localStorage.getItem('playlistsPagination')
	);

	const [page] = useState(1);
	const [limit] = useState(100);

	const [checkedRecordings, setCheckedRecordings] = useState([]);

	const getRecordings = () => {
		setIsShowSkeleton(true);
		getAccountRecordingsFilterPlaylist(
			false,
			accountId,
			searchValue,
			page,
			limit,
			playlistsPagination?.startDate || dateFrom,
			playlistsPagination?.endDate || dateTo
		).then((res) => {
			const data = res.data.recordings.map((item) => ({
				...item,
				checked: checkedRecordingIds.includes(item.id) ? true : false,
			}));

			const allRecordingsButton = {
				id: 0,
				raw_title: lang === 'en' ? 'Select all' : 'Выбрать все',
				checked: searchValue.length > 0 ? false : true,
			};

			let recordingsData;

			if (data.length) {
				recordingsData = [allRecordingsButton, ...data];
				setIsEmptyData(false);
			} else {
				recordingsData = [];
				setIsEmptyData(true);
			}

			const newRecordings = recordingsData.map((item) => ({
				...item,
				checked: checkedRecordingIds.includes(item.id),
			}));

			if (!searchValue.length) {
				setCheckedRecordingIds(
					newRecordings.filter((item) => item.checked)?.map((item) => item.id)
				);
				setCheckedRecordings(
					newRecordings.filter((item) => item.checked)?.map((item) => item)
				);
			}

			setRecordings(recordingsData);
			setIsShowSkeleton(false);
		});
	};

	useEffect(() => {
		if (isFirstRender) {
			setIsFirstRender(false);
			return;
		}
		getRecordings();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		searchValue,
		playlistsPagination?.startDate,
		playlistsPagination?.endDate,
	]);

	useEffect(() => {
		getRecordings();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleCheckedRecording = (checkedRecording) => {
		let mappedRecordings;

		if (checkedRecording.id === 0 && checkedRecording.checked) {
			mappedRecordings = recordings.map((release) => ({
				...release,
				checked: false,
			}));

			setCheckedRecordingIds([]);
			setCheckedRecordings([]);
		}

		if (checkedRecording.id === 0 && !checkedRecording.checked) {
			mappedRecordings = recordings.map((release) => ({
				...release,
				checked: true,
			}));

			setCheckedRecordingIds(recordings.map((release) => release.id));
			setCheckedRecordings(recordings.map((release) => release));
		}

		if (!(checkedRecording.id === 0) && checkedRecording.checked) {
			mappedRecordings = recordings.map((release) => {
				if (release.id === 0) {
					return { ...release, checked: false };
				}

				if (release.id === checkedRecording.id) {
					return { ...release, checked: false };
				} else {
					return release;
				}
			});

			setCheckedRecordingIds(
				checkedRecordingIds.filter(
					(id) => id !== checkedRecording.id && id !== 0
				)
			);
			setCheckedRecordings(
				checkedRecordings.filter(
					(recording) =>
						recording.id !== checkedRecording.id && recording.id !== 0
				)
			);
		}

		if (!(checkedRecording.id === 0) && !checkedRecording.checked) {
			mappedRecordings = recordings.map((release) => {
				if (release.id === 0) {
					return { ...release, checked: false };
				}

				if (release.id === checkedRecording.id) {
					return { ...release, checked: true };
				} else {
					return release;
				}
			});

			setCheckedRecordingIds([
				...checkedRecordingIds.filter((checkedId) => checkedId !== 0),
				checkedRecording.id,
			]);

			setCheckedRecordings([
				...checkedRecordings.filter((recording) => recording.id !== 0),
				checkedRecording,
			]);
		}

		setRecordings(mappedRecordings);
	};

	const handleSearchInput = (e) => {
		setSearchValue(e.target.value);
	};

	const debouncedSearchHandler = useCallback(
		debounce(handleSearchInput, 900),
		[]
	);

	const handleClickOnly = (e, recording) => {
		e.stopPropagation();

		const mappedRecordings = recordings.map((item) => ({
			...item,
			checked: item.id === recording.id,
		}));

		setCheckedRecordingIds([recording.id]);
		setCheckedRecordings([recording]);

		setRecordings(mappedRecordings);
	};

	const handleSelectClose = () => {
		if (cachedIds !== checkedRecordingIds) {
			setPage(1);
			recordingsState(checkedRecordingIds);
		}
	};

	const handleSelectOpen = () => {
		setCachedIds(checkedRecordingIds);
	};

	return (
		<FormControl variant="standard" className={s.formControl}>
			<Input id="tracks-select-label">
				<FormattedMessage id="rod.statistic.search-recordings-performers" />
			</Input>

			<SelectCustom
				labelId="tracks-select-label"
				id="tracks-select"
				multiple
				value={!checkedRecordingIds.length ? [] : recordings}
				onOpen={handleSelectOpen}
				onClose={handleSelectClose}
				disabled={isShowSkeleton}
				MenuProps={{
					style: { zIndex: 99999999999 },
				}}
				renderValue={() => {
					if (recordings.length === 1 && recordings[0].id === 0) {
						return (
							<>
								<FormattedMessage id={'rod.statistic.selected'} /> 0{' '}
								<FormattedMessage id={'rod.statistic.selected-tracks'} />
							</>
						);
					}

					if (
						checkedRecordings.filter((recording) => recording.id !== 0)
							.length === 1
					) {
						return <span> {checkedRecordings[0]?.raw_title}</span>;
					} else if (!isShowSkeleton && recordings.length !== 0) {
						return (
							<>
								<FormattedMessage id={'rod.statistic.selected'} />{' '}
								{checkedRecordingIds.includes(0)
									? checkedRecordingIds.length - 1
									: checkedRecordingIds.length}{' '}
								<FormattedMessage id={'rod.statistic.selected-tracks'} />
							</>
						);
					} else if (isShowSkeleton) {
						return <FormattedMessage id={'rod.statistic.updating-data'} />;
					}
				}}
			>
				<FormattedMessage id="rod.statistic.search-recordings-performers">
					{(label) => (
						<TextFieldInput
							className={s.search}
							label={label}
							initialValue={searchValue ? searchValue : ''}
							defaultValue={searchValue}
							onChange={debouncedSearchHandler}
							variant="standard"
						/>
					)}
				</FormattedMessage>
				<div className={s.wrapper}>
					{!isShowSkeleton ? (
						recordings.map((recording, index) => (
							<MenuItem
								key={recording.id}
								value={recording.raw_title}
								onClick={() => handleCheckedRecording(recording)}
							>
								<CheckboxBlack
									checked={checkedRecordingIds.includes(recording.id)}
								/>
								<Label>
									{recording.raw_title}{' '}
									{recording.id !== 0 ? `- ${recording.performers}` : ''}
								</Label>
								<button
									className={s.btnOnly}
									onClick={(e) => handleClickOnly(e, recording)}
								>
									<FormattedMessage id={'rod.statistic.only'} />
								</button>
							</MenuItem>
						))
					) : (
						<MuiMultipleCheckedSkeleton />
					)}

					{!recordings.length && !isShowSkeleton && (
						<p className={s.noData}>
							<FormattedMessage id={'rod.no_data_select'} />
						</p>
					)}
				</div>
			</SelectCustom>
		</FormControl>
	);
};

export default compose(withRoot, withAuth)(MultipleSelectCheckmarksPlaylist);
