import { RegisterSpecial as RegisterForm } from 'forms/index';
import { withAuth, withRoot, withUI } from 'hocs';
import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import styles from './RegisterSpecial.module.css';

class RegisterSpecial extends PureComponent {
	static propTypes = {};

	componentDidMount() {
		this.props.UIContext.showBackNavi();
	}

	componentWillUnmount() {
		this.props.UIContext.hideBackNavi();
	}

	render() {
		const {
			authContext: { isAuth, registerSpecial, errors, isRegisterInProcess },
		} = this.props;

		if (isAuth) {
			return <Redirect to={'/'} />;
		}

		return (
			<div className={styles.Page}>
				<RegisterForm
					onSubmit={registerSpecial}
					errors={errors}
					isRegisterInProcess={isRegisterInProcess}
				/>
			</div>
		);
	}
}

export default compose(withRoot, withAuth, withUI)(RegisterSpecial);
