// Core
import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

// Constants
import errorRules from 'constants/errorRules';

export const showError = (field) => (error) => {
	if (typeof error === 'string') {
		switch (error) {
			case errorRules.EXISTS_IN_SIGNUP:
				return (
					<FormattedHTMLMessage id={`rod.error.exists_in_signup.${field}`} />
				);
			case errorRules.EXISTS_IN_USER:
				return (
					<FormattedHTMLMessage id={`rod.error.exists_in_user.${field}`} />
				);
			case errorRules.DOES_NOT_MATCH:
				return (
					<FormattedHTMLMessage
						id={
							field === 'main_performer' || field === 'party_id'
								? `rod.error.${field}.does_not_match`
								: `rod.error.${error}`
						}
					/>
				);

			default:
				return <FormattedHTMLMessage id={`rod.error.${error}`} />;
		}
	} else {
		if (!field && error.upper_case_extension) {
			return (
				<FormattedHTMLMessage
					id={'rod.error.admin.notify.extension.incorrect'}
				/>
			);
		}

		if (!field && (error.wrong_file_type || error.accounts_file)) {
			return (
				<FormattedHTMLMessage
					id={'rod.error.admin.notify.file_type.incorrect'}
				/>
			);
		}

		if (!field && error.accounts_id) {
			return (
				<FormattedHTMLMessage
					id={'rod.error.admin.notify.account_id.required'}
				/>
			);
		}

		if (
			(field === 'date_p_line' || field === 'date_c_line') &&
			error.rule === 'max_length'
		) {
			return <FormattedHTMLMessage id="rod.error.wrong_format" />;
		}
		if (
			(field === 'bank_country' || field === 'swift_code') &&
			error &&
			error?.message &&
			error?.message ===
				'Bank data does not meet the requirements of the system'
		) {
			return (
				<FormattedHTMLMessage id={'rod.error.currency_country_not_match'} />
			);
		}

		if (field === 'phone' && error.rule === 'not_uniques')
			return <FormattedHTMLMessage id={'rod.error.phone_exists'} />;

		if (field === 'phone' && error.rule === 'retry')
			return <FormattedHTMLMessage id={'rod.error.code.retry'} />;

		if (
			(field === 'password' || field === 'password_confirmation') &&
			error.rule === 'be_confirmed'
		) {
			return (
				<FormattedHTMLMessage id={'rod.error.forgot_password.not_coincide'} />
			);
		}

		switch (error.rule) {
			case errorRules.USER_EXIST:
				return <FormattedHTMLMessage id={`rod.error.${field}_exist`} />;
			case errorRules.MIN_LENGTH:
			case errorRules.MIN_AGE:
			case errorRules.MAX_LENGTH:
				if (error.value) {
					return (
						<FormattedHTMLMessage
							id={`rod.error.${error.rule}`}
							values={{ num: error.value ? +error.value : null }}
						/>
					);
				} else {
					return (
						<FormattedHTMLMessage id={`rod.error.${error.rule}_no_value`} />
					);
				}
			case errorRules.INVALID:
				if (field === 'password' || field === 'password_confirmation') {
					return (
						<FormattedHTMLMessage id={`rod.error.${error.rule}_${field}`} />
					);
				}

				return (
					<FormattedHTMLMessage
						id={`rod.error.${error.rule}`}
						values={{ num: error.value ? +error.value : null }}
					/>
				);
			case errorRules.VALIDATION_CYRILLIC:
			case errorRules.CYRILLIC_INVALID:
				if (field === 'password') {
					return (
						<FormattedHTMLMessage id={`rod.error.${error.rule}_${field}`} />
					);
				}

				return (
					<FormattedHTMLMessage
						id={`rod.error.${error.rule}`}
						values={{ num: error.value ? +error.value : null }}
					/>
				);
			case errorRules.REQUIRED:
			case errorRules.REQUIRED_UNLESS:
				if (field === 'society') {
					return <FormattedHTMLMessage id={`rod.error.${error.rule}`} />;
				}
				if (field === 'rod.contracts.add.conditions.header') {
					return (
						<FormattedHTMLMessage id={`rod.error.contracts.add.conditions`} />
					);
				}
				if (field === 'rod.contracts.add.conditions.header.lyric') {
					return (
						<FormattedHTMLMessage
							id={`rod.error.contracts.add.conditions_with_lyric`}
						/>
					);
				}
				return (
					<FormattedHTMLMessage
						id={`rod.error.${error.rule}`}
						values={{ num: error.value ? +error.value : null }}
					/>
				);
			case errorRules.SOMETHING_WENT_WRONG:
			case errorRules.WRONG_EMAIL_OR_PASSWORD:
				return (
					<FormattedHTMLMessage
						id={`rod.error.incorrect_username_or_password`}
					/>
				);
			case errorRules.UPC_FORMAT:
				return <FormattedHTMLMessage id={`rod.error.invalid_upc_format`} />;
			case errorRules.CODE_INVALID:
				return <FormattedHTMLMessage id={`rod.error.invalid_code_invalid`} />;
			case errorRules.FORBIDDEN:
				return <FormattedHTMLMessage id={'rod.error.code_exhausted'} />;
			case errorRules.SALE_START_SLA:
				return (
					<FormattedMessage id={'rod.error.sale.start.date.sla.one.plus'} />
				);
			case errorRules.SALE_START_NOT_SLA:
				return <FormattedMessage id={'rod.error.minDate'} />;
			case errorRules.SALE_START_2_DAYS:
				return <FormattedMessage id={'rod.error.sales_start_date_2_days'} />;
			case errorRules.SALE_START_3_DAYS:
				return <FormattedMessage id={'rod.error.sales_start_date_3_days'} />;
			case 'there is no release type':
				return (
					<FormattedMessage id={'rod.error.select.another.release.type'} />
				);

			default:
				return (
					<FormattedHTMLMessage
						id={`rod.error.${error.rule}`}
						values={{ num: error.value ? +error.value : null }}
					/>
				);
		}
	}
};
