// Core
import React from 'react';
import { withRouter } from 'react-router-dom';
import lscache from 'lscache';
import isEqual from 'lodash/isEqual';
import axios from 'axios';

// Utils
import { auth, accounts } from 'services';
import widgetConfigs from '../rod_instance_configuration.json';
import { getLastItemFromUrl, reachDataLayer } from 'utils';
import { reachParams } from 'utils';
import { EVENTS, paramName, paramSuccess, userId } from 'utils/reachParams';

const AuthContext = React.createContext();

class Provider extends React.PureComponent {
	state = {
		accountId: localStorage.getItem('accountId') || null,
		countryId: null,
		isAccountLoading: false,
		isRedirect: true,
		isAuth: false,
		loaded: false,
		isJoin: false,
		isJoinCheckedComplete: false,
		isLegacy: false,
		isShowStatistics: false,
		isRegisterInProcess: false,
		isHideTechnicalWidget: false,

		isAccountDisallowManualReport: false,
		isAccountHideTechnicalSupportWidget: false,
		isAccountFaqDisabled: false,
		isAccountHideContracts: false,

		isContractsRightsChangeEnable: false,
		isAddReleaseEnableForAccount: false,
		isAddCompositionEnableForAccount: false,
		isEditReleaseAfterShipmentForAccount: false,
		isShipmentsTakedownsEnableFeatureForAccount: false,
		isSlaPrivelegesForAccount: false,
		isArtistIdForAccount: false,
		isTimeZoneForAccount: false,
		user: null,
		error: null,
		errors: {},
		regUpdateErrors: {},
		regData: {
			phone: null,
		},
		user_completion_step: '',
		prevWidgetParams: {},
		adminV2Settings: {
			page: 1,
			filterStatus: [],
			searchValue: '',
		},
		adminV2StatisticIssue: { outlet: '', date: '', data: [] },
		stepForWidget: '',
		isAllowLanguageDetection: false,
		emailVerifyCode: '',
		countEqualLimit: false,
		countEqualLimitSms: false,
	};

	setIsAccountLoading = (value) => {
		this.setState({ isAccountLoading: value });
	};

	setEmailVerifyCode = (value) => {
		this.setState({ emailVerifyCode: value });
	};

	setAdminV2StatisticIssue = ({ outlet, date, data }) => {
		this.setState({
			adminV2StatisticIssue: { outlet, date, data },
		});
	};

	getAllowLanguageDetectionParam = (param = false) => {
		this.setState({ isAllowLanguageDetection: param });
	};

	setAdminV2Settings = (params) => {
		this.setState({
			adminV2Settings: {
				page: params.page,
				filterStatus: params.filterStatus,
				searchValue: params.searchValue,
			},
		});
	};

	getStepForWidget = (step) => {
		this.setState({ stepForWidget: step });
	};

	getFlagForWidget = (flag = false) => {
		this.setState({ isHideTechnicalWidget: flag });
	};

	getAccountCompletionStep = (completionStep = '', id) => {
		if (id !== this.state.accountId || !id) {
			completionStep === 'personal_area' ||
			completionStep === 'repertoire_apply'
				? this.setState({ isJoin: true, isLegacy: false })
				: this.setState({ isJoin: false, isLegacy: false });

			if (
				completionStep === 'personal_area_legacy' ||
				completionStep === 'account_legacy'
			) {
				this.setState({ isJoin: false, isLegacy: true });
			}
		}
		this.setState({ isJoinCheckedComplete: true });
		this.changeWidget();
	};

	login = ({ username, password }) => {
		localStorage.removeItem('token');
		localStorage.removeItem('refresh');

		const eventName = EVENTS.login;
		const formName = 'вход / логин';
		const pageUrl = window.location.href;

		return auth
			.login(username, password)
			.then((resp) => {
				const yaParams = {
					[pageUrl]: {
						[eventName]: {
							[formName]: {
								[paramName]: paramSuccess,
							},
						},
					},
				};
				reachParams(yaParams);
				reachDataLayer(eventName, formName, paramSuccess, '', '');
				localStorage.setItem('token', resp.access_token);
				localStorage.setItem('refresh', resp.refresh_token);
				this.setState(
					{
						errors: {},
					},
					() => {
						this.getUser();
					}
				);
			})
			.catch((data) => {
				const yaParams = {
					[pageUrl]: {
						[eventName]: {
							[formName]: {
								[paramName]: data.status,
							},
						},
					},
				};
				reachParams(yaParams);
				reachDataLayer(eventName, formName, data.status, '', '');

				console.info('Data in catch of AuthContext- login: ', data);
				console.info(
					'Data.response in catch of AuthContext- login: ',
					data.response
				);
				this.setState({
					errors:
						Object.keys(data.errors).length && typeof data.errors !== 'string'
							? data.errors
							: { password: [{ rule: data.status }] },
					isAuth: false,
				});
			});
	};

	logout = () => {
		localStorage.removeItem('queue_statuses');
		return auth
			.logout()
			.then(() => {
				localStorage.removeItem('token');
				localStorage.removeItem('refresh');
				lscache.flush();
				this.setState(
					{
						isAuth: false,
						user: null,
					},
					() => {
						localStorage.removeItem('releaseId');
						localStorage.removeItem('accountId');
						localStorage.removeItem('helper');
						localStorage.removeItem('no_contract');
						this.getAccountCompletionStep();
						this.props.history.push('/');
					}
				);
			})
			.catch((error) => {
				this.setState({
					error,
					isAuth: false,
					user: null,
				});
			});
	};

	contractsExpiredRedirect = (status) => {
		status = status.toString();
		localStorage.setItem('no_contract', true);
		switch (status) {
			case '205':
				this.props.history.push('/contract_overdue');
				break;
			case '207':
				this.props.history.push('/no_contract');
				break;

			default:
				break;
		}
	};

	setIsNoContract = () => {
		localStorage.removeItem('no_contract');
	};

	registerOtherStage = async (registerData, redirectStage) => {
		const url =
			redirectStage === 'RU'
				? process.env.REACT_APP_API_RU
				: process.env.REACT_APP_API_COM;
		const client = axios.create({
			withCredentials: true,
			baseURL: `${url}`,
		});

		const eventName = EVENTS.registration;
		const formName = 'Форма самостоятельной регистрации"';
		const pageUrl = window.location.href;

		client
			.post(`/auth/registration`, {
				agreement: true,
				...registerData,
			})
			.then((result) => {
				const yaParams = {
					[pageUrl]: {
						[eventName]: {
							[formName]: {
								[paramName]: paramSuccess,
							},
						},
					},
				};
				reachParams(yaParams);
				reachDataLayer(eventName, formName, paramSuccess, '', '');

				client.interceptors.request.use((config) => {
					config.headers = {
						'Content-Language': localStorage.getItem('lang') || 'en',
						'X-Requested-With': 'XMLHttpRequest',
						'X-Access-Token': result.data.data.access_token,
						'X-Client-Version': window.COMMIT_SHA || null,
						'Content-Type': 'application/json',
						'Refresh-Token': result.data.data.refresh_token,
					};
					return config;
				});

				client.post(`/auth/token/create`).then((handoffResult) => {
					if (handoffResult.data.data.handoff_token) {
						const otherStage =
							process.env[`REACT_APP_REDIRECT_URL_${redirectStage}`];
						return window.location.assign(
							`${otherStage}?handoff_token=${handoffResult.data.data.handoff_token}`
						);
					}
				});
			})
			.catch((data) => {
				if (data?.response?.data?.data?.errors || data?.response?.data.errors) {
					const yaParams = {
						[pageUrl]: {
							[eventName]: {
								[formName]: {
									[paramName]: data?.response?.status,
								},
							},
						},
					};
					reachParams(yaParams);
					reachDataLayer(eventName, formName, data?.response?.status, '', '');

					this.setState({
						errors:
							data?.response?.data.errors ?? data?.response?.data?.data?.errors,
						isAuth: false,
						isRegisterInProcess: false,
					});
				}
			});
	};

	register = async (data) => {
		this.clearAllErrors();
		localStorage.removeItem('token');
		localStorage.removeItem('refresh');

		if (process.env.REACT_APP_API_RU && process.env.REACT_APP_API_COM) {
			const countryCode = localStorage.getItem('countryCode');
			if (
				(countryCode === 'RU' || countryCode === 'BY') &&
				document.location.origin === process.env.REACT_APP_REDIRECT_URL_COM
			) {
				return this.registerOtherStage(data, 'RU');
			}
			if (
				countryCode !== 'RU' &&
				countryCode !== 'BY' &&
				document.location.origin === process.env.REACT_APP_REDIRECT_URL_RU
			) {
				return this.registerOtherStage(data, 'COM');
			}
		}

		this.setState({ isRegisterInProcess: true });

		const eventName = EVENTS.registration;
		const formName = 'Форма самостоятельной регистрации"';
		const pageUrl = window.location.href;

		return auth
			.register(data)
			.then((resp) => {
				const yaParams = {
					[pageUrl]: {
						[eventName]: {
							[formName]: {
								[paramName]: paramSuccess,
							},
						},
					},
				};
				reachParams(yaParams);

				localStorage.setItem('token', resp.access_token);
				localStorage.setItem('refresh', resp.refresh_token);
				this.setState(
					{
						isAuth: false,
						regData: data,
					},
					() => {
						this.getUser();
					}
				);
			})
			.catch((data) => {
				const yaParams = {
					[pageUrl]: {
						[eventName]: {
							[formName]: {
								[paramName]: data.status,
							},
						},
					},
				};
				reachParams(yaParams);

				this.setState({
					errors: data.errors ? data.errors : data.data.errors,
					isAuth: false,
					isRegisterInProcess: false,
				});
			})
			.finally(() => {
				this.setState({ isRegisterInProcess: false });
			});
	};

	registerSpecial = (data) => {
		this.setState({ isRegisterInProcess: true });

		const eventName = EVENTS.requestPersonalForm;
		const formName = 'Форма "Персональное предложение"';
		const pageUrl = window.location.href;

		auth
			.registerSpecial(data)
			.then(() => {
				const yaParams = {
					[pageUrl]: {
						[eventName]: {
							[formName]: {
								[paramName]: paramSuccess,
							},
						},
					},
				};
				reachParams(yaParams);
				reachDataLayer(eventName, formName, paramSuccess, '', '');
				this.props.history.push('/registration/special/success');
			})
			.catch((data) => {
				const yaParams = {
					[pageUrl]: {
						[eventName]: {
							[formName]: {
								[paramName]: data.status,
							},
						},
					},
				};
				reachParams(yaParams);
				this.setState({
					errors: data.errors,
				});
				reachDataLayer(eventName, formName, data.status, '', '');
			})
			.finally(() => {
				this.setState({ isRegisterInProcess: false });
			});
	};

	forgotPassword = ({ email, password, password_confirmation }) => {
		return auth
			.resetPasswordRequest({
				email,
				password,
			})
			.then((resp) => {
				this.props.history.push('/password/restore/email');
				return Promise.resolve(resp);
			})
			.catch((err) => {
				throw err;
			});
	};

	isEmailValid = (email) => {
		return auth
			.isEmailValid(email)
			.then((resp) => {
				return Promise.resolve(resp.data);
			})
			.catch((data) => {
				return Promise.reject(data);
			});
	};

	changePassword = ({ token }) => {
		return auth.resetPassword({ token });
	};

	addCodeErrors = (newErrors) => {
		this.setState({
			errors: { ...this.state.errors, ...newErrors },
		});
	};

	clearAllErrors = () => {
		this.setState({
			errors: {},
		});
	};

	clearFieldErrors = (field) => {
		// Create a copy of the current errors
		const errors = { ...this.state.errors };
		// Delete the errors for the specified field
		delete errors[field];
		// Update the state with the new errors object
		this.setState({ errors });
	};

	clearRegUpdateErrors = () => {
		this.setState({
			regUpdateErrors: {},
		});
	};

	verifySMS = ({ code }) => {
		const eventName = EVENTS.submitRequestSms;
		const formName = 'Форма "введите код из смс"';
		const pageUrl = window.location.href;

		auth
			.checkSMSCode({ code })
			.then((resp) => {
				const yaParams = {
					[pageUrl]: {
						[eventName]: {
							[formName]: {
								[paramName]: paramSuccess,
								[userId]: this.state.user?.id,
							},
						},
					},
				};
				reachParams(yaParams);
				reachDataLayer(
					eventName,
					formName,
					paramSuccess,
					this.state.user?.id,
					''
				);
				this.getUser();
				return Promise.resolve(resp);
			})
			.catch((data) => {
				const yaParams = {
					[pageUrl]: {
						[eventName]: {
							[formName]: {
								[paramName]: data.status,
								[userId]: this.state.user?.id,
							},
						},
					},
				};
				reachParams(yaParams);
				reachDataLayer(
					eventName,
					formName,
					data.status,
					this.state.user?.id,
					''
				);
				this.setState({
					errors: data.errors,
				});

				if (data.status === 'unauthorized_error') {
					this.props.history.push('/session-expired');
				}
			});
	};

	getSmsRetryTime = (type) => {
		return auth
			.getSmsRetryTime(type)
			.then()
			.catch((error) => {
				if (error.status === 'unauthorized_error') {
					this.props.history.push('/session-expired');
				} else {
					return Promise.reject(error);
				}
			});
	};

	resendSMS = (phone) => {
		return auth
			.resendSMSCode({
				phone: phone
					? phone
					: this.state.regData.phone || this.state.user.phone,
			})
			.then()
			.catch((error) => {
				if (error.status === 'unauthorized_error') {
					this.props.history.push('/session-expired');
				} else {
					return Promise.reject(error);
				}
			});
	};

	verifyEmail = ({ code }) => {
		const eventName = EVENTS.submitRequestEmail;
		const formName = 'Форма "введите код из письма"';
		const pageUrl = window.location.href;

		auth
			.checkEmailCode({ code })
			.then((resp) => {
				const yaParams = {
					[pageUrl]: {
						[eventName]: {
							[formName]: {
								[paramName]: paramSuccess,
								[userId]: this.state.user?.id,
							},
						},
					},
				};
				reachParams(yaParams);
				reachDataLayer(
					eventName,
					formName,
					paramSuccess,
					this.state.user?.id,
					''
				);
				resp = resp.data.data;
				localStorage.setItem('token', resp.access_token);
				localStorage.setItem('refresh', resp.refresh_token);
				this.getUser();
				this.props.history.push('/accounts');
				return Promise.resolve(resp);
			})
			.catch((data) => {
				const yaParams = {
					[pageUrl]: {
						[eventName]: {
							[formName]: {
								[paramName]: data.status,
								[userId]: this.state.user?.id,
							},
						},
					},
				};
				reachParams(yaParams);
				reachDataLayer(
					eventName,
					formName,
					data.status,
					this.state.user?.id,
					''
				);
				this.setState({
					errors: data.response.data.errors,
				});

				if (data.status === 'unauthorized_error') {
					this.props.history.push('/session-expired');
				}
			});
	};

	registrationUpdate = (email, phone) => {
		auth
			.registrationUpdate(email, phone)
			.then((res) => {
				if (
					res.email &&
					res.email.count &&
					res.email.limit &&
					res.email.count === res.email.limit
				) {
					this.setState({
						countEqualLimit: true,
					});
				} else if (
					res.sms &&
					res.sms.count &&
					res.sms.limit &&
					res.sms.count === res.sms.limit
				) {
					this.setState({
						countEqualLimitSms: true,
					});
				}
				return Promise.resolve(res);
			})
			.catch((data) => {
				if (data && data.errors) {
					this.setState({
						regUpdateErrors: data.errors,
					});
				} else if (data && data.data) {
					this.setState({
						regUpdateErrors: data?.data?.errors,
					});
				}
				return Promise.reject(data);
			});
	};

	resendEmail = (data) => {
		auth
			.resendEmailCode(data)
			.then()
			.catch((data) => {
				this.setState({
					errors: data?.errors,
				});
				return Promise.reject(data);
			});
	};

	noCode = (data) => {
		auth
			.noCode(data)
			.then((res) => {
				this.props.history.push('/registration/success');
			})
			.catch((data) => {
				this.setState({
					errors: data.errors,
				});
				return Promise.reject(data);
			});
	};

	userUpdate = (data) => {
		auth
			.userUpdate(data)
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((data) => {
				if (data && data.errors) {
					this.setState({
						regUpdateErrors: data.errors,
					});
				} else if (data && data.data) {
					this.setState({
						regUpdateErrors: data?.data?.errors,
					});
				}
				return Promise.reject(data);
			});
	};

	switchAccount = (id) => {
		if (Number(id) !== Number(this.state.accountId)) {
			const updatedUser = { ...this.state.user, account_id: id };
			if (id && id !== 'null') {
				this.getFeaturesFlagsForAccount(id);
			}

			this.setState(
				{
					accountId: id,
					user: updatedUser,
				},
				() => {
					localStorage.setItem('accountId', id);
					this.changeWidget(this.state.user);
				}
			);
		}
	};

	checkUserId = (resp) => {
		if (resp.accounts.length) {
			resp.account_id
				? localStorage.setItem('accountId', resp.account_id)
				: localStorage.setItem('accountId', resp.accounts[0].id);
		} else {
			localStorage.setItem('accountId', null);
		}
	};

	getWidgetConfigs = (branch, isAuth, lang) => {
		if (
			process.env.REACT_APP_CLIENT === 'dgtal' ||
			this.state.isAccountHideTechnicalSupportWidget
		)
			return;
		if (!process.env.REACT_APP_CLIENT) branch = 'broma';
		return {
			serviceId: widgetConfigs[branch][isAuth].serviceId[lang],
			channelId: widgetConfigs[branch][isAuth].channelId[lang],
		};
	};

	changeWidget = (
		resp = this.state.user,
		changedLang = localStorage.getItem('lang')
	) => {
		if (
			this.state.isHideTechnicalWidget ||
			this.state.isAccountHideTechnicalSupportWidget
		)
			return;

		const backgroundColor =
			process.env.REACT_APP_CLIENT === 'dgtal'
				? `#7fabdc`
				: process.env.REACT_APP_CLIENT === 'mts'
				? '#ddd1fd'
				: '#dade67';

		if (process.env.REACT_APP_CLIENT === 'mts') {
			const container = document.querySelector('#chat21-container');
			const widgetRU = document.querySelector('#autofaqWidgetRU');
			const widgetEN = document.querySelector('#autofaqWidgetEN');

			// устанавливаем язык
			document.cookie = `lang=${changedLang}; SameSite=None; Secure`;
			const script1 = document.createElement('script');

			// удаляем старый виджет
			if (this.state.isAuth) {
				if (
					resp.account_id?.toString() ===
						this.state.prevWidgetParams.id?.toString() &&
					changedLang === this.state.prevWidgetParams.lang &&
					this.state.stepForWidget === this.state.prevWidgetParams.step
				)
					return;

				if (container) {
					container?.parentElement?.remove();
					container?.remove();
				}

				// удаляем скрипт добавления виджета
				if (widgetRU || widgetEN) {
					widgetRU ? widgetRU.remove() : widgetEN.remove();
				}

				this.setState({
					prevWidgetParams: {
						id: resp.account_id,
						lang: changedLang,
						step: this.state.stepForWidget,
					},
				});
			} else {
				if (
					process.env.REACT_APP_CLIENT === 'dgtal' ||
					this.state.isAccountHideTechnicalSupportWidget
				)
					return;

				if (container) container.remove();
				// удаляем скрипт добавления виджета
				if (widgetRU || widgetEN) {
					widgetRU ? widgetRU.remove() : widgetEN.remove();
				}

				const config = this.getWidgetConfigs(
					`${process.env.REACT_APP_CLIENT}`,
					'notAuth',
					changedLang
				);

				if (config.serviceId === '' || config.channelId === '') return;
			}

			document.body.appendChild(script1);
		} else {
			if (
				process.env.REACT_APP_CLIENT === 'dgtal' ||
				this.state.isAccountHideTechnicalSupportWidget
			)
				return;
			const container = document.querySelector('#widget-fr');

			if (window.fcWidget?.isInitialized() && !window.fcWidget?.isLoaded()) {
				return;
			}

			if (container) {
				window.fcWidget?.destroy();
				container.remove();
			}

			const script1 = document.createElement('script');
			script1.id = 'widget-fr';
			script1.src = '//fw-cdn.com/10484357/3445451.js';
			script1.setAttribute('chat', 'true');

			if (this.state.isAuth) {
				script1.setAttribute(
					'widgetId',
					'79baea60-e174-4279-9ae6-85cf230ba863'
				);

				if (
					resp.account_id?.toString() !==
						this.state.prevWidgetParams.id?.toString() ||
					changedLang !== this.state.prevWidgetParams.lang ||
					this.state.stepForWidget !== this.state.prevWidgetParams.step
				) {
					const account = resp?.accounts.find(
						(item) => item?.id?.toString() === resp?.account_id?.toString()
					);

					window.fcWidgetMessengerConfig = {
						locale: `${changedLang}`,
						firstName: account?.first_name
							? `${account?.first_name}`
							: account?.title
							? `${account?.title}`
							: '-',
						lastName: account?.last_name ? `${account?.last_name}` : '-',
						phone: resp?.phone ? `${resp?.phone}` : null,
						meta: {
							cf_email: `${resp?.login}` || '-',
							cf_account_name: account?.title ? `${account?.title}` : '-',
							cf_h11_account_id: resp?.account_id ? `${resp?.account_id}` : '-',
							cf_h11_user_id: `${resp?.id}` || '-',
							cf_user_name: `${resp?.title}` || '-',
							cf_registration_step: this.state.stepForWidget
								? `${this.state.stepForWidget}`
								: `${resp?.completion_step}`,
							cf_instance: `${document.domain}`,
						},
						config: {
							headerProperty: {
								backgroundColor,
								foregroundColor: '#fff',
							},
						},
					};
				}
			} else {
				if (changedLang !== this.state.prevWidgetParams.lang) {
					window.fcWidgetMessengerConfig = {
						locale: `${changedLang}`,
						meta: {
							cf_instance: `${document.domain}`,
						},
						config: {
							headerProperty: {
								backgroundColor,
								foregroundColor: '#fff',
							},
						},
					};
				}
			}
			document.body.appendChild(script1);
			setTimeout(() => {
				if (!window.fcWidget?.isInitialized() && !window.fcWidget?.isLoaded()) {
					this.changeWidget();
				}
			}, 4000);
		}
	};

	setIsAccountDisallowManualReport = (flag) => {
		this.setState({ isAccountDisallowManualReport: flag });
	};

	setIsAccountFaqDisabled = (flag) => {
		this.setState({ isAccountFaqDisabled: flag });
	};

	setIsAccountHideTechnicalSupportWidget = (flag) => {
		this.setState({ isAccountHideTechnicalSupportWidget: flag });
	};

	setIsAccountHideContracts = (flag) => {
		this.setState({ isAccountHideContracts: flag });
	};

	setIsContractsRightsChangeEnable = (flag) => {
		this.setState({ isContractsRightsChangeEnable: flag });
	};

	setIsAddReleaseEnableForAccount = (flag) => {
		this.setState({ isAddReleaseEnableForAccount: flag });
	};

	setIsAddCompositionEnableForAccount = (flag) => {
		this.setState({ isAddCompositionEnableForAccount: flag });
	};

	setIsShipmentsTakedownsEnableFeatureForAccount = (flag) => {
		this.setState({ isShipmentsTakedownsEnableFeatureForAccount: flag });
	};

	setIsEditReleaseAfterShipmentForAccount = (flag) => {
		this.setState({ isEditReleaseAfterShipmentForAccount: flag });
	};

	setIsSlaPrivelegesForAccount = (flag) => {
		this.setState({ isSlaPrivelegesForAccount: flag });
	};

	setIsArtistIdForAccount = (flag) => {
		this.setState({ isArtistIdForAccount: flag });
	};

	setIsTimeZoneForAccount = (flag) => {
		this.setState({ isTimeZoneForAccount: flag });
	};

	getFeaturesFlagsForAccount = (accountId) => {
		if (!accountId || accountId === 'null') return;

		accounts
			.getFeaturesFlagsForAccount(accountId)
			.then((res) => {
				res = res.data.data;

				res['flags_accounts.disallow_manual_report']
					? this.setState({
							isAccountDisallowManualReport:
								res['flags_accounts.disallow_manual_report'],
					  })
					: this.setState({
							isAccountDisallowManualReport: false,
					  });

				res['flags_accounts.faq.disabled']
					? this.setState({
							isAccountFaqDisabled: res['flags_accounts.faq.disabled'],
					  })
					: this.setState({
							isAccountFaqDisabled: false,
					  });

				res['flags_accounts.hide.technical_support_widget']
					? this.setState({
							isAccountHideTechnicalSupportWidget:
								res['flags_accounts.hide.technical_support_widget'],
					  })
					: this.setState({
							isAccountHideTechnicalSupportWidget: false,
					  });

				res['flags_accounts.hide_contracts']
					? this.setState({
							isAccountHideContracts: res['flags_accounts.hide_contracts'],
					  })
					: this.setState({
							isAccountHideContracts: false,
					  });

				res['account_feature.rights_change']
					? this.setState({
							isContractsRightsChangeEnable:
								res['account_feature.rights_change'],
					  })
					: this.setState({
							isContractsRightsChangeEnable: false,
					  });

				res['account_feature.add_release_create']
					? this.setState({
							isAddReleaseEnableForAccount:
								res['account_feature.add_release_create'],
					  })
					: this.setState({
							isAddReleaseEnableForAccount: false,
					  });

				res['account_feature.add_composition_enabled']
					? this.setState({
							isAddCompositionEnableForAccount:
								res['account_feature.add_composition_enabled'],
					  })
					: this.setState({
							isAddCompositionEnableForAccount: false,
					  });

				res['account_feature.artist_id']
					? this.setState({
							isArtistIdForAccount: res['account_feature.artist_id'],
					  })
					: this.setState({
							isArtistIdForAccount: false,
					  });

				res['account_feature.editing_release']
					? this.setState({
							isEditReleaseAfterShipmentForAccount:
								res['account_feature.editing_release'],
					  })
					: this.setState({
							isEditReleaseAfterShipmentForAccount: false,
					  });

				res['account_feature.interaction_shipped_release']
					? this.setState({
							isShipmentsTakedownsEnableFeatureForAccount:
								res['account_feature.interaction_shipped_release'],
					  })
					: this.setState({
							isShipmentsTakedownsEnableFeatureForAccount: false,
					  });

				res['account_feature.sla_privileges']
					? this.setState({
							isSlaPrivelegesForAccount: res['account_feature.sla_privileges'],
					  })
					: this.setState({
							isSlaPrivelegesForAccount: false,
					  });

				res['account_feature.artist_id']
					? this.setState({
							isArtistIdForAccount: res['account_feature.artist_id'],
					  })
					: this.setState({
							isArtistIdForAccount: false,
					  });

				res['account_feature.time_zone']
					? this.setState({
							isTimeZoneForAccount: res['account_feature.time_zone'],
					  })
					: this.setState({
							isTimeZoneForAccount: false,
					  });
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	putAuthDataToState(resp) {
		const shouldUpdate = !isEqual(
			{
				isAuth: true,
				accountId: this.state.accountId,
				user: {
					...this.state.user,
					accounts: resp.accounts,
				},
				loaded: true,
				user_completion_step: this.state.user_completion_step,
			},
			{
				isAuth: true,
				accountId: resp.account_id
					? resp.account_id
					: localStorage.getItem('accountId'),
				user: {
					...resp,
					accounts: resp.accounts,
				},
				loaded: true,
				user_completion_step: resp.completion_step,
			}
		);

		shouldUpdate &&
			this.setState(
				{
					isAuth: true,
					accountId: resp.account_id
						? resp.account_id
						: localStorage.getItem('accountId'),
					user: {
						...resp,
						accounts: resp.accounts,
					},
					loaded: true,
					user_completion_step: resp.completion_step,
				},
				() => {
					this.changeWidget(resp);

					switch (resp.completion_step) {
						case 'confirm_phone':
							this.props.history.push('/registration/sms/check');
							break;
						case 'confirm_email':
							const verifyCode = getLastItemFromUrl();
							this.setEmailVerifyCode(verifyCode);
							if (verifyCode) {
								this.verifyEmail({ code: verifyCode });
							} else {
								this.props.history.push('/registration/email/check');
							}
							break;
						case 'account':
							if (~this.props.location.pathname.indexOf('registration')) {
								this.props.history.push('/accounts');
							}
							break;
						case 'account_legacy':
							this.state.accountId
								? this.props.history.push(
										`/accounts/${this.state.accountId}/dashboard`
								  )
								: this.props.history.push('/accounts');
							break;

						default:
							this.props.history.push('/');
					}

					this.setState({ isRegisterInProcess: false });

					const { accounts } = this.state.user;
					const account_id = this.state.accountId;

					if (account_id) {
						const account = accounts?.filter(
							(item) => item.id.toString() === account_id.toString()
						);

						if (account?.length) {
							this.getAccountCompletionStep(account[0].completion_step);
						}
						if (account_id !== 'null' && !account?.length) {
							this.setState({
								isJoinCheckedComplete: true,
							});

							this.props.history.push('/no_contract');
						}

						if (account_id === null || account_id === 'null') {
							this.setState({
								isJoinCheckedComplete: true,
							});
						}
					} else {
						this.props.history.push('/accounts');
					}
				}
			);
	}

	getUser = () => {
		if (!this.state.isRedirect) {
			this.getUserData();
			return;
		}

		if (this.props.location.search.includes('handoff_token')) {
			const handoff = this.props.location.search.substring(15);

			auth.getTokensWithHandoff(handoff).then((res) => {
				res = res.data.data;
				if (res.access_token && res.refresh_token) {
					localStorage.setItem('token', res.access_token);
					localStorage.setItem('refresh', res.refresh_token);
					lscache.set('isAlreadyRedirected', true, 5);

					this.getUserData();
				} else {
					this.getUserData();
				}
			});
		} else {
			this.getUserData();
		}
	};

	getUserData = () => {
		const token = localStorage.getItem('token');

		if (token) {
			return auth
				.getUser()
				.then((resp) => {
					resp = resp.data.data;

					const account = resp.accounts.filter(
						(item) => parseInt(resp.account_id) === parseInt(item.id)
					);

					this.setState({
						countryId: account[0].country_id,
					});

					this.checkUserId(resp);
					this.getStepForWidget(
						account.length ? account[0].completion_step : ''
					);
					if (resp.account_id && resp.account_id !== 'null') {
						this.getFeaturesFlagsForAccount(resp.account_id);
					}

					if (!this.state.isRedirect) {
						this.putAuthDataToState(resp);
						return;
					}

					if (this.state.isAllowLanguageDetection) {
						const access = localStorage.getItem('token');
						const refresh = localStorage.getItem('refresh');
						const isAlreadyRedirected = lscache.get('isAlreadyRedirected');
						if (!resp.account_id && resp.role !== 'admin') {
							auth.getCountryCode().then((res) => {
								const countryCode = res.data.data;
								if (typeof countryCode !== 'boolean') {
									switch (countryCode) {
										case 'RU':
											if (
												document.location.origin ===
												process.env.REACT_APP_REDIRECT_URL_RU
											)
												return this.putAuthDataToState(resp);

											auth
												.createTokenForRedirect(access, refresh)
												.then((res) => {
													res = res.data.data;
													if (res.handoff_token) {
														window.location.assign(
															`${process.env.REACT_APP_REDIRECT_URL_RU}?handoff_token=${res.handoff_token}`
														);
													} else {
														window.location.assign(
															`${process.env.REACT_APP_REDIRECT_URL_RU}`
														);
													}
												});
											break;

										case 'BY':
											if (
												document.location.origin ===
												process.env.REACT_APP_REDIRECT_URL_RU
											)
												return this.putAuthDataToState(resp);
											auth
												.createTokenForRedirect(access, refresh)
												.then((res) => {
													res = res.data.data;
													if (res.handoff_token) {
														window.location.assign(
															`${process.env.REACT_APP_REDIRECT_URL_RU}?handoff_token=${res.handoff_token}`
														);
													} else {
														window.location.assign(
															`${process.env.REACT_APP_REDIRECT_URL_RU}`
														);
													}
												});
											break;
										default:
											if (
												document.location.origin ===
												process.env.REACT_APP_REDIRECT_URL_COM
											)
												return this.putAuthDataToState(resp);
											auth
												.createTokenForRedirect(access, refresh)
												.then((res) => {
													res = res.data.data;
													if (res.handoff_token) {
														window.location.assign(
															`${process.env.REACT_APP_REDIRECT_URL_COM}?handoff_token=${res.handoff_token}`
														);
													} else {
														window.location.assign(
															`${process.env.REACT_APP_REDIRECT_URL_COM}`
														);
													}
												});
											break;
									}
								} else {
									return this.putAuthDataToState(resp);
								}
							});
						} else if (
							(resp.account_id &&
								resp.role !== 'admin' &&
								!isAlreadyRedirected) ||
							(resp.account_id && resp.role !== 'admin' && resp.redirect)
						) {
							auth.getDomain(resp.account_id).then((domainData) => {
								const domain = domainData.data ? domainData.data.data : true;

								switch (domain) {
									case true:
										this.putAuthDataToState(resp);
										break;
									case null:
										this.putAuthDataToState(resp);
										break;

									case 'RU':
										if (
											document.location.origin ===
											process.env.REACT_APP_REDIRECT_URL_RU
										)
											return this.putAuthDataToState(resp);

										auth.createTokenForRedirect(access, refresh).then((res) => {
											res = res.data.data;
											if (res.handoff_token) {
												window.location.assign(
													`${process.env.REACT_APP_REDIRECT_URL_RU}?handoff_token=${res.handoff_token}`
												);
											} else {
												window.location.assign(
													`${process.env.REACT_APP_REDIRECT_URL_RU}`
												);
											}
										});
										break;

									case 'BY':
										if (
											document.location.origin ===
											process.env.REACT_APP_REDIRECT_URL_RU
										)
											return this.putAuthDataToState(resp);
										auth.createTokenForRedirect(access, refresh).then((res) => {
											res = res.data.data;
											if (res.handoff_token) {
												window.location.assign(
													`${process.env.REACT_APP_REDIRECT_URL_RU}?handoff_token=${res.handoff_token}`
												);
											} else {
												window.location.assign(
													`${process.env.REACT_APP_REDIRECT_URL_RU}`
												);
											}
										});
										break;
									default:
										if (
											document.location.origin ===
											process.env.REACT_APP_REDIRECT_URL_COM
										)
											return this.putAuthDataToState(resp);
										auth.createTokenForRedirect(access, refresh).then((res) => {
											res = res.data.data;
											if (res.handoff_token) {
												window.location.assign(
													`${process.env.REACT_APP_REDIRECT_URL_COM}?handoff_token=${res.handoff_token}`
												);
											} else {
												window.location.assign(
													`${process.env.REACT_APP_REDIRECT_URL_COM}`
												);
											}
										});
										break;
								}
							});
						} else {
							this.putAuthDataToState(resp);
						}
					} else {
						this.putAuthDataToState(resp);
					}
				})
				.catch((err) => {
					console.error(
						'AuthContext - api- error.response, error  - getUser',
						err.response,
						err
					);
					this.setState(
						{
							isAuth: false,
							user: null,
							loaded: true,
						},
						() => {
							this.changeWidget();
							if (err.response?.status === 502) {
								this.props.history.push('/service-not-available');
							} else {
								this.props.history.push('/session-expired');
							}
						}
					);
				});
		} else {
			this.setState(
				{
					loaded: true,
					isAuth: false,
					user: null,
					isJoinCheckedComplete: true,
				},
				() => {
					this.changeWidget();
				}
			);
		}
	};

	initV3Captcha() {
		if (process.env.REACT_APP_RECAPTCHA_V3KEY) {
			const captchaScript = document.createElement('script');
			captchaScript.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_V3KEY}`;
			document.body.appendChild(captchaScript);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			this.setState({
				errors: {},
			});
		}
	}

	componentDidMount() {
		this.getUser();
	}

	render() {
		return (
			<AuthContext.Provider
				value={{
					...this.state,
					login: this.login,
					logout: this.logout,
					register: this.register,
					resendSMS: this.resendSMS,
					verifySMS: this.verifySMS,
					verifyEmail: this.verifyEmail,
					registrationUpdate: this.registrationUpdate,
					userUpdate: this.userUpdate,
					resendEmail: this.resendEmail,
					noCode: this.noCode,
					registerSpecial: this.registerSpecial,
					forgotPassword: this.forgotPassword,
					isEmailValid: this.isEmailValid,
					changePassword: this.changePassword,
					switchAccount: this.switchAccount,
					getUser: this.getUser,
					getUserData: this.getUserData,
					addCodeErrors: this.addCodeErrors,
					clearAllErrors: this.clearAllErrors,
					clearFieldErrors: this.clearFieldErrors,
					clearRegUpdateErrors: this.clearRegUpdateErrors,
					getAccountCompletionStep: this.getAccountCompletionStep,
					changeWidget: this.changeWidget,
					setAdminV2Settings: this.setAdminV2Settings,
					setAdminV2StatisticIssue: this.setAdminV2StatisticIssue,
					getStepForWidget: this.getStepForWidget,
					getAllowLanguageDetectionParam: this.getAllowLanguageDetectionParam,
					contractsExpiredRedirect: this.contractsExpiredRedirect,
					setIsNoContract: this.setIsNoContract,
					getFlagForWidget: this.getFlagForWidget,
					getSmsRetryTime: this.getSmsRetryTime,
					setIsAccountLoading: this.setIsAccountLoading,

					setIsAccountDisallowManualReport: this
						.setIsAccountDisallowManualReport,
					setIsAccountFaqDisabled: this.setIsAccountFaqDisabled,
					setIsAccountHideTechnicalSupportWidget: this
						.setIsAccountHideTechnicalSupportWidget,
					setIsAccountHideContracts: this.setIsAccountHideContracts,
					setIsContractsRightsChangeEnable: this
						.setIsContractsRightsChangeEnable,
					setIsAddReleaseEnableForAccount: this.setIsAddReleaseEnableForAccount,
					setIsAddCompositionEnableForAccount: this
						.setIsAddCompositionEnableForAccount,
					setIsEditReleaseAfterShipmentForAccount: this
						.setIsEditReleaseAfterShipmentForAccount,
					setIsShipmentsTakedownsEnableFeatureForAccount: this
						.setIsShipmentsTakedownsEnableFeatureForAccount,
					setIsSlaPrivelegesForAccount: this.setIsSlaPrivelegesForAccount,
					setIsArtistIdForAccount: this.setIsArtistIdForAccount,
					setIsTimeZoneForAccount: this.setIsTimeZoneForAccount,
				}}
			>
				{this.props.children}
			</AuthContext.Provider>
		);
	}
}

const AuthConsumer = AuthContext.Consumer;
const AuthProvider = withRouter(Provider);

export { AuthProvider, AuthConsumer, AuthContext };
