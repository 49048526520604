// Core
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

// UI
import { Button } from 'components/Buttons';
import { Checkbox } from 'components/Checkboxes';
import { Input, PhoneInput } from 'components/Inputs';
import { Label } from 'components/Labels';

// Utils
import { withUI } from 'hocs';
import { showError } from 'validators/showError';
import { compose } from 'recompose';
import { reachGoal } from 'utils';
import { EVENTS } from 'utils/reachParams';

// Types
import PropTypes from 'prop-types';

// Styles
import styles from 'forms/auth/Registration/SpecialRegistrationForm/RegisterSpecial.module.css';

class RegisterSpecial extends PureComponent {
	static propTypes = {
		onSubmit: PropTypes.func,
		errors: PropTypes.object,
	};

	static defaultProps = {
		errors: {},
	};

	count = React.createRef();
	form = React.createRef();
	name = React.createRef();
	email = React.createRef();
	phone = React.createRef();
	company = React.createRef();
	agreedCheckbox = React.createRef();

	state = {
		agreed: false,
		repertoireCount: '',
		email: '',
		errFromRepertoire: false,
		errFromEmail: false,
	};

	handleAgreed = () => {
		this.setState({ agreed: this.agreedCheckbox.current.checked });
	};

	handleOnRepertoireCount = (e) => {
		this.setState({ repertoireCount: e });
		this.setState({ errFromRepertoire: false });
	};

	handleOnEmail = (e) => {
		this.setState({ email: e });
		this.setState({ errFromEmail: false });
	};

	handleSubmit = (event) => {
		reachGoal(EVENTS.requestPersonalForm);
		event.preventDefault();
		const { errors } = this.props;
		const email = 'email';
		const maxLength = 'max_length';
		const worksNumber = 'works_number';
		const minRepertoire = 'min_repertoire_value';

		errors[worksNumber] && delete errors[worksNumber];
		errors[email] && delete errors[email];

		if (parseInt(this.state.repertoireCount) <= 0) {
			const err = showError(worksNumber)({ rule: minRepertoire });

			this.setState({ errFromRepertoire: true });
			errors[worksNumber] = [{ rule: minRepertoire }];

			return err;
		}

		if (this.state.email.length > 100) {
			const err = showError(email)({ rule: maxLength });

			this.setState({ errFromEmail: true });
			errors[email] = [{ rule: maxLength, value: '100' }];

			return err;
		}

		if (this.props.onSubmit) {
			this.props.onSubmit({
				works_number: this.count.current.value,
				company: this.company.current.value,
				name: this.name.current.value,
				email: this.email.current.value,
				phone: this.phone.current.value,
			});
		}
	};

	render() {
		const {
			UIContext: { isPrivatePolicyShow },
			errors,
		} = this.props;

		return (
			<div ref={this.form} className={styles.Form}>
				<div className={styles.Title}>
					<Label className={styles.header}>
						<FormattedMessage id={'rod.registration.special.header'} />
					</Label>
					<Label className={styles.subheader}>
						<FormattedMessage id={'rod.registration.special.subheader'} />
					</Label>
				</div>

				<div className={styles.Fields}>
					<FormattedMessage id={'rod.field.repertoire_count'}>
						{(placeholder) => (
							<Input
								className={styles.Input}
								value={this.state.repertoireCount}
								placeholder={placeholder}
								forwardRef={this.count}
								errors={errors.works_number}
								showError={showError('works_number')}
								type="number"
								numberNonNegative={true}
								onChange={this.handleOnRepertoireCount}
							/>
						)}
					</FormattedMessage>
					<FormattedMessage id={'rod.field.name'}>
						{(placeholder) => (
							<Input
								className={styles.Input}
								placeholder={placeholder}
								forwardRef={this.name}
								showError={showError('name')}
								errors={errors.name}
								inputMaxLength={255}
							/>
						)}
					</FormattedMessage>
					<FormattedMessage id={'rod.field.company'}>
						{(placeholder) => (
							<Input
								className={styles.Input}
								placeholder={placeholder}
								forwardRef={this.company}
								showError={showError('company')}
								errors={errors.company}
							/>
						)}
					</FormattedMessage>
					<FormattedMessage id={'rod.field.email'}>
						{(placeholder) => (
							<Input
								value={this.state.email}
								className={styles.Input}
								placeholder={placeholder}
								forwardRef={this.email}
								showError={showError('email')}
								errors={errors.email}
								onChange={this.handleOnEmail}
							/>
						)}
					</FormattedMessage>
					<FormattedMessage id={'rod.field.phone'}>
						{(placeholder) => (
							<PhoneInput
								placeholder={placeholder}
								ref={this.phone}
								showError={showError('phone')}
								required
								errors={errors.phone}
							/>
						)}
					</FormattedMessage>

					<Checkbox
						className={styles.Checkbox}
						ref={this.agreedCheckbox}
						onChange={this.handleAgreed}
						required
					>
						{isPrivatePolicyShow ? (
							<FormattedMessage
								id={'rod.register.terms'}
								values={{
									a: (msg) => (
										<a
											href={`/examples/privacy_policy_v3.pdf`}
											target="_blank"
											rel="noopener noreferrer"
										>
											{msg}
										</a>
									),
								}}
							/>
						) : (
							<FormattedMessage id={'rod.register.terms_no_link'} />
						)}
					</Checkbox>
				</div>

				<div className={styles.Controls}>
					<Button
						className={styles.Button}
						text={<FormattedMessage id={'rod.action.send_request'} />}
						type={'submit'}
						onClick={this.handleSubmit}
						variant={'primary'}
						disabled={!this.state.agreed || this.props.isRegisterInProcess}
					/>
				</div>
			</div>
		);
	}
}

export default compose(withUI)(RegisterSpecial);
