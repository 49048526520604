// Core
import React, {
	useEffect,
	useState,
	useContext,
	useRef,
	useCallback,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import debounce from 'lodash.debounce';

// Context
import { LangContext } from 'contexts/LangContext';
import { RootContext } from 'contexts/RootContext';
import { UIContext } from 'contexts/UIContext';

// UI
import { Pagination, Input } from 'material-design/components';
import { MainStatsCompositions } from 'material-design/components/MainStats/MainStatsCompositions';
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';
import { accounts } from 'services';
import { Loading } from 'components';
import { Col, Row, Container } from 'react-grid-system';
import { RepertoireFilterModal } from 'material-design/modals/RepertoireFilterModal';
import Banner from '../RepertoireReleaseSection/Banner/Banner';
import ContentEmpty from '../ContentEmpty/ContentEmpty';

// Icons
import edit from 'images/edit.svg';
import { Nota } from 'material-design/svg';
import close from 'images/close_button.svg';

// Data
import { filterCompositionSettingsData } from './data/filterSettingsData';

// Utils
import { useOutsideClick } from 'material-design/hooks';
import { onEditCompositionHandler } from './utils/onEditCompositionHandler';

// Styles
import styles from './RepertoireCompositionSection.module.css';

const RepertoireCompositionSection = (props) => {
	const { statistics } = props;
	const {
		authContext: { accountId, isAuth },
		UIContext: { upload, isAccountFeatureCompositionView },
	} = props;

	const prevFilters = JSON.parse(localStorage.getItem('prevFilters'));
	const prevStatus = localStorage.getItem('prevStatus');
	const prevPage = localStorage.getItem('prevPage');

	const { currentCompositionStatus, materialsAvailableEditing } = useContext(
		UIContext
	);
	const { lang } = useContext(LangContext);

	const [page, setPage] = useState(prevPage !== null ? +prevPage : 1);
	const [songItems, setSongItems] = useState([]);
	const [value, setValue] = useState('');
	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState(true);

	const [banner, setBanner] = useState(true);
	const [modal, setModal] = useState(false);
	const [filterSettings, setFilterSettings] = useState(
		filterCompositionSettingsData
	);
	const [currentFilterSettings, setCurrentFilterSettings] = useState(
		prevFilters ?? {}
	);
	const [selectFilters, setSelectFilters] = useState(prevFilters ?? {});
	const [compositionStatus, setCompositionStatus] = useState(
		prevStatus ?? currentCompositionStatus
	);
	const [isFocus, setIsFocus] = useState(false);
	const [availableEditing, setAvailableEditing] = useState(
		materialsAvailableEditing
	);

	const resultRef = useRef(null);
	const helpRef = useRef(null);

	const modalOpen = (active) => setModal(active);
	const handleChangeFilter = (data) => setFilterSettings(data);
	const handleCurrentFilter = (data) => setCurrentFilterSettings(data);
	const onClose = () => {
		setIsFocus(false);
	};

	const history = useHistory();
	let { push } = useHistory();

	const {
		getAccountPersonalData,
		getAccountContactsData,
		getRepertoireDataByCiType,
	} = useContext(RootContext);

	useOutsideClick(resultRef, onClose, isFocus, helpRef);

	const handleClear = () => {
		const newFilterSettings = [...filterSettings];
		newFilterSettings.map((item) =>
			item.list.map((list_item) => (list_item.checked = false))
		);
		setCurrentFilterSettings({});
		setFilterSettings(newFilterSettings);
		setSelectFilters({});
		localStorage.removeItem('prevFilters');
	};

	const handleFilterClear = (item) => {
		const newFilterSettings = [...filterSettings];
		newFilterSettings[item.parentId - 1].list[
			item.currentId - 1
		].checked = false;
		item.show = false;
		setCurrentFilterSettings({
			...currentFilterSettings,
		});
		setFilterSettings(newFilterSettings);
		localStorage.removeItem('prevFilters');
	};

	useEffect(() => {
		accounts.getFeatures().then((res) => {
			res = res.data.data;
			setAvailableEditing(res['feature.materials_available_editing']);
		});
	}, []);

	useEffect(() => {
		const firstFilter = currentFilterSettings
			? Object.values(currentFilterSettings)[0]?.show
				? Object.values(currentFilterSettings)[0]
				: ''
			: '';

		if (firstFilter) {
			setCompositionStatus(firstFilter.code);
			localStorage.setItem('prevStatus', firstFilter.code);
		} else {
			setCompositionStatus('');
			localStorage.removeItem('prevStatus');
		}
	}, [currentFilterSettings]);

	useEffect(() => {
		if (isAuth && accountId !== 'null') {
			getAccountPersonalData(accountId)
				.then((account) => {})
				.catch((err) => push('/accounts'));

			getAccountContactsData(accountId)
				.then((data) => {})
				.catch((err) => push('/accounts'));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onChange = (filterText) => {
		setLoading(true);
		setValue(filterText);
		getCompositionData(filterText);
		setPage(1);
		localStorage.setItem('prevPage', 1);
	};

	useEffect(() => {
		setLoading(true);
		getCompositionData(value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [compositionStatus]);

	useEffect(() => {
		setLoading(true);
		getCompositionData(value, page);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	const handleReceivedDraftFilterData = (res, status) => {
		if (res && Array.isArray(res.data) && res.data.length > 0) {
			res.data.forEach((item) => {
				item.statuses = status;
			});
			setTotal(res.total);
			setSongItems(res.data);
		} else {
			setTotal(0);
			setSongItems([]);
		}
	};

	const getCompositionData = (filterText, page = prevPage) => {
		const searchByStatus = compositionStatus.length > 0;
		switch (filterText.length >= 3) {
			case true:
				switch (searchByStatus) {
					case true:
						if (
							['draft_processing', 'draft_verify', 'verify'].includes(
								compositionStatus
							)
						) {
							accounts
								.getAssetsPageTextByStatus(
									accountId,
									filterText,
									page,
									'compositions',
									`status=${compositionStatus}`
								)
								.then((res) => {
									res = res.data;
									handleReceivedDraftFilterData(res, compositionStatus);
								})
								.catch((error) => {
									console.error('Error', error);
								})
								.finally(() => {
									setLoading(false);
								});
						} else if (
							['active', 'expired', 'expiring'].includes(compositionStatus)
						) {
							getRepertoireDataByCiType(
								accountId,
								'compositions',
								compositionStatus,
								page,
								filterText
							)
								.then((res) => {
									setTotal(res.total);
									setSongItems(res.data);
								})
								.catch((error) => {
									console.info('Error', error);
								})
								.finally(() => {
									setLoading(false);
								});
						} else {
							accounts
								.getAssetsPageTextByStatus(
									accountId,
									filterText,
									page,
									'compositions',
									`status=${compositionStatus}`
								)
								.then((res) => {
									res = res.data.data;
									setTotal(res.total);
									setSongItems(res.data);
								})
								.catch((error) => {
									console.info('Error', error);
								})
								.finally(() => {
									setLoading(false);
								});
						}
						break;
					case false:
						accounts
							.getAssetsPageText(accountId, filterText, page, 'compositions')
							.then((res) => {
								res = res.data.data;
								setTotal(res.total);
								setSongItems(res.data);
							})
							.catch((error) => {
								console.info('Error', error);
							})
							.finally(() => {
								setLoading(false);
							});
						break;
					default:
						break;
				}
				break;
			case false:
				switch (searchByStatus) {
					case true:
						if (
							['draft_processing', 'draft_verify', 'verify'].includes(
								compositionStatus
							)
						) {
							accounts
								.getAssetsPageByStatus(
									accountId,
									'compositions',
									page,
									`status=${compositionStatus}`
								)
								.then((res) => {
									res = res.data;
									handleReceivedDraftFilterData(res, compositionStatus);
								})
								.catch((error) => {
									console.info('Error', error);
								})
								.finally(() => {
									setLoading(false);
								});
						} else if (
							['active', 'expired', 'expiring'].includes(compositionStatus)
						) {
							getRepertoireDataByCiType(
								accountId,
								'compositions',
								compositionStatus,
								page
							)
								.then((res) => {
									setTotal(res.total);
									setSongItems(res.data);
								})
								.catch((error) => {
									console.info('Error', error);
								})
								.finally(() => {
									setLoading(false);
								});
						} else {
							accounts
								.getCompositionsPageByStatus(accountId, compositionStatus, page)
								.then((res) => {
									res = res.data;
									setTotal(res.total);
									setSongItems(res.data);
								})
								.catch((error) => {
									console.info('Error', error);
								})
								.finally(() => {
									setLoading(false);
								});
						}
						break;
					case false:
						accounts
							.getAssetsPage(accountId, 'compositions', page)
							.then((res) => {
								res = res.data.data;
								setTotal(res.total);
								setSongItems(res.data);
							})
							.catch((error) => {
								console.info('Error', error);
							})
							.finally(() => {
								setLoading(false);
							});
						break;
					default:
						break;
				}
				break;
			default:
				return;
		}
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleFilter = useCallback(debounce(onChange, 500), [
		compositionStatus,
	]);

	const showStatus = (statuses) => {
		const importancy = ['disputed', 'ready', 'not_ready'];

		if (Array.isArray(statuses) && statuses.length > 1) {
			let resultedStatus = 'not_ready';
			statuses.forEach((stat) => {
				if (importancy.indexOf(stat) < importancy.indexOf(resultedStatus)) {
					resultedStatus = stat;
				}
			});
			return resultedStatus;
		} else return statuses;
	};

	const changeStatusFromStatsBlock = (selectedStatus) => {
		if (selectedStatus === '') {
			handleClear();
			return;
		}

		const item = filterSettings[0];
		const setting = item.list.find((obj) => obj.code === selectedStatus);

		if (setting) {
			item.list.map((obj) =>
				obj.id === setting.id ? (obj.checked = true) : (obj.checked = false)
			);

			const newCurrentFilter = {};
			newCurrentFilter['' + item.id + setting.id] = {
				parentId: item.id,
				currentId: setting.id,
				id: '' + item.id + setting.id,
				title_en: item.title_en,
				title_ru: item.title_ru,
				name_en: setting.name_en,
				name_ru: setting.name_ru,
				code: setting.code,
				show: true,
			};

			setCurrentFilterSettings(newCurrentFilter);
			setSelectFilters(newCurrentFilter);
			setPage(1);
			localStorage.setItem('prevPage', 1);
			localStorage.setItem('prevFilters', JSON.stringify(newCurrentFilter));
			setFilterSettings([item]);
		}
	};

	const getStatusTitle = (filterItem) => {
		if (filterItem.code === 'verify') {
			if (lang === 'en') {
				return 'status: under review';
			} else {
				return 'статус: на рассмотрении';
			}
		} else {
			return (
				<>
					{lang === 'en'
						? `${filterItem.title_en}:
				${filterItem.name_en}`
						: `${filterItem.title_ru}:
				${filterItem.name_ru}`}
				</>
			);
		}
	};

	useEffect(() => {
		if (currentCompositionStatus) {
			setCompositionStatus('verify');
			changeStatusFromStatsBlock('verify');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentCompositionStatus]);

	return (
		<>
			{banner && process.env.REACT_APP_CLIENT !== 'dgtal' && (
				<Banner setBanner={setBanner} title="rod.releases.title" />
			)}
			<MainStatsCompositions
				data={statistics}
				changeStatus={changeStatusFromStatsBlock}
			/>
			<div className={styles.inputWrapper}>
				<Input
					curRef={helpRef}
					handleOnChange={handleFilter}
					setIsFocus={setIsFocus}
					setActive={modalOpen}
					filter={currentFilterSettings}
					placeholder={'rod.repertoire.compositions.search_by_author'}
					parent={'releaseSection'}
				/>
				{Object.values(currentFilterSettings).some((i) => i.show === true) && (
					<>
						<ul className={styles.filter}>
							{Object.keys(currentFilterSettings).map(
								(item) =>
									currentFilterSettings[item].show && (
										<li
											className={styles.filter__item}
											key={currentFilterSettings[item].id}
											onClick={() =>
												handleFilterClear(currentFilterSettings[item])
											}
										>
											<span>{getStatusTitle(currentFilterSettings[item])}</span>
											<img
												src={close}
												width={16}
												onClick={() =>
													handleFilterClear(currentFilterSettings[item])
												}
												alt=""
											/>
										</li>
									)
							)}

							<li className={styles.filter__itemButton}>
								<button
									className={styles.btnAsLink}
									type="button"
									onClick={() => handleClear()}
								>
									<FormattedMessage id={'rod.repertoire.clear_all'} />
								</button>
							</li>
						</ul>
					</>
				)}
			</div>
			{loading ? (
				<Loading className={styles.loader} />
			) : songItems.length ? (
				<div className={styles.repertoire__mainContent}>
					<div>
						<div
							className={`${styles.repertoire__songTitle} ${styles.song__table} ${styles.adaptive__songTitle}`}
						>
							<FormattedHTMLMessage id={'rod.for_all.title'} />
							<FormattedHTMLMessage id={'rod.for_all.authors'} />
							<FormattedHTMLMessage id={'rod.for_all.share'} />
							<FormattedHTMLMessage id={'rod.for_all.status'} />
						</div>

						<ul className={styles.repertoire__songItemsWrapper}>
							{songItems.map((item) => (
								<li className={styles.itemWrapper}>
									{isAccountFeatureCompositionView ? (
										<Link
											to={`/repertoire/compositions/${item.id}`}
											key={item.id}
										>
											<div
												className={
													showStatus(item.statuses) === 'disputed'
														? `${styles.repertoire__songItemError} ${styles.adaptive__item}`
														: `${styles.adaptive__item}`
												}
											>
												<Container fluid>
													<Row>
														<Col xs={4}>
															<div>
																<Nota
																	className={styles.adaptive__songItemLogo}
																/>
															</div>
														</Col>
														<Col xs={6}>
															<div className={styles.adaptive__itemText}>
																<span title={item.title}>{item.title}</span>
															</div>
														</Col>
														{item.moderation_status === 'completed' &&
															availableEditing && (
																<Col xs={2}>
																	<button
																		className={styles.editIcon}
																		onClick={(e) =>
																			onEditCompositionHandler(
																				e,
																				item.id,
																				accounts,
																				history
																			)
																		}
																	>
																		<img src={edit} alt="" />
																	</button>
																</Col>
															)}
													</Row>

													<Row>
														<Col xs={4}>
															<div className={styles.adaptive__itemTitle}>
																<FormattedHTMLMessage
																	id={'rod.for_all.author'}
																/>
															</div>
															<div className={styles.adaptive__itemTitle}>
																<FormattedHTMLMessage
																	id={'rod.for_all.status'}
																/>
															</div>
														</Col>
														<Col xs={8}>
															<div className={styles.adaptive__itemText}>
																{item.authors && Array.isArray(item.authors) ? (
																	<span title={item.authors.join(', ')}>
																		{item.authors.join(', ')}
																	</span>
																) : item.performers ? (
																	<span title={item.authors}>
																		{item.authors}
																	</span>
																) : (
																	<span>-</span>
																)}
															</div>
															<div className={styles.adaptive__itemText}>
																<span>
																	<FormattedMessage
																		id={`rod.repertoire.${showStatus(
																			item.statuses
																		)}`}
																	/>
																</span>
															</div>
														</Col>
													</Row>
												</Container>
											</div>
											<div
												className={
													showStatus(item.statuses) === 'disputed'
														? `${styles.repertoire__songItemError} ${styles.song__table} ${styles.adaptive__songTable}`
														: `${styles.song__table} ${styles.adaptive__songTable}`
												}
											>
												<div>
													<span
														title={item.title}
														className={styles.repertoire__songItemName}
													>
														{item.title}
													</span>
												</div>
												<div>
													{item.authors && Array.isArray(item.authors) ? (
														<span title={item.authors.join(', ')}>
															{item.authors.join(', ')}
														</span>
													) : item.performers ? (
														<span title={item.authors}>{item.authors}</span>
													) : (
														<span>-</span>
													)}
												</div>
												<div className={styles.repertoire__songItemShare}></div>
												<div>
													<span>
														{item.moderation_status === 'completed' &&
														availableEditing ? (
															<FormattedMessage id="rod.repertoire.draft_processing" />
														) : (
															<FormattedMessage
																id={`rod.repertoire.${showStatus(
																	item.statuses
																)}`}
															/>
														)}
													</span>
												</div>
												{item.moderation_status === 'completed' &&
													availableEditing && (
														<button
															className={styles.editIcon}
															onClick={(e) =>
																onEditCompositionHandler(
																	e,
																	item.id,
																	accounts,
																	history
																)
															}
														>
															<img src={edit} alt="" />
														</button>
													)}
												<Nota className={styles.repertoire__songItemLogo} />
											</div>
										</Link>
									) : (
										<div>
											<div
												className={
													showStatus(item.statuses) === 'disputed'
														? `${styles.repertoire__songItemError} ${styles.adaptive__item}`
														: `${styles.adaptive__item}`
												}
											>
												<Container fluid>
													<Row>
														<Col xs={4}>
															<div>
																<Nota
																	className={styles.adaptive__songItemLogo}
																/>
															</div>
														</Col>
														<Col xs={8}>
															<div className={styles.adaptive__itemText}>
																<span title={item.title}>{item.title}</span>
															</div>
														</Col>
													</Row>

													<Row>
														<Col xs={4}>
															<div className={styles.adaptive__itemTitle}>
																<FormattedHTMLMessage
																	id={'rod.for_all.author'}
																/>
															</div>
															<div className={styles.adaptive__itemTitle}>
																<FormattedHTMLMessage
																	id={'rod.for_all.status'}
																/>
															</div>
														</Col>
														<Col xs={8}>
															<div className={styles.adaptive__itemText}>
																{item.authors && Array.isArray(item.authors) ? (
																	<span title={item.authors.join(', ')}>
																		{item.authors.join(', ')}
																	</span>
																) : item.performers ? (
																	<span title={item.authors}>
																		{item.authors}
																	</span>
																) : (
																	<span>-</span>
																)}
															</div>
															<div className={styles.adaptive__itemText}>
																<span>
																	<FormattedMessage
																		id={`rod.repertoire.${showStatus(
																			item.statuses
																		)}`}
																	/>
																</span>
															</div>
														</Col>
													</Row>
												</Container>
											</div>
											<div
												className={
													showStatus(item.statuses) === 'disputed'
														? `${styles.repertoire__songItemError} ${styles.song__table} ${styles.adaptive__songTable}`
														: `${styles.song__table} ${styles.adaptive__songTable}`
												}
											>
												<div>
													<span
														title={item.title}
														className={styles.repertoire__songItemName}
													>
														{item.title}
													</span>
												</div>
												<div>
													{item.authors && Array.isArray(item.authors) ? (
														<span title={item.authors.join(', ')}>
															{item.authors.join(', ')}
														</span>
													) : item.performers ? (
														<span title={item.authors}>{item.authors}</span>
													) : (
														<span>-</span>
													)}
												</div>
												<div className={styles.repertoire__songItemShare}></div>
												<div>
													<span>
														<FormattedMessage
															id={`rod.repertoire.${showStatus(item.statuses)}`}
														/>
													</span>
												</div>

												<Nota className={styles.repertoire__songItemLogo} />
											</div>
										</div>
									)}
								</li>
							))}
						</ul>
					</div>

					<Pagination
						page={page}
						total={total}
						paginate={(pageNumber) => {
							setPage(pageNumber);
							localStorage.setItem('prevPage', pageNumber);
						}}
					/>
				</div>
			) : (
				<>{upload.compositions && <ContentEmpty composition />}</>
			)}
			<RepertoireFilterModal
				active={modal}
				setActive={modalOpen}
				filterData={filterSettings}
				setFilterData={handleChangeFilter}
				currentFilterSettings={currentFilterSettings}
				setCurrentFilter={handleCurrentFilter}
				selectFilters={selectFilters}
				setSelectFilters={setSelectFilters}
				setPage={setPage}
				whiteTheme
			/>
		</>
	);
};

export default compose(
	withUI,
	withAuth,
	withRoot
)(RepertoireCompositionSection);
