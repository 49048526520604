// Core
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { UIContext } from 'contexts/UIContext';

// UI
import CheckBox from '../CheckBox/CheckBox';
import { RadioGroup } from 'components/Radio';
import { Loading } from 'components';
import { InfoButton } from 'components/Buttons';

// Utils
import { getOutletInfo } from '../utils/getOutletInfo';
import { radioOutlets } from '../utils/radio';

// Styles
import s from '../Distribution.module.css';

const OutletsBlock = ({
	isH11Edit,
	personalData2,
	outletsRadioHandler,
	isLoading,
	showCheckbox,
	checks,
	allChecked,
	getCheckedOutlets,
	personal,
}) => {
	const { isTimeZoneForAccount } = useContext(AuthContext);
	const { showModal } = useContext(UIContext);

	const isSelectedOutlet = (id) => {
		if (isTimeZoneForAccount && personal.distribution_outlets?.length) {
			return personal.distribution_outlets[0].outlets.includes(id);
		}
		return;
	};

	return (
		<div className={s.Input__radio} style={isH11Edit ? { marginTop: 0 } : {}}>
			{!isH11Edit && (
				<>
					<div className={s.Input__title}>
						<FormattedMessage
							id={'rod.release.create.step.distribution.input_title'}
						/>
					</div>
					<RadioGroup
						className={s.RadioGroup}
						name={'outlets'}
						value={personalData2}
						onChange={outletsRadioHandler}
						items={radioOutlets}
					/>
				</>
			)}

			{isLoading ? (
				<Loading className={s.loader} />
			) : (
				showCheckbox && (
					<>
						<div
							className={s.Outlets}
							style={isH11Edit ? { paddingLeft: '0' } : {}}
						>
							<div className={s.table}>
								{checks.map(
									(item, index) =>
										!item.is_recommended &&
										(item.description_ru &&
										item.description_ru.trim() !== '' &&
										item.description_en &&
										item.description_en.trim() !== '' ? (
											<InfoButton
												key={index}
												className={s.info}
												onClick={showModal({
													text: getOutletInfo(item),
												})}
											>
												<CheckBox
													name={'check_box_outlets'}
													key={index}
													item={item}
													allChecked={allChecked}
													index={index}
													getCheckedOutlets={getCheckedOutlets}
													isLocked={item.is_recommended ? true : false}
													disabled={isH11Edit || isSelectedOutlet(item.id)}
													selectedOutlet={isSelectedOutlet(item.id)}
												/>
											</InfoButton>
										) : (
											<CheckBox
												name={'check_box_outlets'}
												key={index}
												item={item}
												allChecked={allChecked}
												index={index}
												getCheckedOutlets={getCheckedOutlets}
												isLocked={item.is_recommended ? true : false}
												disabled={isH11Edit || isSelectedOutlet(item.id)}
												selectedOutlet={isSelectedOutlet(item.id)}
											/>
										))
								)}
							</div>
						</div>
					</>
				)
			)}
		</div>
	);
};

export default OutletsBlock;
