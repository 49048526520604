// Core
import React from 'react';

// UI
import Loader from 'components/Loader';

// Icons
import remove_cart from 'images/remove_cart.svg';

// Styles
import s from '../../File.module.css';

const RecordingLoader = ({
	item,
	index,
	uploadProgressPercents,
	deleteBtnDisabled,
	handleDelete,
}) => {
	return (
		<>
			<div className={!item.done ? s.loader : ''}>
				<Loader
					uploadProgressPercents={uploadProgressPercents.find(
						(progressObj) => progressObj.key === item.name
					)}
				/>
			</div>
			<button
				className={
					deleteBtnDisabled
						? `${s.deleteErrorButton} ${s.disabled}`
						: s.deleteErrorButton
				}
				disabled={deleteBtnDisabled}
				onClick={() => handleDelete(index, item)}
			>
				<img src={remove_cart} alt="" />
			</button>
		</>
	);
};

export default RecordingLoader;
