// Data
import { accId, paramName, userId } from './reachParams';

export const reachDataLayer = (
	eventName,
	formName,
	statusValue,
	userIdValue,
	accIdValue
) => {
	// Initialize dataLayer if it doesn't exist
	window.dataLayer = window.dataLayer || [];

	// Check if dataLayer is an array
	if (!Array.isArray(window.dataLayer)) {
		console.error('GTM not loaded or dataLayer not initialized.');
		return;
	}

	const eventKey = 'event';

	// Push the event to dataLayer
	window.dataLayer.push({
		[eventKey]: eventName,
		gtm: {
			[formName]: {
				[paramName]: statusValue,
				[userId]: userIdValue,
				[accId]: accIdValue,
			},
		},
	});
};
