export const getCreatedDateString = (value1, value2) => {
	// Extract the part after 'T' from the first value
	const timePart = value1.split('T')[1];

	// Extract the part before 'T' from the second value
	const datePart = value2.split('T')[0];

	// Combine them into the desired string
	const result = `${datePart}T${timePart}`;
	return result;
};
