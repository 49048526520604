// Core
import { useContext, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

// Context
import { LangContext } from 'contexts/LangContext';

// UI
import { Input } from 'material-design/components';

// Icons
import close from 'images/close_button.svg';

// Styles
import styles from './SearchInput.module.css';

const SearchInput = ({
	handleFilter,
	currentFilterSettings,
	setIsFocus,
	modalOpen,
	handleRemoveFilterItem,
	handleClear,
}) => {
	const { lang } = useContext(LangContext);
	const helpRef = useRef(null);

	return (
		<div className={styles.inputWrapper}>
			<Input
				curRef={helpRef}
				handleOnChange={handleFilter}
				setIsFocus={setIsFocus}
				setActive={modalOpen}
				filter={currentFilterSettings}
				parent={'repertoireReleaseSection'}
			/>

			{currentFilterSettings &&
				Array.from(currentFilterSettings.values()).some(
					(i) => i.show === true
				) && (
					<ul className={styles.filter}>
						{Array.from(currentFilterSettings.values()).map(
							(item) =>
								item.show && (
									<li
										className={styles.filter__item}
										key={item.id}
										onClick={() => handleRemoveFilterItem(item.title_en)}
									>
										<span>
											{lang === 'en'
												? `${item.title_en}:
                ${item.name_en}`
												: `${item.title_ru}:
                ${item.name_ru}`}
										</span>
										<img
											src={close}
											width={16}
											onClick={() => handleRemoveFilterItem(item.title_en)}
											alt=""
										/>
									</li>
								)
						)}

						<li className={styles.filter__itemButton}>
							<button
								className={styles.btnAsLink}
								type="button"
								onClick={() => handleClear()}
							>
								<FormattedMessage id={'rod.repertoire.clear_all'} />
							</button>
						</li>
					</ul>
				)}
		</div>
	);
};

export default SearchInput;
