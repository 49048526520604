// Core
import { CloseButton } from 'components/Buttons';
import { Button } from 'components/Buttons';
import { Col, Container, Row } from 'components/Grid';
import { FormInput } from 'components/Form/FormInput';
import { compose } from 'recompose';
import { withLang, withRoot, withUI } from 'hocs';
import { DropZone } from 'components/Upload/DropZone';
import React from 'react';
import posed, { PoseGroup } from 'react-pose';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import TimePicker from 'components/Inputs/TimePicker/TimePicker';
import { FaqAdminLang } from 'material-design/components/FaqAdminLang';
import BaseModal from './BaseModal';
import debounce from 'lodash.debounce';
import { showError } from 'validators/showError';

import styles from './Modal.module.css';

const Modal = posed.div({
	enter: {
		x: 0,
		opacity: 1,
		delay: 200,
		transition: {
			x: {
				ease: 'easeIn',
			},
			default: { duration: 300 },
		},
	},
	exit: {
		x: '100%',
		opacity: 0,
		transition: { duration: 250 },
	},
});

const Shade = posed.div({
	enter: { opacity: 0.5 },
	exit: { opacity: 0 },
});

const ReportsModal = (props) => {
	const [isVisible, setIsVisible] = useState(true);
	const [errors, setErrors] = useState({});
	const [backErrors, setBackErrors] = useState({});
	const [reportLang, setReportLang] = useState('en');
	const [enData, setEnData] = useState({
		report_id: '',
		title: '',
	});

	const [ruData, setRuData] = useState({
		report_id: '',
		title: '',
	});
	const [attachment, setAttachment] = useState({ file: null, fileData: null });
	const [enConfirmed, setEnConfirmed] = useState(false);
	const [ruConfirmed, setRuConfirmed] = useState(false);
	const [fileTabReady, setFileTabReady] = useState(false);
	const [isShowNotice, setIsShowNotice] = useState(false);
	const [isConfirmedBtnActive, setIsConfirmedBtnActive] = useState(true);
	const [isShowConfirmQuestion, setIsShowConfirmQuestion] = useState(false);
	const [reportPrepared, setReportPrepared] = useState('');
	const [reportsModalRadio, setReportsModalRadio] = useState('archive');
	const [fileLoadingData, setFileLoadingData] = useState({});
	const [initialTimeInHHMM, setInitialTimeInHHMM] = useState('');
	const [isSelectedTimeValid, setIsSelectedTimeValid] = useState(true);

	const {
		data,
		onClose,
		prepareFileToUpload,
		refreshList,
		distributionId,
		distributionName,
		putAdminDistributionReportArchive,
		postAdminDistributionReportFile,
		putAdminDistributionEditContentRequest,
		editManualReportFile,
		globalLang,
		isManualReports = false,
	} = props;

	const currencies = [
		{ id: 1, title: 'EUR', code: '€' },
		{ id: 2, title: 'USD', code: '$' },
		{ id: 3, title: 'GBP', code: '£' },
		{ id: 4, title: 'RUB', code: '₽' },
	];

	const isEdit = data ? true : false;

	useEffect(() => {
		if (isEdit) {
			setRuData({
				title: data.heading,
			});
			setEnData({
				title: data.heading_en,
			});
			setInitialTimeInHHMM(
				data.date_publication && data.date_publication.length > 18
					? '13:00'
					: '11:00'
			);
			const { royalties, currency, account_id } = data;
			setFileLoadingData({
				royalties,
				currency: currencies.find((item) => item.code === currency),
				account_id,
			});
		} else {
			setRuData({
				title: distributionName.heading,
			});
			setEnData({
				title: distributionName.heading_en,
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		switch (isEdit) {
			case false:
				switch (reportsModalRadio) {
					case 'archive':
						if (enData.title && attachment.file) {
							setEnConfirmed(true);
						} else setEnConfirmed(false);
						break;
					case 'file':
						break;
					default:
						break;
				}

				break;
			default:
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enData, attachment]);

	useEffect(() => {
		switch (isEdit) {
			case false:
				switch (reportsModalRadio) {
					case 'archive':
						if (ruData.title && attachment.file) {
							setRuConfirmed(true);
						} else setRuConfirmed(false);
						break;
					case 'file':
						break;
					default:
						break;
				}

				break;
			default:
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ruData, attachment]);

	const willUnmount = (withData = true) => {
		setIsVisible(false);
		setTimeout(() => {
			if ((withData && isEdit) || (withData && reportsModalRadio === 'file'))
				refreshList(true);
			onClose();
		}, 300);
	};

	const titleChange = (e) => {
		setErrors({});
		if (e.target.value.length > 85) return;
		if (reportLang === 'en') {
			setEnData((prev) => ({ ...prev, title: e.target.value }));
		} else {
			setRuData((prev) => ({ ...prev, title: e.target.value }));
		}
	};

	const handleFileRemove = () => {
		setBackErrors({});
		setAttachment({
			file: null,
			fileData: null,
		});
	};

	const handleArchiveUpload = (files) => {
		const fileTypes = ['application/zip', 'application/x-zip-compressed'];
		setErrors({});
		if (!fileTypes.includes(files[0].type)) {
			setErrors({ file: [{ rule: 'archive_wrong_format' }] });
			return;
		}
		const correctedFile = Object.assign(files[0], {
			preview: URL.createObjectURL(files[0]),
		});
		const fd = new FormData();
		fd.append('file', correctedFile);
		setAttachment({
			file: correctedFile,
			fileData: files[0],
		});
		return Promise.resolve();
	};

	const handleFileUpload = (files) => {
		const fileTypes = [
			'application/xls',
			'application/x-xls',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		];
		setErrors({});
		if (!fileTypes.includes(files[0].type)) {
			setErrors({ file: [{ rule: 'archive_wrong_format' }] });
			return;
		}
		const fd = new FormData();
		fd.append('file', files[0]);
		setAttachment({
			file: fd,
			fileData: files[0],
		});
		return Promise.resolve();
	};

	const preConfirmData = () => {
		setBackErrors({});
		if (isEdit) {
			setIsShowNotice(false);
			setIsShowConfirmQuestion(true);
		} else
			switch (reportsModalRadio) {
				case 'archive':
					if (!enConfirmed || !ruConfirmed) {
						setReportLang(enConfirmed ? 'ru' : 'en');
						setIsShowNotice(true);
					} else if (attachment.file || isEdit) {
						setIsShowNotice(false);
						setIsShowConfirmQuestion(true);
					}
					break;
				case 'file':
					if (attachment.file && Object.keys(fileLoadingData).length === 3) {
						setIsShowNotice(false);
						setIsShowConfirmQuestion(true);
					}
					break;
				default:
					break;
			}
	};
	const debouncedSendData = debounce(() => prepareToSendData(), 500);

	const prepareToSendData = () => {
		sendFile();
	};

	const sendFile = () => {
		if (isEdit) {
			const { royalties, currency, account_id } = fileLoadingData;
			const { report_files, date_publication } = data;
			const additionalData = {
				royalties,
				currency: currency.code,
				account_id,
			};
			putAdminDistributionEditContentRequest(
				distributionId,
				report_files,
				date_publication,
				additionalData
			).then((res) => {
				setReportPrepared(res);
			});
		} else if (reportsModalRadio === 'file') {
			const { account_id, royalties, currency } = fileLoadingData;
			const fileData = {
				account_id,
				royalties,
				currency: currency.code,
			};
			postAdminDistributionReportFile(distributionId, fileData).then((res) => {
				editManualReportFile(res.data.id, attachment.file)
					.then((res) => {
						willUnmount();
					})
					.catch((err) => console.error('Error:', err));
			});
		} else {
			putAdminDistributionReportArchive(distributionId, {
				heading_en: enData.title,
				heading: ruData.title,
			}).then((res) => {
				setReportPrepared(res.data);
			});
		}
	};

	useEffect(() => {
		if (reportPrepared && isEdit && attachment.file) {
			editManualReportFile(data.report_files, attachment.file).then(() =>
				willUnmount()
			);
		} else if (reportPrepared && !isEdit) {
			prepareFileToUpload(attachment, reportPrepared.id, reportPrepared)
				.then(() => willUnmount())
				.catch((error) => {
					setBackErrors(error.response?.data.errors);
					setIsShowConfirmQuestion(false);
					setIsConfirmedBtnActive(true);
				});
		} else if (reportPrepared) {
			willUnmount();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reportPrepared]);

	const langChange = () => {
		setReportLang((prev) => (prev === 'en' ? 'ru' : 'en'));
	};

	const changeField = (field) => (e) => {
		switch (field) {
			case 'royalties':
				if (!e.target.value.includes(' ') && !isNaN(+e.target.value))
					setFileLoadingData((prev) => ({ ...prev, [field]: e.target.value }));
				break;
			case 'currency':
				setFileLoadingData((prev) => ({ ...prev, [field]: e }));
				break;
			case 'account_id':
				setFileLoadingData((prev) => ({ ...prev, [field]: e.target.value }));
				break;
			default:
				setFileLoadingData((prev) => ({ ...prev, [field]: e }));
				break;
		}
	};

	useEffect(() => {
		switch (isEdit) {
			case true:
				if (fileLoadingData && Object.keys(fileLoadingData).length === 3) {
					setFileTabReady(
						!Object.values(fileLoadingData).some((item) => {
							return (
								!item ||
								(item &&
									typeof item === 'object' &&
									Object.keys(item).length === 0)
							);
						})
					);
				} else setFileTabReady(false);
				break;
			case false:
				if (
					fileLoadingData &&
					Object.keys(fileLoadingData).length === 3 &&
					attachment.file
				) {
					setFileTabReady(
						!Object.values(fileLoadingData).some((item) => {
							return (
								item === '' ||
								(item &&
									typeof item === 'object' &&
									Object.keys(item).length === 0)
							);
						})
					);
				} else setFileTabReady(false);
				break;
			default:
				break;
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fileLoadingData, attachment]);

	const onReportsModalRadio = (event) => {
		setBackErrors({});
		setReportsModalRadio(event.target.value);
		setFileLoadingData({});
		setRuData({ report_id: '', title: distributionName.heading });
		setEnData({ report_id: '', title: distributionName.heading_en });
	};

	const handleIsSelectedTimeValid = (data) => {
		setIsSelectedTimeValid(data);
	};

	const getErrorText = (errorsData) => {
		if (
			Object.keys(errorsData).includes('client_name') &&
			(errorsData.client_name[0]?.message === 'no distribution' ||
				(errorsData.client_name[0]?.rule === 'invalid' &&
					!errorsData.client_name[0]?.message))
		) {
			return (
				<FormattedMessage id="rod.admin.distribution.reports.error.auto.distribution.title" />
			);
		}

		return <FormattedMessage id="rod.modal.admin_reports_parsing.title" />;
	};

	return (
		<BaseModal className={styles.Modal}>
			<PoseGroup>
				{isVisible && [
					<Shade key="shade" className={styles.Overlay} />,

					<Modal
						key="modal"
						style={{
							marginLeft: 'auto',
						}}
					>
						<Container fluid>
							<Row>
								<Col onClick={() => willUnmount(false)} />
								<Col width={'1'} className={styles.notify}>
									<div
										className={styles.notify__wrapper}
										style={{ width: '400px' }}
									>
										<div className={styles.ReportsModalTitle}>
											<div className={styles.notify__title}>
												<FormattedHTMLMessage
													id={
														isEdit
															? 'rod.admin.reports.editing_report'
															: 'rod.admin.reports.loading_report'
													}
												/>
											</div>
											<CloseButton
												black
												small
												className={styles.CloseButtonNotify}
												onClick={() => willUnmount(false)}
											/>
										</div>
										{!isEdit && (
											<div className={styles.ReportsModalRadioBtn}>
												<div>
													<input
														type="radio"
														id="reportsModalArchiveLoad"
														name="reportsModalRadio"
														value="archive"
														checked={reportsModalRadio === 'archive'}
														onChange={onReportsModalRadio}
													/>
													<label for="reportsModalArchiveLoad">
														<FormattedMessage
															id={'rod.admin.reports.load_archive'}
														/>
													</label>
												</div>
												<div>
													<input
														type="radio"
														id="reportsModalFileLoad"
														name="reportsModalRadio"
														value="file"
														checked={reportsModalRadio === 'file'}
														onChange={onReportsModalRadio}
													/>
													<label for="reportsModalFileLoad">
														<FormattedMessage
															id={'rod.admin.reports.load_file'}
														/>
													</label>
												</div>
											</div>
										)}
										{!isEdit && reportsModalRadio === 'archive' && (
											<div className={styles.ReportsModalArchiveLoadWrapper}>
												<div className={styles.ReportsModalLang}>
													<ul className={styles.Tray}>
														<li
															onClick={langChange}
															style={{ maxWidth: '50px' }}
														>
															<FaqAdminLang
																lang={reportLang}
																className={styles.Lang}
															/>
														</li>
													</ul>
												</div>
												<div
													className={`${styles.reports__title} ${styles.ReportsModalName} ${styles.InactiveBlock}`}
												>
													<FormattedMessage
														id={'rod.admin.reports.report_name'}
													>
														{(placeholder) => (
															<input
																type="muiInput"
																name="reportTitle"
																value={
																	reportLang === 'en'
																		? enData.title
																		: ruData.title
																}
																placeholder={placeholder}
																disabled={true}
																onChange={titleChange}
															/>
														)}
													</FormattedMessage>
													{Object.keys(errors).includes('heading') && (
														<span className={styles.Helper}>
															{showError('reportTitle')(errors.heading[0])}
														</span>
													)}
												</div>

												<div className={styles.DropZone__wrapper}>
													<DropZone
														multiple={false}
														accept={'.zip, .7z'}
														imgType={1}
														classStyles={styles.DropZone}
														dropzoneId={
															isEdit
																? 'rod.admin.reports.edit_report_dropbox'
																: 'rod.admin.reports.report_dropbox'
														}
														onUpload={handleArchiveUpload}
														onRemove={handleFileRemove}
														kb
														value={
															attachment.file
																? {
																		client_name: attachment.fileData.name,

																		size: attachment.fileData.size,
																		url: attachment.fileData.path,
																  }
																: null
														}
														maxSize={99991024 * 99991024 * 99100}
													/>
												</div>
											</div>
										)}
										{isEdit && (
											<div className={styles.InactiveBlock}>
												<div className={styles.ReportsModalLang}>
													<ul className={styles.Tray}>
														<li style={{ maxWidth: '50px' }}>
															<FaqAdminLang
																lang={reportLang}
																className={styles.Lang}
															/>
														</li>
													</ul>
												</div>
												<div
													className={`${styles.reports__title} ${styles.ReportsModalName}`}
												>
													<input
														name="reportTitle"
														errors={errors}
														value={
															globalLang === 'en' ? enData.title : ruData.title
														}
													/>
												</div>
												<span className={styles.DateTimeTitle}>
													<FormattedMessage id={'rod.distribution.date_time'} />
												</span>
												<div
													className={`${styles.nextReportsDateTimeWrapper} ${styles.ZeroPaddingBottom} ${styles.horizontalFlex}`}
												>
													<div className={styles.nextReportsDateWrapper}>
														<FormInput
															type={'datePicker'}
															name={'date_publication'}
															errors={errors}
															data={data}
															minDate={new Date()}
														/>

														{Object.keys(errors).includes('report_date') && (
															<div className={styles.error}>
																<span className={styles.errorText}>
																	{errors['report_date'] === 'invalid date' ? (
																		<FormattedMessage
																			id={'rod.admin.invalid-date'}
																		/>
																	) : (
																		''
																	)}
																</span>
															</div>
														)}
													</div>
													<div
														className={styles.nextReportsDateWrapper}
														style={{
															marginTop: '0',
														}}
													>
														<TimePicker
															setTimeInHHMM={() => {}}
															initialTimeInHHMM={initialTimeInHHMM}
															handleIsSelectedTimeValid={
																handleIsSelectedTimeValid
															}
														/>
													</div>
												</div>
											</div>
										)}
										{(reportsModalRadio === 'file' || isEdit) && (
											<div className={styles.dataTable}>
												<div className={styles.RoyaltyWrapper}>
													<div className={styles.Royalty}>
														<FormInput
															type={'muiInputNumber'}
															name={'royalties'}
															onChange={changeField}
															errors={errors}
															data={fileLoadingData}
															label={
																<FormattedMessage
																	id={'rod.admin.reports.reports.summ'}
																/>
															}
														/>
														{Object.keys(errors).includes('royalties') && (
															<span className={styles.Helper}>
																{
																	<FormattedMessage
																		id={`rod.error.${errors.royalties[0].rule}`}
																	/>
																}
															</span>
														)}
													</div>
													<div className={styles.RoyaltyCurrencies}>
														<FormInput
															type={'MuiSelectCurrencies'}
															name={'currency'}
															onChange={changeField}
															errors={errors}
															data={fileLoadingData}
															className={styles.select}
															label={
																<FormattedMessage
																	id={'rod.admin.reports.reports.currency'}
																/>
															}
															items={currencies}
														/>
														{Object.keys(errors).includes('currency') && (
															<span className={styles.Helper}>
																{showError(errors.currency[0].rule)(
																	errors.currency[0]
																)}
															</span>
														)}
													</div>
												</div>

												{!isManualReports && (
													<div className={styles.ReportsModalAccId}>
														<FormInput
															type="muiInput"
															name={'account_id'}
															onChange={changeField}
															errors={errors}
															data={fileLoadingData}
															label={
																<FormattedHTMLMessage
																	id={'rod.field.admin.reports.acc_id'}
																/>
															}
														/>
														{Object.keys(errors).includes('account_id') && (
															<span className={styles.Helper}>
																{
																	<FormattedMessage
																		id={`rod.error.${errors.account_id[0].rule}`}
																	/>
																}
															</span>
														)}
													</div>
												)}
												<div className={styles.DropZone__wrapper}>
													{isEdit && (
														<div className={styles.DropZone__title}>
															<FormattedHTMLMessage
																id={'rod.admin.reports.edit_file_new'}
															/>
														</div>
													)}
													<DropZone
														multiple={false}
														accept={'.xls, .xlsx'}
														imgType={1}
														classStyles={styles.DropZone}
														dropzoneId={'rod.admin.reports.edit_report_dropbox'}
														onUpload={handleFileUpload}
														onRemove={handleFileRemove}
														kb
														value={
															attachment.file
																? {
																		client_name: attachment.fileData.name,

																		size: attachment.fileData.size,
																		url: attachment.fileData.path,
																  }
																: null
														}
														maxSize={99991024 * 99991024 * 99100}
													/>
												</div>
											</div>
										)}
										{Object.keys(backErrors).length > 0 && (
											<span className={styles.errorHelper}>
												{getErrorText(backErrors)}
											</span>
										)}
										{!isShowConfirmQuestion ? (
											<>
												<div className={styles.reports__confirm}>
													{isShowNotice && (
														<div className={styles.notify__confirm_question}>
															<FormattedHTMLMessage
																id={'rod.admin.reports.confirm_one_article'}
															/>
														</div>
													)}
													{reportsModalRadio === 'archive' && !isEdit && (
														<Button
															text={
																<FormattedHTMLMessage
																	id={
																		isEdit
																			? 'rod.performance_work.save'
																			: 'rod.action.upload'
																	}
																/>
															}
															className={
																((reportLang === 'en' && enConfirmed) ||
																	(reportLang === 'ru' && ruConfirmed)) &&
																(!Object.keys(errors).length
																	? styles.activeReportsBtn
																	: styles.inactiveReportsBtn) &&
																isSelectedTimeValid
															}
															variant={'primary'}
															disabled={
																!(
																	(reportLang === 'en' && enConfirmed) ||
																	(reportLang === 'ru' && ruConfirmed)
																) ||
																Object.keys(errors).length ||
																!isSelectedTimeValid
															}
															onClick={preConfirmData}
														/>
													)}

													{(reportsModalRadio === 'file' || isEdit) && (
														<Button
															text={
																<FormattedHTMLMessage
																	id={
																		isEdit
																			? 'rod.performance_work.save'
																			: 'rod.action.upload'
																	}
																/>
															}
															className={
																fileTabReady && !Object.keys(errors).length
																	? styles.activeReportsBtn
																	: styles.inactiveReportsBtn
															}
															variant={'primary'}
															disabled={
																!fileTabReady || Object.keys(errors).length
															}
															onClick={preConfirmData}
														/>
													)}
												</div>
											</>
										) : (
											<div className={styles.notify__confirm_question_wrapper}>
												<div className={styles.notify__confirm_question}>
													<FormattedHTMLMessage
														id={
															(enConfirmed && ruConfirmed) ||
															reportsModalRadio === 'file' ||
															isEdit
																? 'rod.modal.notify.admin.confirm-question'
																: 'rod.faq.confirm_one_article'
														}
													/>
												</div>
												<div className={styles.notify__confirm}>
													<Button
														text={
															<FormattedHTMLMessage
																id={'rod.modal.notify.admin.confirm-yes'}
															/>
														}
														className={styles.activeBtnConfirmQuestion}
														variant={
															isConfirmedBtnActive ? 'primary' : 'disabled'
														}
														onClick={() => {
															if (isConfirmedBtnActive) {
																setIsConfirmedBtnActive(false);
																debouncedSendData();
															}
														}}
													/>
													<Button
														text={
															<FormattedHTMLMessage
																id={'rod.modal.notify.admin.confirm-no'}
															/>
														}
														className={styles.activeBtnConfirmQuestion}
														variant={
															isConfirmedBtnActive ? 'primary' : 'disabled'
														}
														onClick={() => {
															if (isConfirmedBtnActive) {
																setIsShowConfirmQuestion(false);
															}
														}}
													/>
												</div>
											</div>
										)}
									</div>
								</Col>
							</Row>
						</Container>
					</Modal>,
				]}
			</PoseGroup>
		</BaseModal>
	);
};

export default compose(withLang, withUI, withRoot)(ReportsModal);
