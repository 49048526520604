// Core
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

// Context
import { UIContext } from 'contexts/UIContext';

// Icons
import { ReactComponent as Selected } from 'images/selected.svg';
import play_button from 'images/play_button.svg';
import stop_button from 'images/stop_button.svg';
import close_darkgrey from 'images/close_darkgrey.svg';
import arrow_grey from 'images/arrow_grey.svg';

// Styles
import s from '../Authors.module.css';

const SideTable = ({ toogleMenu, personal, handleForm, disableSaveBtn }) => {
	const { play, handlePlay, audioId, handlePlayStop } = useContext(UIContext);

	return (
		<div
			className={toogleMenu !== '' ? s.main : `${s.main} ${s.main__notForm}`}
		>
			<span className={s.title}>
				<FormattedMessage id={'rod.release.create.step.author.title'} />
			</span>
			<ul className={s.table}>
				{personal.length !== 0 &&
					personal.map((item, index) => (
						<li
							key={index}
							className={
								index === toogleMenu
									? `${s.table__item} ${s.table__itemSel}`
									: s.table__item
							}
						>
							{item.done ? (
								<Selected className={s.tableItem__sel} />
							) : (
								<div
									className={`${s.tableItem__inselected} ${s.tableItem__sel}`}
								/>
							)}
							<span>{index + 1}</span>
							<span>{item.title}</span>
							{audioId === item.file?.id && play ? (
								<img
									src={stop_button}
									className={s.play}
									onClick={handlePlayStop}
									alt=""
								/>
							) : (
								<img
									src={play_button}
									className={s.play}
									onClick={() =>
										handlePlay(
											index,
											item.file,
											item.title ?? item.file.client_name
										)
									}
									alt=""
								/>
							)}
							<img
								src={toogleMenu === index ? close_darkgrey : arrow_grey}
								className={s.toogleMenu}
								style={disableSaveBtn ? { opacity: '0.5' } : {}}
								onClick={disableSaveBtn ? () => {} : () => handleForm(index)}
								alt=""
							/>
							<span></span>
						</li>
					))}
			</ul>
		</div>
	);
};

export default SideTable;
