import { ForgotPassword as ForgotPasswordForm } from 'forms/index';
import { withUI } from 'hocs';

import { withAuth, withRoot } from 'hocs/index';
import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import styles from './ForgotPassword.module.css';

class ForgotPassword extends PureComponent {
	static propTypes = {};

	componentDidMount() {
		this.props.UIContext.showBackNavi();
	}

	componentWillUnmount() {
		this.props.UIContext.hideBackNavi();
	}

	render() {
		const {
			authContext: { isAuth, forgotPassword },
		} = this.props;

		if (isAuth) {
			return <Redirect to={'/'} />;
		}

		return (
			<div className={styles.Page}>
				<ForgotPasswordForm onSubmit={forgotPassword} />
			</div>
		);
	}
}

export default compose(withRoot, withAuth, withUI)(ForgotPassword);
