/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 16/12/2020, 15:04
 */
// eslint-disable-next-line
const modals = {
	'rod.modal.terms.header': 'пользовательское соглашение',
	'rod.modal.terms.text': `
		<h2>заголовок для абзаца</h2>
        <p>Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое. Лежа на панцирнотвердой спине, он видел, стоило ему приподнять голову, свой коричневый, выпуклый, разделенный дугообразными чешуйками живот, на верхушке которого еле держалось готовое вот-вот окончательно сползти одеяло. Его многочисленные, убого тонкие по сравнению с остальным телом ножки беспомощно копошились у него перед глазами. «Что со мной случилось?» – подумал он. Ссылка</p>
        <h4>Список с буллитами:</h4>
		<ul>
		<li>What Type of User am I and What Privacy Terms are Applicable to Me?</li>
		<li>Privacy Terms for Subscribers</li>
		<li>Privacy Terms for Free Users</li>
		<li>Privacy Terms for Site Visitors</li>
		<li>Additional Privacy Terms for All Users</li>
		</ul>
		<h4>Список с цифрами:</h4>
		<ol>
		<li>What Type of User am I and What Privacy Terms are Applicable to Me?</li>
		<li>Privacy Terms for Subscribers</li>
		<li>Privacy Terms for Free Users</li>
		<li>Privacy Terms for Site Visitors</li>
		<li>Additional Privacy Terms for All Users</li>
		</ol>
    `,

	'rod.help.identifiers.modal.title':
		'International Standard Name Identifier (ISO 27729)',
	'rod.help.identifiers.modal.text':
		'Регистрация имен, псевдонимов и наименований юридических лиц происходит с внесением информации в Международный реестр имен и присвоением идентификатора ISNI. <br/> <br/> ISNI относится к международным стандартным идентификаторам, сертифицированным по стандартам ISO, таким как идентификаторы объектов, например ISBN, ISSN, ISRC, ISAN и др. <br/> <br/> Регистрация имени позволяет создателю и правообладателю интеллектуальной собственности получить репутацию. <br/> <br/> ISNI также устраняет противоречия и выделяет вас среди тех, кто обладает таким же именем. Идентификатор объединяет все возможные написания вашего имени (например, И. И. Петров или Иван Петров или Иван И. Петров) <br/> <br/> Регистрация имени ISNI помогает: Идентифицировать вас как создателей и правообладателей объектов интеллектуальной собственности. <br/> Отделить и дифференцировать часто встречающиеся имена и фамилии. <br/>  Собрать в одном месте разные варианты написания имени (Dostoyevsky/Dostoevski).  <br/> Упорядочить процесс получения и выплаты авторских сборов.  <br/> Сделать поиск более точным и увеличить вероятность обнаружения/открытия талантов.',
	'rod.modal.notify.admin.create_notification': 'notification creation',
	'rod.modal.notify.admin.placeholder-header': 'Notification header',
	'rod.modal.notify.admin.placeholder-body': 'Main text of notification',
	'rod.modal.notify.admin.main-text': 'Main text',
	'rod.modal.notify.admin.send-all': 'send all',
	'rod.modal.notify.admin.recipients.hint':
		'select recipients or upload a list in xls or csv with account id`s',
	'rod.modal.notify.admin.send-to-whom': 'To whom',
	'rod.modal.notify.admin.status': 'Status',
	'rod.modal.notify.admin.created-date': 'Created date',
	'rod.modal.notify.admin.confirm-yes': 'Yes',
	'rod.modal.notify.admin.confirm-no': 'No',
	'rod.modal.notify.admin.confirm-question': 'Are You sure?',
	'rod.modal.notify.already-read': 'Read',
	'rod.modal.notify.not-read': 'Not read',

	'rod.modal.release.withdrawal.rejected_title': 'Withdrawal rejected',
	'rod.modal.release.shipment.rejected_title': 'Shipment rejected',
	'rod.modal.release.withdrawal.error_title': 'release removal error',
	'rod.modal.release.shipment.error_title': 'release delivery error',
	'rod.modal.release.withdrawal.outlets_failed_title':
		'Failed to take down the release from some outlets',
	'rod.modal.release.shipment.outlets_failed_title':
		'Some of the outlets were not shipped',

	'rod.modal.release.withdrawal.rejected_text':
		'The release metadata is not specified. You can see the details in the tabs of the release card. Correct the data and try again',
	'rod.modal.release.shipment.rejected_text':
		'The release metadata is not specified. You can see the details in the tabs of the release card. Correct the data and try again',
	'rod.modal.release.shipment.error_text':
		'It is impossible to ship the release to the outlets: {outlets}. For clarifications, contact technical support or see the answers in the FAQ section',
	'rod.modal.release.withdrawal.error_text':
		'It is impossible to take down the release from the outlets: {outlets}. For clarifications, contact technical support or see the answers in the FAQ section',
	'rod.modal.release.shipment.outlets_failed_text':
		'It is impossible to ship the release to the outlets: {outlets}. For clarification of the reasons, contact the support',
	'rod.modal.release.withdrawal.outlets_failed_text':
		'It is impossible to remove the release from the outlets: {outlets}. For clarification of the reasons, contact the support',
	'rod.modal.payments.wrong_currency_title': 'not all data is provided',
	'rod.modal.payments.wrong_currency_text':
		'bank details and contract currency do not match, or not all data is filled in. Please proceed to the next step, enter the correct data or contact technical support',
	'rod.modal.payments.wrong_currency_action': 'next',

	'rod.modal.release.delete.file.title': 'do you want to delete a phonogram?',
	'rod.modal.release.delete.file.text': 'the action cannot be undone',
	'rod.modal.release.delete.last_file.text':
		'the release must contain at least one phonogram',
	'rod.modal.release.delete.last_file.action': 'ok',

	'rod.modal.unsaved_changes_info.text':
		'you have edited data, please click "save" button if you want to apply changes, if not, click "next"',
};

export default modals;
