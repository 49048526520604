// Core
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Col, Row } from 'react-grid-system';
import { useContext } from 'react';

// Context
import { LangContext } from 'contexts/LangContext';
import { UIContext } from 'contexts/UIContext';

// Types
import modalTypes from 'constants/modalTypes';

// Icons
import download from 'images/download.svg';
import error from 'images/error.svg';
import pendingIcon from 'images/pending-icon.svg';
import more from 'images/more.svg';
import moreWhite from 'images/moreWhite.svg';

// Styles
import styles from '../DistributionPage.module.css';

const Reports = ({
	reports,
	isShowMenuMore,
	isShowMenuMoreId,
	handleOnBlurMenuMore,
	handlingReloadReport,
	distributionId,
	putAdminDistributionReportArchive,
	postAdminDistributionReportFile,
	putAdminDistributionEditContentRequest,
	editManualReportFile,
	refreshList,
	handleDelete,
	handleClickMore,
}) => {
	const { lang } = useContext(LangContext);
	const { showModal, isManualReports } = useContext(UIContext);

	return (
		<>
			{reports.map((item, index) => (
				<li className={styles.articlesListItem}>
					<Row nowrap className={styles.row_data}>
						<Row nowrap className={styles.row_title}>
							<Col width={'12%'} className={styles.shell}>
								{item.date_publication}
							</Col>
							<Col width={'12%'} className={styles.shell}>
								{item.created_at}
							</Col>
							<Col width={'11%'} className={styles.shell}>
								<div className={styles.row_title_accId}>
									<span>{item.account_id}</span>
									<span>
										{item.account_title ? item.account_title : '\u2212'}
									</span>
								</div>
							</Col>
							<Col width={'30%'} className={styles.shell}>
								<div className={styles.heading}>
									{lang === 'ru' ? item.heading : item.heading_en}
								</div>
							</Col>
							<Col width={'15%'} className={styles.shell}>
								{item.royalties &&
									item.royalties.replace(
										/(\d)(?=(\d{3})+(?!\d))/g,
										`$1\u00A0`
									) +
										' ' +
										item.currency}
							</Col>

							<Col width={'170px'} className={styles.shell}>
								{item.status === 'done' && (
									<div
										className={styles.action}
										onClick={() => {
											item.file_id && window.open(item.file_id, '_parent');
										}}
									>
										<img src={download} alt="" width="25" />
										<div
											className={styles.actionTitle}
											style={{ paddingTop: '1px' }}
										>
											<FormattedHTMLMessage id={'rod.action.download'} />
										</div>
									</div>
								)}
								{item.status === 'error' && (
									<div
										className={styles.action}
										onClick={() => {
											item.file_id && window.open(item.file_id, '_parent');
										}}
									>
										<img src={error} alt="" />
										<div className={styles.actionTitleError}>
											<FormattedHTMLMessage
												id={'rod.error_page.not_found.header'}
											/>
										</div>
									</div>
								)}
								{item.status === 'pending' && (
									<div className={styles.action}>
										<img src={pendingIcon} alt="" width="25" />
										<div className={styles.actionTitlePending}>
											<FormattedHTMLMessage
												id={'rod.admin.reports.pending_review'}
											/>
										</div>
									</div>
								)}
							</Col>

							<Col width={'42px'} className={styles.shell}>
								{isShowMenuMore && isShowMenuMoreId === index ? (
									<div
										className={styles.menuMoreWrapperActive}
										tabindex="0"
										onBlur={handleOnBlurMenuMore}
									>
										<img src={moreWhite} alt="" className={styles.menuMore} />
										<ul className={styles.menuMoreList}>
											<li
												className={styles.menuMoreListItem}
												onClick={() => {
													item.status === 'error' &&
														item.report_files &&
														handlingReloadReport(item.file_db);
												}}
											>
												<span
													style={
														['pending', 'done'].includes(item.status) ||
														!item.report_files
															? { color: 'var(--color-grey)' }
															: { cursor: 'pointer' }
													}
												>
													<FormattedMessage
														id={`rod.admin.reports.download_again`}
													/>
												</span>
											</li>

											<li
												className={styles.menuMoreListItem}
												onClick={
													item.status !== 'pending' &&
													item.report_files &&
													showModal(
														{
															isManualReports,
															globalLang: lang,
															reportId: item.report_files,
															distributionId,
															data: item,
															putAdminDistributionReportArchive,
															postAdminDistributionReportFile,
															putAdminDistributionEditContentRequest,
															editManualReportFile,
															refreshList,
														},
														'reports'
													)
												}
											>
												{
													<span
														style={
															item.status === 'pending' || !item.report_files
																? { color: 'var(--color-grey)' }
																: { cursor: 'pointer' }
														}
													>
														<FormattedMessage id={`rod.admin.reports.edit`} />
													</span>
												}
											</li>

											<li
												className={styles.menuMoreListItem}
												onClick={showModal(
													{
														title: (
															<FormattedMessage
																id={'rod.distribution.report.delete-confirm'}
															/>
														),
														text: (
															<FormattedMessage
																id={'rod.distribution.report.delete-help'}
															/>
														),
														onAction: () => {
															item.status !== 'pending' &&
																item.report_files &&
																handleDelete(item);
														},
														confirmBtnTxt: (
															<FormattedMessage id={'rod.modal.yes_delete'} />
														),
														declineBtnTxt: (
															<FormattedMessage id={'rod.modal.cancel'} />
														),
													},
													modalTypes.FULL_MODAL
												)}
											>
												<span
													style={
														item.status === 'pending' || !item.report_files
															? { color: 'var(--color-grey)' }
															: { cursor: 'pointer' }
													}
												>
													<FormattedMessage id={`rod.drafts.delete`} />
												</span>
											</li>
										</ul>
									</div>
								) : (
									<div
										className={styles.menuMoreWrapper}
										tabindex="0"
										onClick={() => handleClickMore(index)}
										onBlur={handleOnBlurMenuMore}
									>
										<img src={more} alt="" className={styles.menuMore} />
									</div>
								)}
							</Col>
						</Row>
					</Row>
				</li>
			))}
		</>
	);
};

export default Reports;
