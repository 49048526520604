// Constants
import { reachDataLayer } from './reachDataLayer';

// Utils
import {
	accId,
	paramName,
	reachParams,
	releaseFillType,
} from './reachParams';

export const updateYaParamsAndReachDataLayer = (
	pageUrl,
	eventName,
	formName,
	param,
	userId,
	accountId,
	releaseFillTypeValue
) => {
	const yaParams = {
		[pageUrl]: {
			[eventName]: {
				[formName]: {
					[paramName]: param,
					[accId]: accountId,
					[userId]: userId,
					[releaseFillType]: releaseFillTypeValue,
				},
			},
		},
	};
	reachParams(yaParams);
	reachDataLayer(eventName, formName, param, userId, accountId);
};
