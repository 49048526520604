// Core
import { FormattedMessage } from 'react-intl';
import { useContext } from 'react';

// Context
import { UIContext } from 'contexts/UIContext';

// Icons
import play_button from 'images/play_icon.svg';
import stop_button from 'images/stop_button.svg';

// Styles
import s from '../../Confirm.module.css';

const RecordingsWrapper = ({ personal, emptyTrackList }) => {
	const { play, handlePlay, audioId, handlePlayStop } = useContext(UIContext);

	return (
		<div className={s.recordings__wrapper}>
			<span className={s.lh__title}>
				<FormattedMessage id={'rod.release.create.step.submit.recordings'} />
			</span>

			<div className={s.recordings}>
				{personal?.tracks && personal?.tracks.length !== 0 ? (
					personal.tracks.map((item, index) => (
						<div className={s.recordings__item} key={item.id}>
							<span>{index + 1}</span>
							<span>{item?.title}</span>
							<span></span>
							{(audioId === item.id && play) ||
							(audioId === item.file?.id && play) ? (
								<img
									src={stop_button}
									className={s.play}
									onClick={handlePlayStop}
									alt=""
								/>
							) : (
								<img
									src={play_button}
									className={s.play}
									onClick={() =>
										handlePlay(
											index,
											item.file,
											item?.title ?? item.file.client_name
										)
									}
									alt=""
								/>
							)}
						</div>
					))
				) : (
					<>
						<div className={s.error}>
							<FormattedMessage
								id={'rod.release_create.error.track_required'}
							/>
						</div>
						<span className={s.errorButton} onClick={() => emptyTrackList()}>
							<FormattedMessage id={'rod.release.create.step.submit.upload'} />
						</span>
					</>
				)}
			</div>
		</div>
	);
};

export default RecordingsWrapper;
