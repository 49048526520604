// Core
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

// Services
import { accounts } from 'services';

// Context
import { RootContext } from 'contexts/RootContext';

// Utils
import { getGenres } from 'utils';

// Styles
import styles from '../Review.module.css';

const Information = ({ data, lang, genresArr }) => {
	const { getCompositionTypes, getCountryById } = useContext(RootContext);

	const [compositionTypes, setCompositionTypes] = useState();
	const [languages, setLanguages] = useState();

	useEffect(() => {
		getCompositionTypes().then((res) => {
			if (res) {
				setCompositionTypes(res.data);
			}
		});

		accounts
			.getLanguages()
			.then((res) => {
				res = res.data.data;
				setLanguages(res);
			})
			.catch((error) => {
				console.error('getLanguages - Error', error);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<span className={styles.Review__Section_title}>
				<FormattedMessage id={'rod.release.create.step.submit.body_title'} />
			</span>
			<div className={styles.Review__Information_wrapper}>
				<div className={styles.Review__Information_table_row}>
					<div className={styles.Review__Information_table_cell}>
						<span>
							<FormattedMessage id={'rod.composition.info.creation_date'} />
						</span>

						<span>
							{data.created_date
								? data.created_date.replace(
										/(\d{4})-(\d{2})-(\d{2})(.+)/gm,
										'$3.$2.$1'
								  )
								: '-'}
						</span>
					</div>
					<div className={styles.Review__Information_table_cell}>
						<span>ISWC</span>
						<span>{data.iswc ? data.iswc : '-'}</span>
					</div>
					<div className={styles.Review__Information_table_cell}>
						<span>
							<FormattedMessage id={'rod.composition.info.lyrics_language'} />
						</span>
						<span>
							{languages && data.language
								? languages.find((lan) => data.language === lan.id)[
										lang === 'ru' ? 'title_ru' : 'title_en'
								  ]
								: '-'}
						</span>
					</div>
				</div>
				<div className={styles.Review__Information_table_row}>
					<div className={styles.Review__Information_table_cell}>
						<span>
							<FormattedMessage id={'rod.composition.info.genres'} />
						</span>
						<span>
							{Array.isArray(data.genres) && data.genres.length > 0
								? getGenres(lang, genresArr, data.genres)
								: '-'}
						</span>
					</div>
					<div className={styles.Review__Information_table_cell}>
						<span>
							<FormattedMessage
								id={'rod.composition.info.creation_territory'}
							/>
						</span>
						<span>
							{data.created_country_id
								? getCountryById(data.created_country_id, false)
								: '-'}
						</span>
					</div>
					<div className={styles.Review__Information_table_cell}>
						<span>
							<FormattedMessage id={'rod.composition.info.composition_type'} />
						</span>
						<span>
							{compositionTypes && data.composition_type_id
								? compositionTypes.find(
										(type) => parseInt(data.composition_type_id) === type.id
								  )[lang === 'ru' ? 'title_ru' : 'title_en']
								: '-'}
						</span>
					</div>
					<div className={styles.Review__Information_table_cell}></div>
				</div>
			</div>
		</div>
	);
};

export default Information;
