// Core
import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import { compose } from 'recompose';

// Utils
import { showError } from 'validators/showError';
import { withRoot, withUI, withAuth } from 'hocs';
import update from 'immutability-helper';
import { getHelpMessage } from '../../../../../messages/helper';
import { reachDataLayer, reachGoal, reachParams } from 'utils';
import { accId, EVENTS, paramName, paramSuccess, userId } from 'utils/reachParams';

// Steps
import accountCompletionSteps from 'constants/accountCompletionSteps';
import { contractTermsSteps } from './contractTermsSteps';

// UI
import { Loading, Wizard } from 'components';
import { Bullet } from 'components/Bullet';
import { InfoButton } from 'components/Buttons/InfoButton';
import { Label } from 'components/Labels/Label';
import DurationCurrency from './DurationCurrency/DurationCurrency';
import Conditions from './components/Conditions/Conditions';
import Controls from './Controls/Controls';
import SignType from './SignType/SignType';

// Styles
import styles from './ContractTerms.module.css';

class ContractTerms extends PureComponent {
	constructor(props) {
		super(props);

		this.steps = contractTermsSteps;
		this.state = {
			loading: true,
			data: {},
			accountParams: '',
			isConditionsChecked: true,
			isConditionsCheckedwithLyric: true,
			isSubmitBtnActive: false,
			errors: {},
			contractSignTypes: [],
		};
	}

	changeField = (field) => (value) => {
		this.setState(update(this.state, { data: { [field]: { $set: value } } }));
	};

	counterCheckBoxes = (data) => {
		const a = Object.values(data);
		let disabledCount = 0;
		let trueCount = 0;

		for (const value of a) {
			if (value.resources && Object.keys(value.resources).length) {
				for (const resource of Object.values(value.resources)) {
					if (resource.enabled && resource.signed_properties) {
						disabledCount++;
					}

					if (resource.enabled) {
						trueCount++;
					}
				}
			} else {
				if (value.enabled && value.signed_properties) {
					disabledCount++;
				}

				if (value.enabled) {
					trueCount++;
				}
			}
		}

		return { disabledCount, trueCount };
	};

	componentDidMount() {
		const {
			rootContext: { getContractData, getContractSignTypes },
			authContext: { accountId, setIsAccountLoading },
			UIContext: { hideBackNavi },
			completionStep,
			isAdditional,
		} = this.props;

		hideBackNavi();
		setIsAccountLoading(true);
		this.updateAccountLoading();

		getContractSignTypes().then((res) => {
			this.setState({ contractSignTypes: res });
		});

		if (isAdditional) {
			const counterCheckBoxes = this.counterCheckBoxes(
				this.props.contractData.contract_types
			);

			this.setState({
				countInitialTrueDisabledCheckBoxes: counterCheckBoxes.disabledCount,
				countInitialTrueCheckBoxes: counterCheckBoxes.trueCount,
			});
		}

		if (
			completionStep !== accountCompletionSteps.PERSONAL_AREA &&
			completionStep !== accountCompletionSteps.REPERTOIRE_APPLY
		) {
			getContractData(this.props.account ? this.props.account.id : accountId)
				.then((res) => {
					res = res.data.data;
					this.setState(
						update(this.state, {
							data: {
								$set: {
									...res,
									period: res.period || '3',
								},
							},
							loading: { $set: false },
						}),
						() => {
							const accountScopes = this.state.data.account_scopes.map(
								(item) => item.keyword
							);
							accountScopes.includes('words_author')
								? this.setState({ accountParams: 'withLyric' })
								: this.setState({ accountParams: 'withoutLyric' });
						}
					);
				})
				.catch((err) => {
					console.error('err', err);
				})
				.finally(() => {
					setIsAccountLoading(false);
				});
		} else {
			const {
				rootContext: { getRolesForAddNew, getContractData },
				authContext: { accountId },
				isAddRole,
			} = this.props;

			let res;
			const accId = accountId ?? localStorage.getItem('accountId');

			if (isAddRole && accId) {
				getContractData(accId)
					.then((contractData) => {
						res = contractData.data.data;

						if (accId && accId !== 'null') {
							getRolesForAddNew(accId)
								.then((roles) => {
									res.account_scopes = roles;
									this.setState(
										update(this.state, {
											data: {
												$set: {
													...res,
													period: res.period || '3',
												},
											},
											loading: { $set: false },
										}),
										() => {
											const accountScopes = this.state.data.account_scopes
												.filter((item) => item.selected && !item.signed)
												.map((role) => role.keyword);

											accountScopes.includes('words_author')
												? this.setState({ accountParams: 'withLyric' }, () => {
														console.info(
															'accountParams',
															this.state.accountParams
														);
												  })
												: this.setState(
														{ accountParams: 'withoutLyric' },
														() => {
															console.info(
																'accountParams',
																this.state.accountParams
															);
														}
												  );
										}
									);
								})
								.catch((err) => {
									console.error('Error', err);
								});
						}
					})
					.catch((err) => {
						console.error('Error:', err);
					})
					.finally(() => {
						setIsAccountLoading(false);
					});
			} else {
				res = this.props.contractData;
				this.setState(
					update(this.state, {
						data: {
							$set: {
								...res,
								period: res.period || '3',
							},
						},
						loading: { $set: false },
					}),
					() => {
						const accountScopes = this.state.data.account_scopes.map(
							(item) => item.keyword
						);

						accountScopes.includes('words_author')
							? this.setState({ accountParams: 'withLyric' })
							: this.setState({ accountParams: 'withoutLyric' });
					}
				);
				setIsAccountLoading(false);
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.loading !== this.state.loading) {
			this.updateAccountLoading();
		}
	}

	updateAccountLoading() {
		const {
			authContext: { setIsAccountLoading },
		} = this.props;

		if (this.state.loading) {
			setIsAccountLoading(true);
		} else {
			setIsAccountLoading(false);
		}
	}

	isUseTypeChecked(state, useType) {
		let enabled = false;

		Object.keys(state.data.contract_types[useType].resources).forEach((key) => {
			if (state.data.contract_types[useType].resources[key].enabled) {
				enabled = true;
			}
		});

		return enabled;
	}

	isMediaTypeChecked(state, useType) {
		const enabled = state.data.contract_types[useType].enabled;
		Object.keys(state.data.contract_types[useType].resources).forEach((key) => {
			state = update(state, {
				data: {
					contract_types: {
						[useType]: {
							resources: { [key]: { enabled: { $set: enabled } } },
						},
					},
				},
			});
		});
		return state;
	}

	changeHandler = ({ useType, mediaType }) => {
		this.setState({ errors: {} });
		this.setState({ isConditionsChecked: true });
		this.setState({ isConditionsCheckedwithLyric: true });

		if (mediaType) {
			let state = update(this.state, {});
			const enabled = !state.data.contract_types[useType].resources[mediaType]
				.enabled;

			state = update(state, {
				data: {
					contract_types: {
						[useType]: {
							resources: { [mediaType]: { enabled: { $set: enabled } } },
						},
					},
				},
			});

			state = update(state, {
				data: {
					contract_types: {
						[useType]: {
							enabled: { $set: this.isUseTypeChecked(state, useType) },
						},
					},
				},
			});

			this.setState(state, this.save);
		} else {
			let state = update(this.state, {
				data: {
					contract_types: {
						[useType]: {
							enabled: {
								$set: !this.state.data.contract_types[useType].enabled,
							},
						},
					},
				},
			});

			state = this.isMediaTypeChecked(state, useType);
			this.setState(state, this.save);
		}
	};

	save = () => {
		const counterSelectedCheckBoxes = this.counterCheckBoxes(
			this.state.data.contract_types
		);

		this.setState({
			countSelectedTrueCheckBoxes:
				counterSelectedCheckBoxes.trueCount -
				(this.state.countInitialTrueDisabledCheckBoxes
					? this.state.countInitialTrueDisabledCheckBoxes
					: 0),
		});

		if (typeof this.props.onChange === 'function') {
			this.props.onChange(this.state.data);
		}
	};

	submitAccountWithLyric = () => {
		this.setState({ isConditionsChecked: true });
		this.setState({ isConditionsCheckedwithLyric: true });

		const isLyricChecked = this.props.contractData.contract_types
			? this.state.data.contract_types?.lyrics?.enabled &&
			  !this.props.contractData.contract_types?.lyrics.signed_properties
			: this.state.data.contract_types?.lyrics?.enabled;

		switch (this.props.isAdditional) {
			case true:
				if (!this.props.contracts || !this.props.contracts.length) {
					if (
						this.state.countSelectedTrueCheckBoxes &&
						this.state.countSelectedTrueCheckBoxes === 1 &&
						isLyricChecked
					) {
						const err = showError('rod.contracts.add.conditions.header.lyric')({
							rule: 'required',
						});
						this.setState({
							isConditionsCheckedwithLyric: false,
						});

						return err;
					} else if (
						!this.state.countSelectedTrueCheckBoxes &&
						this.state.countInitialTrueCheckBoxes ===
							this.state.countInitialTrueDisabledCheckBoxes
					) {
						const err = showError('rod.contracts.add.conditions.header')({
							rule: 'required',
						});
						this.setState({
							isConditionsChecked: false,
						});
						return err;
					}
				}

				break;
			default:
				const {
					digital,
					public_performance,
					synchronization,
					lyrics,
				} = this.state.data.contract_types;

				if (
					digital.enabled === false &&
					public_performance.enabled === false &&
					synchronization.enabled === false &&
					lyrics.enabled === false
				) {
					const err = showError('rod.contracts.add.conditions.header')({
						rule: 'required',
					});
					this.setState({ isConditionsChecked: false });

					return err;
				}
				if (
					digital.enabled === false &&
					public_performance.enabled === false &&
					synchronization.enabled === false &&
					lyrics.enabled === true
				) {
					const err = showError('rod.contracts.add.conditions.header.lyric')({
						rule: 'required',
					});
					this.setState({ isConditionsCheckedwithLyric: false });

					return err;
				}
		}
		if (typeof this.props.onSubmit === 'function') {
			this.setState({ loading: true });
			this.props.onSubmit(this.state.data);
		}
	};

	submitAccountWithoutLyric = () => {
		this.setState({ isConditionsChecked: true });

		const {
			digital,
			public_performance,
			synchronization,
		} = this.state.data.contract_types;

		switch (this.props.isAdditional) {
			case true:
				if (
					!this.state.countSelectedTrueCheckBoxes &&
					this.state.countInitialTrueCheckBoxes ===
						this.state.countInitialTrueDisabledCheckBoxes
				) {
					const err = showError('rod.contracts.add.conditions.header')({
						rule: 'required',
					});
					this.setState({ isConditionsChecked: false });
					return err;
				}
				break;
			default:
				if (
					digital.enabled === false &&
					public_performance.enabled === false &&
					synchronization.enabled === false
				) {
					const err = showError('rod.contracts.add.conditions.header')({
						rule: 'required',
					});
					this.setState({ isConditionsChecked: false });
					return err;
				}
				break;
		}

		if (typeof this.props.onSubmit === 'function') {
			this.setState({ loading: true });
			this.props.onSubmit(this.state.data);
		}
	};

	submit = () => {
		const {
			authContext: { user },
		} = this.props;

		this.setState({ errors: {} });
		let err = {};

		reachGoal(EVENTS.submitAgreementParams);

		const eventName = EVENTS.submitAgreementParams;
		const formName = 'Форма "параметры договора"';
		const pageUrl = window.location.href;

		const yaParams = {
			[pageUrl]: {
				[eventName]: {
					[formName]: {
						[paramName]: paramSuccess,
						[userId]: user.id,
						[accId]: this.props.account.id,
					},
				},
			},
		};
		reachParams(yaParams);
		reachDataLayer(
			eventName,
			formName,
			paramSuccess,
			user.id,
			this.props.account.id
		);

		if (!this.state.data.currency) {
			err.currency = [{ rule: 'required' }];
		}

		if (!this.state.data.sign) {
			err.sign = [{ rule: 'required' }];
		}

		if (Object.keys(err).length) {
			return this.setState({ errors: err });
		}

		if (
			this.props.isAdditional &&
			this.state.countSelectedTrueCheckBoxes === 0 &&
			!this.props.contracts.length
		) {
			return this.setState({ errors: err });
		}

		if (
			this.props.isAdditional &&
			!this.state.countSelectedTrueCheckBoxes &&
			this.state.countInitialTrueDisabledCheckBoxes ===
				this.state.countInitialTrueCheckBoxes &&
			!this.props.contracts.length
		) {
			return this.setState({ errors: err });
		}

		switch (this.state.accountParams) {
			case 'withLyric':
				return this.submitAccountWithLyric();
			case 'withoutLyric':
				return this.submitAccountWithoutLyric();
			default:
				break;
		}
	};

	getActiveTypes = (activeTypes) => {
		if (activeTypes.length) {
			activeTypes.find((item) => {
				if (Object.values(item).includes(true)) {
					this.setState({ isSubmitBtnActive: true });
				}
			});
		} else {
			this.setState({ isSubmitBtnActive: false });
		}
	};

	render() {
		const {
			UIContext: { showModal },
			rootContext: { currencies, contractPeriods },
			isAddRole,
			isAdditional,
			isAddService,
			contracts,
		} = this.props;

		if (this.state.loading || !this.state.contractSignTypes.length) {
			return <Loading non_margin={true} />;
		}

		const {
			data: { account_scopes, contract_types },
		} = this.state;

		const lang = localStorage.getItem('lang');
		const message = DOMPurify.sanitize(
			...getHelpMessage(lang, 'contract_terms_sign_as')
		);

		const disabledSubmitBtn =
			(isAdditional &&
				this.state.countSelectedTrueCheckBoxes === 0 &&
				!this.props.contracts.length) ||
			(isAdditional &&
				!this.state.countSelectedTrueCheckBoxes &&
				this.state.countInitialTrueDisabledCheckBoxes ===
					this.state.countInitialTrueCheckBoxes &&
				!this.props.contracts.length);

		return (
			<Container fluid className={styles.Page}>
				<Wizard steps={this.steps} className={styles.Wizard} />
				<div className={styles.Form}>
					<Row>
						<Col sm={10} offset={{ sm: 1 }} className={styles.Header}>
							<Label
								text={
									<FormattedHTMLMessage
										id={
											isAddService
												? 'rod.contracts.add.header_add_service'
												: 'rod.contracts.add.header'
										}
									/>
								}
								font="--gilroy-Medium-40"
								black
							/>
							<Label
								className="sub"
								text={
									<FormattedHTMLMessage id={'rod.contracts.add.subheader'} />
								}
								font="--gilroy-Medium-24"
								black
							/>
						</Col>
					</Row>

					<Row>
						<Col sm={10} offset={{ sm: 1 }}>
							<InfoButton
								className={styles.infoButton}
								onClick={showModal({
									text: parse(message),
								})}
							>
								<Label
									text={
										<FormattedMessage id={'rod.contracts.add.sign_as.header'} />
									}
									font="--gilroy-Medium-24"
									display="inline"
									black
								/>
							</InfoButton>
							<div className={styles.Roles}>
								<ul>
									{isAddRole
										? account_scopes
												.filter((item) => item.signed || item.selected)
												.map((role) => (
													<li>
														{role.signed ? (
															<Bullet className={styles.BulletSelected}>
																<Label
																	text={
																		<FormattedMessage
																			id={`rod.ref.roles.${role.keyword}.summary`}
																		/>
																	}
																	font="--gilroy-Medium-18"
																	black
																/>
															</Bullet>
														) : (
															<Bullet>
																<Label
																	text={
																		<FormattedMessage
																			id={`rod.ref.roles.${role.keyword}.summary`}
																		/>
																	}
																	font="--gilroy-Medium-18"
																	black
																/>
															</Bullet>
														)}
													</li>
												))
										: account_scopes.map((role) => (
												<li>
													<Bullet>
														<Label
															text={
																<FormattedMessage
																	id={`rod.ref.roles.${role.keyword}.summary`}
																/>
															}
															font="--gilroy-Medium-18"
															black
														/>
													</Bullet>
												</li>
										  ))}
								</ul>
							</div>
							<Conditions
								onChange={this.changeHandler}
								conditions={contract_types}
								isAddService={isAddService}
								isAdditional={isAdditional}
								getActiveTypes={this.getActiveTypes}
							/>

							<DurationCurrency
								state={this.state}
								changeField={this.changeField}
								isAdditional={isAdditional}
								contractPeriods={contractPeriods}
								showModal={showModal}
								currencies={currencies}
							/>

							<SignType state={this.state} changeField={this.changeField} />
							<Controls
								state={this.state}
								submit={this.submit}
								contracts={contracts}
								disabledSubmitBtn={disabledSubmitBtn}
							/>
						</Col>
					</Row>
				</div>
			</Container>
		);
	}
}

ContractTerms.propTypes = {
	onSubmit: PropTypes.func,
};

ContractTerms.defaultProps = {};

export default compose(withUI, withRoot, withAuth)(ContractTerms);
